import * as React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getYupValidator as ub04YupValidator, getYupWarning as ub04YupWarning } from 'utils/ub04/validataions';
import { getYupValidator as hcfaYupValidator } from 'utils/hcfa/validations';
import { getYupValidator as dentalYupValidator } from 'utils/dental/validations';
import { FormContext } from 'components/common/FormProvider';
import { onSearchSelect } from 'utils/search/search';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';

type Fields = 'firstName' | 'lastName' | 'middleInitial' | 'generation';

type FieldsetFullNameProps = {
  form: 'hcfa' | 'ub04' | 'dental';
  lastNameId: string;
  firstNameId: string;
  middleInitialId?: string;
  generationId?: string;
  labels?: Partial<Record<Fields, string>>;
  breakPoints?: Partial<Record<Fields, number>>;
  isRequired?: Partial<Record<Fields, boolean>>;
  direction?: 'horizontal' | 'vertical';
};

const GENERATIONS = ['JR', 'SR', 'II', 'III', 'IV', 'V', 'VI'];

const validatorsMap = {
  hcfa: hcfaYupValidator,
  ub04: ub04YupValidator,
  dental: dentalYupValidator
};

const warningsMap = {
  hcfa: undefined /* TODO: SET CORRECT WARNING MAP */,
  ub04: ub04YupWarning,
  dental: undefined /* TODO: SET CORRECT WARNING MAP */
};

function FieldsetFullName(props: FieldsetFullNameProps): JSX.Element {
  const { lastNameId, firstNameId, middleInitialId, generationId, labels, breakPoints, isRequired, direction } = props;
  const formModel = props.form;
  const { t } = useTranslation();
  const { form } = React.useContext(FormContext);

  const yupValidator = validatorsMap[formModel];
  const yupWarning = warningsMap[formModel];

  const lastNameRules = yupWarning
    ? [yupValidator(t, lastNameId, getFormProvidersDataFromGlobalObject()), yupWarning(t, lastNameId)]
    : [yupValidator(t, lastNameId, getFormProvidersDataFromGlobalObject())];

  if (direction === 'vertical') {
    return (
      <div className="fields-name-style">
        <Form.Item label={labels?.lastName || t('common:fields.lastName')} name={lastNameId} rules={lastNameRules}>
          <Input
            placeholder="LAST NAME"
            min={1}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>

        <Form.Item
          label={labels?.firstName || t('common:fields.firstName')}
          name={firstNameId}
          rules={[yupValidator(t, firstNameId, getFormProvidersDataFromGlobalObject())]}
        >
          <Input
            placeholder="FIRST NAME"
            min={1}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>

        {middleInitialId && (
          <Form.Item
            label={labels?.middleInitial || t('common:fields.middleInitial')}
            name={middleInitialId}
            rules={[yupValidator(t, middleInitialId, getFormProvidersDataFromGlobalObject())]}
          >
            <Input placeholder="MIDDLE NAME" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        )}

        {generationId && (
          <Form.Item
            label={labels?.generation || t('common:fields.generation')}
            name={generationId}
            rules={[yupValidator(t, generationId, getFormProvidersDataFromGlobalObject())]}
          >
            <Select
              placeholder="JR"
              options={GENERATIONS.map((el) => ({ label: el, value: el }))}
              allowClear
              showSearch
              onSearch={(input) => {
                onSearchSelect(form, generationId, input, GENERATIONS);
              }}
            />
          </Form.Item>
        )}
      </div>
    );
  }

  return (
    <Row gutter={0}>
      <Col span={breakPoints?.lastName || 12}>
        <Form.Item
          className="field--first"
          label={labels?.lastName || t('common:fields.lastName')}
          name={lastNameId}
          rules={lastNameRules}
        >
          <Input
            placeholder="LAST NAME"
            min={1}
            max={80}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
      <Col span={breakPoints?.firstName || 12}>
        <Form.Item
          label={labels?.firstName || t('common:fields.firstName')}
          name={firstNameId}
          rules={[yupValidator(t, firstNameId, getFormProvidersDataFromGlobalObject())]}
        >
          <Input
            placeholder="FIRST NAME"
            min={1}
            max={80}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
      {middleInitialId && (
        <Col span={breakPoints?.middleInitial || 12}>
          <Form.Item
            label={
              <span style={{ whiteSpace: 'nowrap' }}>{labels?.middleInitial || t('common:fields.middleInitial')}</span>
            }
            name={middleInitialId}
            rules={[yupValidator(t, middleInitialId, getFormProvidersDataFromGlobalObject())]}
          >
            <Input
              placeholder="MIDDLE NAME"
              max={25}
              onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>
        </Col>
      )}
      {generationId && (
        <Col span={breakPoints?.generation || 12}>
          <Form.Item
            className="field--last"
            label={labels?.generation || t('common:fields.generation')}
            name={generationId}
            rules={[yupValidator(t, generationId, getFormProvidersDataFromGlobalObject())]}
          >
            <Select
              placeholder="JR"
              options={GENERATIONS.map((el) => ({ label: el, value: el }))}
              allowClear
              showSearch
              onSearch={(input) => {
                onSearchSelect(form, generationId, input, GENERATIONS);
              }}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

export default FieldsetFullName;
