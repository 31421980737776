import * as React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DIAGNOSIS_CODES } from '../../../utils/ub04';
import { getYupValidator } from '../../../utils/ub04/validataions';
import { onSearchSelect } from 'utils/search/search';
import { useContext } from 'react';
import { FormContext } from 'components/common/FormProvider';

type Fields = 'codeName' | 'codeSelect';

type FieldsetDiagnosisCodeProps = {
  codeNameId: string;
  codeSelectId: string;
  prefix?: string;
  placeholder?: string;
  labels?: Partial<Record<Fields, string | React.ReactElement>>;
  isRequired?: Partial<Record<Fields, boolean>>;
  isPaperForm?: boolean;
};

function FieldsetECICode(props: FieldsetDiagnosisCodeProps): JSX.Element {
  const { codeNameId, codeSelectId, prefix, labels, placeholder, isRequired, isPaperForm } = props;
  const { t } = useTranslation();
  const { form } = useContext(FormContext);

  return (
    <Row gutter={0}>
      <Col span={24}>
        <Form.Item label={labels?.codeName || 'Code'} name={codeNameId} rules={[getYupValidator(t, codeNameId)]}>
          <Input
            prefix={prefix}
            placeholder={placeholder}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
      <Col span={isPaperForm ? 24 : 8}>
        <Form.Item label={labels?.codeSelect || 'Qual'} name={codeSelectId}>
          <Select
            options={DIAGNOSIS_CODES.map((code) => ({ label: code, value: code }))}
            showSearch
            onSearch={(input) => {
              onSearchSelect(form, codeSelectId, input, DIAGNOSIS_CODES);
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FieldsetECICode;
