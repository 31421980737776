import React from 'react';
import { Checkbox, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { DENTAL_FIELDS } from 'utils/dental/fields';

// const VALUE_MEDICARE = 'Statement of Actual Services';
// const VALUE_MEDICAID = 'Request for Predeterminalin / Preauthorization';
// const VALUE_TRICARE = 'EPSDT / Title XIX';

function OtherCoverage() {
  const { t } = useTranslation();

  const options = [
    { label: `${t('common:fields.dental')}?`, value: '4_other_coverage_dental' },
    { label: `${t('common:fields.medical')}?`, value: '4_other_coverage_medical' }
  ];

  return (
    <Form.Item name={DENTAL_FIELDS.otherCoverage} style={{ marginTop: '-3px' }}>
      <Checkbox.Group options={options} className="flex" style={{ width: '270px' }} />
    </Form.Item>
  );
}

export default OtherCoverage;
