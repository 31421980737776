import React, { useRef, ElementRef, useEffect, useState } from 'react';
import './PageViewerWithAttachments.scss';
import { AttachmentDTO, FileRepresentationsDTO } from 'models/DTO/AttachmentDTO';
import { downloadFile } from 'api/files';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { notification } from 'antd';
import { PDFDocumentProxy, getDocument } from 'pdfjs-dist';
// @ts-ignore
import * as Tiff from 'tiff.js';
import PageViewerPagination from './PageViewerPagination';
import { Spinner } from '@datum/react-components';

interface Props {
  //   mimeType: 'image/tiff' | 'application/pdf' | 'image/jpeg' | 'image/jpg' | 'image/fax-tiff' | 'image/png';
  selectedAttachment: AttachmentDTO | FileRepresentationsDTO | undefined;
}

enum SupportedTypeEnum {
  PDF = 'pdf',
  IMAGE = 'image',
  TIFF = 'tiff'
}

function getBlobType(attachment: AttachmentDTO | FileRepresentationsDTO | undefined) {
  if (!attachment) return undefined;
  if (attachment.mimeType === 'image/tiff' || attachment.mimeType === 'image/fax-tiff') return SupportedTypeEnum.TIFF;
  if (attachment.mimeType === 'application/pdf') return SupportedTypeEnum.PDF;
  if (
    attachment.mimeType === 'image/jpeg' ||
    attachment.mimeType === 'image/jpg' ||
    attachment.mimeType === 'image/png'
  )
    return SupportedTypeEnum.IMAGE;
  notification.error({
    message: 'Unsuported file type selected',
    description: 'Invalid file uploaded'
  });
}

const PageViewer: React.FC<Props> = ({ selectedAttachment }) => {
  const canvasRef = useRef<ElementRef<'canvas'>>(null);
  const { accessToken } = useOidcAccessToken();
  const [pagesNum, setPagesNum] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const pdfDoc = useRef<PDFDocumentProxy>();
  const tiffDoc = useRef<any>();

  const blobType = getBlobType(selectedAttachment);
  const showPagination =
    (blobType === SupportedTypeEnum.PDF || blobType === SupportedTypeEnum.TIFF) && Boolean(pagesNum);

  async function onAttachmentChange() {
    setLoading(true);
    const file = await downloadFile({ path: selectedAttachment!.path, token: accessToken });
    if (blobType === SupportedTypeEnum.PDF) {
      const doc = await getDocument(file).promise;

      pdfDoc.current = doc;
      if (doc.numPages) {
        setPagesNum(doc.numPages);
        setCurrentPage(1);
        // renderPdfPage(1);
      }
    }
    if (blobType === SupportedTypeEnum.TIFF) {
      const doc = new Tiff({ buffer: file });
      tiffDoc.current = doc;
      const pagesNum = doc.countDirectory();
      setPagesNum(pagesNum);
      if (pagesNum) {
        setCurrentPage(1);
        // renderTiffPage(1);
      }
    }
    if (blobType === SupportedTypeEnum.IMAGE) {
      renderImage(file);
    }
  }

  async function renderPdfPage(num: number) {
    if (pdfDoc.current) {
      // setLoading(true);
      const pg = await pdfDoc.current.getPage(num);
      const viewport = pg.getViewport({ scale: 2, rotation: 0 });

      canvasRef.current!.height = viewport.height;
      canvasRef.current!.width = viewport.width;
      pg.render({ canvasContext: canvasRef.current!.getContext('2d')!, viewport });
      setLoading(false);
    }
  }

  function renderTiffPage(pageNum: number) {
    if (tiffDoc.current) {
      tiffDoc.current.setDirectory(pageNum);
      const height = tiffDoc.current.height();
      const width = tiffDoc.current.width();
      canvasRef.current!.height = height;
      canvasRef.current!.width = width;
      const imgBuffer = tiffDoc.current.readRGBAImage();
      const imageData = new Uint8ClampedArray(imgBuffer);
      const newImg = new ImageData(imageData, width, height);
      const ctx = canvasRef.current!.getContext('2d')!;
      ctx.putImageData(newImg, 0, 0);
      setLoading(false);
    }
  }

  async function renderImage(buff: ArrayBuffer) {
    const img = new Image();
    const blob = new Blob([buff], { type: selectedAttachment?.mimeType });

    const url = URL.createObjectURL(blob);
    img.src = url;
    img.onload = function () {
      const context = canvasRef.current!.getContext('2d')!;
      const imgWidth = img.width;
      const imgHeight = img.height;
      canvasRef.current!.width = imgWidth;
      canvasRef.current!.height = imgHeight;
      context.drawImage(img, 0, 0, imgWidth, imgHeight);
      setLoading(false);
      URL.revokeObjectURL(url);
    };
  }

  useEffect(() => {
    if (currentPage) {
      if (blobType === SupportedTypeEnum.TIFF) renderTiffPage(currentPage);
      if (blobType === SupportedTypeEnum.PDF) renderPdfPage(currentPage);
    }
  }, [currentPage, blobType]);

  useEffect(() => {
    if (selectedAttachment) {
      setCurrentPage(0);
      onAttachmentChange();
    }
  }, [selectedAttachment]);

  return (
    <div className="PageViewerWithAttachments__container">
      {showPagination && (
        <PageViewerPagination currentPage={currentPage} totalPages={pagesNum} setCurrentPage={setCurrentPage} />
      )}
      {Boolean(selectedAttachment) && <canvas style={{ width: '100%', height: 'auto' }} ref={canvasRef} />}
      {!selectedAttachment && (
        <div className="pelase-select">
          <p>Please select attachment.</p>
        </div>
      )}

      {loading && <Spinner active description={'Loading preview'} fullPage={false} />}
    </div>
  );
};

export default PageViewer;
