const getDigitsFromValue = (value: string = ''): string => value.replace(/[^\d]+/g, '') || '';

const padDigits = (digits: string): string => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = '0'.repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number: string): string => number.replace(/^0+([0-9]+)/, '$1');

const addDecimalToNumber = (number: string, separator: string): string => {
  const centsStartingPosition = number.length - 2;
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
  const cents = number.substring(centsStartingPosition);
  return dollars + separator + cents;
};

const convertToCurrency = (value: string, separator: string = '.'): string => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);
  return addDecimalToNumber(digitsWithPadding, separator);
};

export const toCurrency: React.FormEventHandler<HTMLInputElement> = (e) => {
  const target = e.target as HTMLInputElement;

  target.value = convertToCurrency(target.value);
};

export const toCurrencyPaste: React.ClipboardEventHandler<HTMLInputElement> = (e) => {
  e.stopPropagation();
  e.preventDefault();
  const target = e.target as HTMLInputElement;
  const pasteText = e.clipboardData.getData('text/plain') || '';
  const cleanedText = pasteText.replace(/[^0-9]/g, '');
  target.value = convertToCurrency(cleanedText);
};

export const nullifyCurrencyInput: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
  const target = e.target as HTMLInputElement;
  if (e.key === 'Delete') {
    target.value = '';
  }
};
