import * as React from 'react';
import { Select } from 'antd';

import states from '../../utils/us-states.json';
import codes from '../../utils/us-phone-areacodes.json';

const { Option, OptGroup } = Select;

type FormPhoneCodeSelectProps = {
  onChange?: (val: string) => void;
  value?: string;
};
type ParseOptiosProps = {
  label: string;
  value: number;
};

function SelectPhoneCode({ onChange, value }: FormPhoneCodeSelectProps): JSX.Element {
  const [data, setData] = React.useState(value);

  const getLabel = (state: string, code: number) => {
    const abbr = states.find((el) => el.name === state);
    return `${abbr?.abbreviation || ''} +(${code})`;
  };
  const parseOptions = React.useMemo((): ParseOptiosProps[] => {
    const _parseOptions: ParseOptiosProps[] = [];
    Object.entries(codes).map(([state, codes]) =>
      codes.map((code) => _parseOptions.push({ label: getLabel(state, code), value: code }))
    );
    return _parseOptions;
  }, []);
  const setValue = React.useCallback(
    (input: string) => {
      const filterResponse = parseOptions.filter((item: ParseOptiosProps) =>
        item.label.toLowerCase().includes(input.toLowerCase())
      );
      if (filterResponse.length === 1) {
        setData(filterResponse[0].value as unknown as string);
      }
    },
    [parseOptions]
  );
  return (
    <Select
      showSearch
      filterOption={(input, option) =>
        (`${option?.value}` ?? '').includes(input) ||
        (`${option?.children}` ?? '').toLowerCase().includes(input.toLowerCase())
      }
      placeholder="Area code"
      style={{ minWidth: 110 }}
      allowClear
      onChange={(input) => {
        if (!input) {
          setData(input);
        }
      }}
      value={data}
      onSearch={(input) => {
        setValue(input);
      }}
    >
      {Object.entries(codes).map(([state, codes]) => (
        <OptGroup key={state} label={state}>
          {codes.map((code) => (
            <Option key={code} value={code}>
              {getLabel(state, code)}
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  );
}

export default SelectPhoneCode;
