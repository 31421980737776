import * as React from 'react';
import { LoadingProvider } from 'context/loading.context';
import { BreadcrumbProvider } from './breadcrumb.context';
import { FormProvider } from './form.context';

type IndexProviderProps = { children: React.ReactNode };

function IndexProvider({ children }: IndexProviderProps): JSX.Element {
  return (
    <BreadcrumbProvider>
      <LoadingProvider>
        <FormProvider>{children}</FormProvider>
      </LoadingProvider>
    </BreadcrumbProvider>
  );
}
export default IndexProvider;
