import React, { useState } from 'react';

import { Page } from 'components/common/Page';
import { SECTIONS } from 'config/constants';
import { Empty } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useTranslation } from 'react-i18next';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import {
  getReportsMostProcessedByProvider,
  getReportsMostSubmittedByUser,
  getReportsProcessedByDay
} from 'api/dashbard';
import { Column } from '@ant-design/plots';
import { Bar } from '@ant-design/plots';
import { Pie } from '@ant-design/plots';

import { useUserData } from 'utils/hooks/useUserData';
import { SubmittedByUserDTO } from 'models/DTO/SubmittedByUserDTO';
import { ProcessedByProviderDTO } from 'models/DTO/ProcessedByProviderDTO';
import { ClaimDistributionDTO } from 'models/DTO/ClaimDistributionDTO';
import { ProcessedByDayDTO } from 'models/DTO/ProcessedByDayDTO';
import moment from 'moment';

function Dashboard(): JSX.Element {
  const { t } = useTranslation();

  const { accessToken } = useOidcAccessToken();
  const { isAdmin, isProvidersAdmin } = useUserData();
  const isGlobalAdmin = isAdmin || isProvidersAdmin;

  const [form] = useForm();

  React.useEffect(() => {
    form.resetFields();
  }, []);

  const [processedByDay, setProcessedByDay] = useState<ProcessedByDayDTO[]>([]);
  const [mostSubmittedByUser, setMostSubmittedByUser] = useState<SubmittedByUserDTO[]>([]);
  const [mostProcessedByProvider, setMostProcessedByProvider] = useState<ProcessedByProviderDTO[]>([]);
  const [claimPercentageDistribution, setClaimPercentageDistribution] = useState<ClaimDistributionDTO>({
    dental: 0,
    hcfa: 0,
    ub: 0
  });

  React.useEffect(() => {
    processedByDayFunction();
    mostSubmittedByUserFunction();
    mostProcessedByProviderFunction();
  }, []);

  const processedByDayFunction = async () => {
    if (accessToken) {
      try {
        const data = await getReportsProcessedByDay(accessToken);
        if (data) {
          console.log('data ', data);
          const claimPercentageData = data.reduce(
            (acc, curr) => {
              acc.dental += curr.dental;
              acc.ub += curr.ub04;
              acc.hcfa += curr.hcfa;
              return acc;
            },
            { dental: 0, hcfa: 0, ub: 0 }
          );

          setClaimPercentageDistribution(claimPercentageData);
          setProcessedByDay(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        // notification.error({
        //   message: <div>Unable to fetch claims processed by day data</div>
        // });
      } finally {
        //
      }
    }
  };

  const mostSubmittedByUserFunction = async () => {
    if (accessToken) {
      try {
        const data = await getReportsMostSubmittedByUser(accessToken);

        if (data) {
          setMostSubmittedByUser(data.sort((a, b) => b.numOfDoc - a.numOfDoc));
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        // notification.error({
        //   message: <div>Unable to fetch most submitted claims by user data</div>
        // });
      } finally {
        //
      }
    }
  };

  const mostProcessedByProviderFunction = async () => {
    if (accessToken) {
      try {
        const data = await getReportsMostProcessedByProvider(accessToken);
        if (data) {
          setMostProcessedByProvider(data.sort((a, b) => b.numOfDoc - a.numOfDoc));
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        // notification.error({
        //   message: <div>Unable to fetch most processed claims by provider data</div>
        // });
      } finally {
        //
      }
    }
  };

  const customLabelFormatter = (text: string) => {
    const limitedText = text?.slice(0, 15);
    const formattedText = limitedText?.length >= 15 ? `${limitedText}...` : limitedText;
    return `${formattedText}`;
  };

  const dateLabelFormatter = (text: string) => {
    const parsedDate = moment(text);

    if (parsedDate.isValid()) {
      return parsedDate.format('DD. MMM YY');
    }
    return text;
  };

  const getWholeNumber = (input: number) => {
    if (input % 1 === 0) return input;
    return '';
  };

  const ProcessedByDay = () => {
    const config = {
      data: processedByDay,
      xField: 'submissionDate',
      yField: 'numOfDoc',
      meta: {
        numOfDoc: {
          tickInterval: 1
        }
      },
      label: {
        textBaseline: 'middle',
        style: {
          textAnchor: (d: any) => (+d.frequency > 0.008 ? 'right' : 'start'),
          fill: (d: any) => (+d.frequency > 0.008 ? '#fff' : '#000'),
          dx: (d: any) => (+d.frequency > 0.008 ? -5 : 5)
        }
      },
      animate: { enter: { type: 'growInY' } },
      axis: {
        x: {
          labelAutoRotate: true,
          labelAutoEllipsis: true,
          labelFormatter: dateLabelFormatter
        },
        y: {
          labelAutoRotate: true,
          labelAutoEllipsis: true,
          labelFormatter: getWholeNumber
        }
      },
      style: {
        fill: 'rgba(21, 219, 209, 0.8)',
        radiusTopLeft: 10,
        radiusTopRight: 10
      }
    };
    return <Bar {...config} />;
  };

  const MostSubmittedByUser = () => {
    const config = {
      data: mostSubmittedByUser,
      xField: 'userId',
      yField: 'numOfDoc',
      label: {
        textBaseline: 'middle',
        style: {
          textAnchor: (d: any) => (+d.frequency > 0.008 ? 'right' : 'start'),
          fill: (d: any) => (+d.frequency > 0.008 ? '#fff' : '#000'),
          dx: (d: any) => (+d.frequency > 0.008 ? -5 : 5)
        }
      },
      animate: { enter: { type: 'growInY' } },
      axis: {
        x: {
          labelAutoRotate: true,
          labelAutoEllipsis: true,
          labelFormatter: customLabelFormatter
        },
        y: {
          labelAutoRotate: true,
          labelAutoEllipsis: true
        }
      },
      style: {
        //fill: 'rgba(126, 212, 236, 0.8)',
        radiusTopLeft: 10,
        radiusTopRight: 10
      }
    };
    return <Bar {...config} />;
  };

  const MostProcessedByProvider = () => {
    const config = {
      data: mostProcessedByProvider,
      xField: 'providerName',
      yField: 'numOfDoc',
      label: {
        textBaseline: 'bottom'
      },
      animate: { enter: { type: 'growInY' } },
      axis: {
        x: {
          labelAutoRotate: true,
          labelAutoEllipsis: true,
          labelFormatter: customLabelFormatter
        },
        y: {
          labelAutoRotate: true,
          labelAutoEllipsis: true
        }
      },
      style: {
        fill: 'rgba(241, 142, 86, 0.8)',
        radiusTopLeft: 10,
        radiusTopRight: 10
      }
    };
    return <Column {...config} />;
  };

  // const MostProcessedByProvider = () => {
  //   const config = {
  //     data: mostProcessedByProvider,
  //     angleField: 'numOfDoc',
  //     colorField: 'providerName',
  //     label: {
  //       text: 'numOfDoc',
  //       style: {
  //         fontWeight: 'bold'
  //       }
  //     },
  //     legend: {
  //       color: {
  //         title: false,
  //         position: 'right',
  //         rowPadding: 5
  //       }
  //     }
  //   };
  //   return <Pie {...config} />;
  // };

  const ClaimPercentageDistribution = () => {
    const config = {
      data: Object.entries(claimPercentageDistribution)
        .filter(([_k, _v]) => _v !== 0)
        .map(([_k, _v], _i, _arr) => {
          const total = _arr.reduce((_prev, [_, _curr]) => {
            return _prev + (_curr as number);
          }, 0);
          return {
            claim: _k,
            percentage: (((_v as number) * 100) / total).toFixed(1),
            numOfDocs: _v
          };
        }),
      angleField: 'numOfDocs',
      colorField: 'claim',
      label: {
        text: ({ percentage }: { percentage: number }) => `${percentage}%`,
        style: {
          fontWeight: 'bold'
        }
      }
      // legend: {
      //   color: {
      //     title: false,
      //     position: 'left'
      //     //rowPadding: 5
      //   }
      // }
    };

    return <Pie {...config} />;
  };

  const totalNumOfDocsProcessedByProvider = mostProcessedByProvider?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.numOfDoc,
    0
  );
  const totalNumOfDocsMostSubmittedByUser = mostSubmittedByUser?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.numOfDoc,
    0
  );
  const totalNumOfDocsProcessedByDay = processedByDay?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.numOfDoc,
    0
  );

  const allZero = Object.values(claimPercentageDistribution).every((value) => value === 0);

  return (
    <div className={'dashboard-page'}>
      <Page pageHelmet={t(`pages.dashboard`)}>
        <Page.Back url={`/${SECTIONS.HOME}`} />
        <Page.Header
          title={t(``)}
          //description={t(`${section}:description`)}
        />
        <div className="charts-wrapper">
          {processedByDay && (
            <div className="chart">
              <h2>
                Processed <br /> by Day ({totalNumOfDocsProcessedByDay || 0})
              </h2>
              {processedByDay.length > 0 ? <ProcessedByDay /> : <Empty />}
            </div>
          )}
          {isGlobalAdmin && (
            <div className="chart">
              <h2>
                Most Submitted <br /> by User ({totalNumOfDocsMostSubmittedByUser || 0})
              </h2>
              {mostSubmittedByUser.length > 0 ? <MostSubmittedByUser /> : <Empty />}
            </div>
          )}
          {isGlobalAdmin && mostProcessedByProvider && (
            <div className="chart">
              <h2>
                Most Processed <br /> by Provider ({totalNumOfDocsProcessedByProvider || 0})
              </h2>
              {mostProcessedByProvider.length > 0 ? <MostProcessedByProvider /> : <Empty />}
            </div>
          )}

          <div className="chart">
            <h2>
              Claim Submission Distribution <br /> (
              {`HCFA: ${claimPercentageDistribution?.hcfa || 0},
                UB: ${claimPercentageDistribution?.ub || 0},
                DENTAL: ${claimPercentageDistribution?.dental || 0}`}
              )
            </h2>
            {!allZero ? <ClaimPercentageDistribution /> : <Empty />}
          </div>
        </div>
      </Page>
    </div>
  );
}

export default Dashboard;
