import React, { useState, useContext, useEffect } from 'react';
import { Form, Radio, RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormContext } from 'components/common/FormProvider';
import { getYupValidator } from 'utils/hcfa/validations';
import { HCFA_FIELDS } from 'utils/hcfa/fields';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';

type Props = {
  label?: string;
};

function FormItemPatientRelationship({ label }: Props) {
  const { t } = useTranslation();
  const { form, setData } = useContext(FormContext);
  const relValue = Form.useWatch(HCFA_FIELDS.patRelInfo6, form);

  const handleChange = () => {
    const fieldsToCheck = [
      '2_pat_last_name',
      '2_pat_first_name',
      '2_pat_mi',
      '5_pat_addr_1',
      '5_pat_city',
      '5_pat_state',
      '5_pat_zip',
      '_5e__PATIENTS_TELEPHONE',
      //'_5e__PATIENTS_TELEPHONE_code',
      'pat_addr2',
      '3_pat_dob',
      HCFA_FIELDS.patientSex
    ];

    const areAllFieldsUndefined = fieldsToCheck.every((fieldName: string) => {
      const value = form?.getFieldValue(fieldName);
      return value === undefined || value === '';
    });

    if (areAllFieldsUndefined) return;

    if (relValue === 'Self') {
      const toSet = {
        '4_ins_last_name': form?.getFieldValue('2_pat_last_name'),
        '4_ins_first_name': form?.getFieldValue('2_pat_first_name'),
        '4_ins_mi': form?.getFieldValue('2_pat_mi'),
        '7_ins_addr_1': form?.getFieldValue('5_pat_addr_1'),
        '7_ins_city': form?.getFieldValue('5_pat_city'),
        '7_ins_state': form?.getFieldValue('5_pat_state'),
        '7_ins_zip': form?.getFieldValue('5_pat_zip'),
        _7e__INSUREDS_TELEPHONE: form?.getFieldValue('_5e__PATIENTS_TELEPHONE'),
        //_7e__INSUREDS_TELEPHONE_code: form?.getFieldValue('_5e__PATIENTS_TELEPHONE_code'),
        ins_addr2: form?.getFieldValue('pat_addr2'),
        [HCFA_FIELDS.insuredDateOfBirth]: form?.getFieldValue('3_pat_dob'),
        [HCFA_FIELDS.insuredSex]: form?.getFieldValue(HCFA_FIELDS.patientSex)
      };

      setData({ ...getFormProvidersDataFromGlobalObject(), ...toSet });
      form?.setFieldsValue({ ...toSet });
    }
  };
  useEffect(() => {
    handleChange();
  }, [relValue]);

  return (
    <Form.Item
      label={
        label ? (
          <span className="form_label_color">{label}</span>
        ) : (
          <span className="form_label_color">PATIENT'S RELATIONSHIP TO INSURED</span>
        )
      }
      name="6_pat_rel_info"
      rules={[getYupValidator(t, HCFA_FIELDS.patRelInfo6)]}
      className="ellipsis"
    >
      <Radio.Group name={HCFA_FIELDS.patRelInfo6}>
        <Radio onClick={nullifyRadioGroup} value="Self">
          {t('common:fields.self')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="Spouse">
          {t('common:fields.spouse')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="Child">
          {t('common:fields.child')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="Other">
          {t('common:other')}
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default FormItemPatientRelationship;
