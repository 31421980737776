import { Form, FormListFieldData } from 'antd';
import { FormContext } from 'components/common/FormProvider';
import React, { useContext, useEffect, useState } from 'react';
import {
  editTableDataInGlobalObject,
  getFormProvidersDataFromGlobalObject
} from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import { useDebounce } from 'utils/hooks/useDebounce';
import { UB04_FIELDS } from 'utils/ub04';

interface Props {
  fields: FormListFieldData[];
}

const TableTotals: React.FC<Props> = ({ fields }) => {
  const tableValues = Form.useWatch('42Services');
  const tableValuesDebounced = useDebounce(tableValues, 100);
  const [totalChargesSum, setTotalChargesSum] = useState<number>(0);
  const [nonCoveredCharges, setNonCoveredCharges] = useState<number>(0);
  const { form, setData } = useContext(FormContext);
  useEffect(() => {
    calculateTotalFees(tableValuesDebounced);
    handleField43Description();
  }, [tableValuesDebounced]);

  function calculateTotalFees(tableValues: any[]) {
    if (!Array.isArray(tableValues)) return;

    let totalChargesSum = 0;
    let nonCovChargesSum = 0;

    tableValues.forEach((row, i) => {
      const totalChargeRowVal = parseFloat(row?.[UB04_FIELDS.total_charges_47] || 0);
      const nonCoveredChargesVal = parseFloat(row?.[UB04_FIELDS.nonCoveredCharges_48] || 0);

      totalChargesSum += totalChargeRowVal;
      nonCovChargesSum += nonCoveredChargesVal;

      const sumAsString = (totalChargeRowVal + nonCoveredChargesVal).toFixed(2);
      editTableDataInGlobalObject(['42Services', i, UB04_FIELDS.ub49], sumAsString);
      form?.setFieldValue(['42Services', i, UB04_FIELDS.ub49], sumAsString);

      const ub49Value = totalChargesSum.toFixed(2);
      const valToSet = ub49Value.endsWith('.00') ? ub49Value : ub49Value.replace(/\.0$/, '');
      setData({ ...getFormProvidersDataFromGlobalObject(), [UB04_FIELDS.ub49]: valToSet });
      form?.setFieldsValue({
        [UB04_FIELDS.ub49]: valToSet
      });
    });

    setTotalChargesSum(totalChargesSum);
    setData({ ...getFormProvidersDataFromGlobalObject(), '49ub_total_charges_sum': totalChargesSum.toFixed(2) });
    form?.setFieldsValue({
      '49ub_total_charges_sum': totalChargesSum.toFixed(2)
    });

    setNonCoveredCharges(nonCovChargesSum);
  }

  function handleField43Description() {
    tableValues?.forEach((row: any, i: any) => {
      const field1 = row?.[UB04_FIELDS.desc_ndc_code] || '';
      const field2 = row?.[UB04_FIELDS.desc_ndc_qty] || '';
      const field3 = row?.[UB04_FIELDS.desc_ndc_unit] || '';
      const descField = row?.[UB04_FIELDS.hcpcs_description_44];
      const combinationOfFieldf = `${field1} ${field2} ${field3}`;

      if (field1 || field2 || field3) {
        editTableDataInGlobalObject(['42Services', i, UB04_FIELDS.field_43_description], combinationOfFieldf.trim());
        form?.setFieldValue(['42Services', i, UB04_FIELDS.field_43_description], combinationOfFieldf.trim());
      } else {
        editTableDataInGlobalObject(['42Services', i, UB04_FIELDS.field_43_description], descField);
        form?.setFieldValue(['42Services', i, UB04_FIELDS.field_43_description], descField);
      }
    });
  }

  if (fields?.length > 0) {
    return (
      <div className="flex bt-2 bb-2 bold">
        <div className="padding-5" style={{ width: '13%' }}></div>
        <div className="padding-5" style={{ width: '15%' }}>
          {/* Page 1 of 1 */}
        </div>
        <div className="padding-5 float-right" style={{ width: '10%' }}>
          {/* {'CREATION DATE'} */}
        </div>
        <div style={{ width: 'calc(12% - 22px)' }}></div>
        <div className="padding-5" style={{ width: '11%' }}></div>
        <div className="padding-5 br-2" style={{ width: 'calc(11% - 5px)' }}>
          {'TOTALS'}
        </div>
        <div className="padding-5 br-2" style={{ width: 'calc(10% - 2px)' }}>
          {totalChargesSum.toFixed(2)}
        </div>
        <div className="padding-5" style={{ width: '10%' }}>
          {nonCoveredCharges.toFixed(2)}
        </div>
        <div style={{ width: '8%' }}></div>
      </div>
    );
  }
  return null;
};

export default TableTotals;
