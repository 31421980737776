import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Button } from '@datum/react-components';
import { Col, Divider, Form, Input, Modal, Row, Select, Skeleton, Spin, notification } from 'antd';
import { deleteBrand, getBranding, getImage, submitBranding, updateBranding } from 'api/branding';
import defaultColorsJson from '../../styles/defaultColors.json';
import React, { useState, useEffect } from 'react';
import { useBrandingContext } from 'components/common/BrandingProvider';
import { Link } from 'react-router-dom';
import { useUserData } from 'utils/hooks/useUserData';
import NotAllowedPage from 'components/app/NotAllowedPage';
import { onSearchSelect } from 'utils/search/search';
import ColorPalette from './ColorPalette';

const { confirm } = Modal;

export const LOB_OPTIONS = [
  { value: 'OH', label: 'OH' },
  { value: 'IN', label: 'IN' },
  { value: 'KY', label: 'KY' },
  { value: 'GA', label: 'GA' },
  { value: 'WV', label: 'WV' },
  { value: 'NC', label: 'NC' },
  { value: 'AR', label: 'AR' },
  { value: 'MS', label: 'MS' },
  { value: 'MI', label: 'MI' },
  { value: 'IA', label: 'IA' },
  { value: 'WI', label: 'WI' }
];

export const STATE_OPTIONS = [
  { value: 'OH', label: 'Ohio' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'GA', label: 'Georgia' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MI', label: 'Michigan' },
  { value: 'IA', label: 'Iowa' },
  { value: 'WI', label: 'Wisconsin' }
];

const requiredJsonProperties = [
  'primary-color',
  'neutral-link-color',
  'primary-link-color',
  'selected-text-color',
  'regular-text-color'
];

function Brabnding() {
  const { accessToken } = useOidcAccessToken();
  const [form] = Form.useForm();
  const { isAdmin } = useUserData();
  const { branding, setBranding } = useBrandingContext();

  const [logoImgUrl, setLogoImgUrl] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileJsonContent, setSelectedFileJsonContent] = useState<Record<string, string>>({});
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const colorObject = branding?.colorPalette ? JSON.parse(branding?.colorPalette) : defaultColorsJson;

  useEffect(() => {
    if (branding?.logoStorageId) {
      getImageFunction();
    }
    if (branding?.colorPalette) {
      try {
        const jsonData = JSON.parse(branding.colorPalette);
        setSelectedFileJsonContent(jsonData);
      } catch (e) {
        //
      }
    }
  }, [branding]);

  useEffect(() => {
    if (!branding) getBrandingFunction();
  }, []);

  useEffect(() => {
    if (branding) {
      form.setFieldsValue(branding);
    }
  }, [branding, form]);

  const getBrandingFunction = async () => {
    if (accessToken) {
      try {
        const data = await getBranding(accessToken);
        if (data) {
          setBranding(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.warn({
          message: <div>Brand not created for your LOB</div>
        });
      } finally {
        //
      }
    }
  };

  const getImageFunction = async () => {
    let logoStorageId = branding?.logoStorageId;
    if (logoStorageId) {
      try {
        const data = await getImage(logoStorageId, accessToken);
        if (data) {
          setLogoImgUrl(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
      }
    }
  };

  const onFinish = async (values: any) => {
    let formData = new FormData();
    values?.state && formData.append('state', values.state);
    values?.lob && formData.append('lob', values.lob);
    values?.providerId && formData.append('providerId', values.providerId);
    values?.title && formData.append('title', values.title);
    selectedImage && formData.append('logo', selectedImage);
    selectedFile && formData.append('colorPalette', selectedFile);

    if (branding?.id) {
      try {
        let res = await updateBranding(formData, accessToken, branding.id);

        notification.success({
          message: <div>Brand Updated!</div>
        });
        setBranding(res);
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: <div>Something went wrong! </div>
        });
      }
    } else {
      try {
        let res = await submitBranding(formData, accessToken);

        notification.success({
          message: <div>Brand Created!</div>
        });
        setBranding(res);
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: <div>Something went wrong!</div>
        });
      }
    }
  };

  function validateUploadedJson(jsonContent: any) {
    return requiredJsonProperties.every((prop) => prop in jsonContent);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onloadend = function () {
        const jsonContent = reader.result as string;

        try {
          const jsonData = JSON.parse(jsonContent);

          const isValid = validateUploadedJson(jsonData);
          if (isValid) {
            form.setFieldValue('colorPalette', jsonData);
            form.validateFields(['colorPalette']);
            setSelectedFileJsonContent(jsonData);
            setSelectedFile(file);
          } else {
            notification.error({ message: getErrorContent(jsonData), duration: 5 });
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          notification.error({ message: 'Could not read file content' });
        }
      };

      reader.readAsText(file);
    } else {
      notification.error({ message: 'Wrong file type' });
    }
  };

  function getErrorContent(jsonContent: any): React.ReactNode {
    const missingProps = requiredJsonProperties.filter((prop) => !(prop in jsonContent));

    return (
      <div>
        <div>Content of uploaded file does not match required model!</div>
        <div>Missing properties:</div>
        <ul style={{ paddingLeft: 0 }}>
          {missingProps.map((prop) => (
            <li style={{ color: 'red', fontWeight: 600, fontSize: 16 }} key={prop}>
              {prop}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  const showConfirm = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete it?',
      okText: 'Delete',
      onOk() {
        handleDeleteBrand();
      },
      onCancel() {}
    });
  };

  async function handleDeleteBrand() {
    setLoading(true);
    if (branding?.id) {
      try {
        await deleteBrand(accessToken, branding.id);

        notification.success({
          message: <div>Brand Deleted!</div>
        });
        resetAll();
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = function () {
        const base64String = reader.result as string;
        setLogoImgUrl(base64String);
      };

      reader.readAsDataURL(file);
      setSelectedImage(file);
    }
  };

  const resetAll = () => {
    setBranding(null);
    form.setFieldsValue({ logo: null, lob: null, providerId: null, colorPalette: null, title: null, state: null });
    setSelectedFile(null);
    setSelectedImage(null);
    setLogoImgUrl(undefined);
  };

  if (!isAdmin) {
    return <NotAllowedPage />;
  }

  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">BRANDING PAGE</Divider>
          <p>
            Welcome to your Branding Page, where you can showcase your brand's identity. Customize your brand's
            appearance and provide essential details to make your online presence uniquely yours.
          </p>
          {isAdmin && (
            <p>
              <Link to={'/admin/all-brands'}>Click to see All brands list</Link>
            </p>
          )}
        </div>
        <div className="box">
          <Row style={{ maxWidth: '960px' }} className="branding-header">
            <Col span={8}></Col>

            <Col span={16} className="your-brand-wrapper">
              <h1>YOUR BRAND</h1>

              <ColorPalette colorObject={colorObject} />
            </Col>
          </Row>
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 960 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="logo"
              label="Logo File"

              //rules={[{ required: true, message: 'Upload Logo file' }]}
            >
              <input type="file" accept="image/*" onChange={handleImageChange} />

              <div style={{ display: 'flex', alignItems: 'center', width: '100%', minHeight: '90px' }}>
                {!logoImgUrl ? (
                  <Skeleton.Avatar style={{ margin: '1rem auto' }} size={'large'} shape={'square'} />
                ) : (
                  <img src={logoImgUrl} alt="Logo" className="logo-img" />
                )}
              </div>
            </Form.Item>

            <Form.Item
              label="BRAND TITLE"
              name="title"
              rules={[{ required: true, message: 'Please type your brand title!' }]}
            >
              <Input placeholder="CareSource" />
            </Form.Item>

            <Form.Item
              label="STATE"
              name="state"
              dependencies={['lob']}
              rules={[
                { required: true, message: 'Please type your brand State!' },
                {
                  validator: (_, value) => {
                    const lobValue = form.getFieldValue('lob');
                    if (!value || !lobValue) return Promise.resolve();
                    if (lobValue === value) return Promise.resolve();
                    return Promise.reject('State does not match LOB');
                  }
                }
              ]}
            >
              <Select showSearch placeholder="Ohio" options={STATE_OPTIONS} />
            </Form.Item>

            <Form.Item label="LOB" name="lob" rules={[{ required: true, message: 'Please type your brand LOB!' }]}>
              <Select showSearch placeholder="OH" options={LOB_OPTIONS} />
            </Form.Item>

            <Form.Item
              name="colorPalette"
              label="Color palette"
              valuePropName="fileList"
              className="color-palette-wrapper"
              rules={[{ required: true, message: 'Upload Color Palette JSON file' }]}
            >
              <input accept=".json" type="file" onChange={handleFileChange} />
              {selectedFileJsonContent && (
                <div className="selected-wrapper">
                  Selected File:{' '}
                  <div className="your-brand-wrapper">
                    <div style={{ marginTop: 0 }}>
                      <ColorPalette colorObject={selectedFileJsonContent} />
                    </div>
                  </div>
                </div>
              )}
            </Form.Item>

            <br />
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button variant="primary" htmltype="submit" style={{ width: '100%' }}>
                SAVE
              </Button>
            </Form.Item>
          </Form>
          <Row style={{ maxWidth: '960px' }}>
            <Col span={8}></Col>
            <Col span={16}>
              {branding?.id && (
                <Button
                  variant="outline"
                  style={{ width: '100%', marginTop: '0.5rem', maxWidth: '150px', margin: '0 auto' }}
                  onClick={showConfirm}
                  loading={loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                >
                  DELETE BRAND
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Brabnding;
