import { DENTAL_FIELDS } from 'utils/dental/fields';
import { HCFA_FIELDS } from 'utils/hcfa/fields';
import { UB04_FIELDS } from 'utils/ub04';

export const hcfaFields: Record<string, any>[] = [
  {
    key: 'submit',
    name: 'Submission date',
    type: 'daterange',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'claimId',
    name: 'ID',
    type: 'string',
    categoryName: 'search',
    required: false,
    allowMultiple: true,
    description: ''
  },
  {
    key: 'groupId',
    name: 'Claim ID',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },

  {
    key: 'patLastName',
    name: 'Patient Last Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'patFirstName',
    name: 'Patient First Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insuredId',
    name: 'Insured Id',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insLastName',
    name: 'Insured Last Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insFirstName',
    name: 'Insured First Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'status',
    name: 'Status',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },

  {
    key: 'patDob',
    name: 'Patient birth',
    type: 'date',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insDob',
    name: 'Insured birth',
    type: 'date',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'totalCharges',
    name: 'Total Charges',
    type: 'number',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'originType',
    name: 'Origin Type',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'dcn',
    name: 'DCN',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  }
];

export const dentalFields: Record<string, any>[] = [
  {
    key: 'submit',
    name: 'Submission date',
    type: 'daterange',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'claimId',
    name: 'ID',
    type: 'string',
    categoryName: 'search',
    required: false,
    allowMultiple: true,
    description: ''
  },
  {
    key: 'groupId',
    name: 'Claim ID',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },

  {
    key: 'patLastName',
    name: 'Patient Last Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'patFirstName',
    name: 'Patient First Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insuredId',
    name: 'Insured Id',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insLastName',
    name: 'Insured Last Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insFirstName',
    name: 'Insured First Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'status',
    name: 'Status',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },

  {
    key: 'patDob',
    name: 'Patient birth',
    type: 'date',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insDob',
    name: 'Insured birth',
    type: 'date',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'totalCharges',
    name: 'Total Charges',
    type: 'number',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'originType',
    name: 'Origin Type',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'dcn',
    name: 'DCN',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  }
];

export const ub04Fields: Record<string, any>[] = [
  {
    key: 'submit',
    name: 'Submission date',
    type: 'daterange',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'claimId',
    name: 'ID',
    type: 'string',
    categoryName: 'search',
    required: false,
    allowMultiple: true,
    description: ''
  },
  {
    key: 'groupId',
    name: 'Claim ID',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },

  {
    key: 'patLastName',
    name: 'Patient Last Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'patFirstName',
    name: 'Patient First Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insuredId',
    name: 'Insured Id',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insLastName',
    name: 'Insured Last Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insFirstName',
    name: 'Insured First Name',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'status',
    name: 'Status',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },

  {
    key: 'patDob',
    name: 'Patient birth',
    type: 'date',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'insDob',
    name: 'Insured birth',
    type: 'date',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'totalCharges',
    name: 'Total Charges',
    type: 'number',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'originType',
    name: 'Origin Type',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  },
  {
    key: 'dcn',
    name: 'DCN',
    type: 'string',
    categoryName: 'search',
    required: false,
    description: ''
  }
];

export const ATTACHMENTS_STEP = 'ATTACHMENTS_STEP';

export const hcfaFormGroups: Record<string, string>[] = [
  { label: `Program and Patient's information`, value: 'step1' },
  { label: `Insured's information`, value: 'step2' },
  { label: 'Physician or supplier information. Patient disability information. Referring provider.', value: 'step3' },
  { label: 'Service items list.', value: 'step4' }
  // { label: 'Attachments', value: ATTACHMENTS_STEP }
];
export const ub04FormGroups: Record<string, string>[] = [
  { label: `Provider and pay-to information`, value: 'step1' },
  { label: `Patient information`, value: 'step2' },
  { label: `Insured's information`, value: 'step3' },
  { label: `Payer's information and service table`, value: 'step4' },
  { label: 'Service, occurence and revenue codes information', value: 'step5' },
  { label: 'Treatment & codes information', value: 'step6' },
  { label: 'Principal Procedure Information', value: 'step7' }
  // { label: 'Attachments', value: ATTACHMENTS_STEP }
];

export const dentalFormGroups: Record<string, string>[] = [
  { label: 'Insurance and Plan inormation', value: 'step1' },
  { label: 'Policyholder and Patient information', value: 'step2' },
  { label: 'Record of Services Provided', value: 'step3' },
  { label: 'Authorization, Billing and treatment information', value: 'step4' }
  // { label: 'Attachments', value: ATTACHMENTS_STEP }
];

export const copyFormModal: Record<string, any>[] = [
  {
    key: 'copyForm',
    name: '',
    type: 'string',
    required: false,
    categoryName: '',
    description: '',
    enum: hcfaFormGroups,
    allowMultiple: true,
    dropdownMinOptions: 6
  }
];

export const hcfaformFields: Record<string, any> = {
  step1: [
    'single_1__INSURANCE_TYPE',
    '2_pat_last_name',
    '2_pat_first_name',
    '2_pat_mi',
    '2_pat_gen',
    '3_pat_dob',
    '5_pat_addr_1',
    '5_pat_city',
    '5_pat_state',
    '5_pat_zip',
    //'_5e__PATIENTS_TELEPHONE_code',
    '_5e__PATIENTS_TELEPHONE',
    '6_pat_rel_info',
    'pat_addr2',
    '_7e__INSUREDS_TELEPHONE_code',
    '_8_reserved_for_nucc',
    '_9b__RESERVED_FOR_NUCC_USE',
    '_9c__RESERVED_FOR_NUCC_USE',
    '9d_oc_plan_name',
    'ins_addr2',
    '9_oc_insured_last_name',
    '9_oc_insured_first_name',
    '9_oc_insured_middle_name',
    '9_oc_insured_generation',
    '9a_oc_ins_id',
    '10a_job_related',
    '10b_auto_accident',
    '10b_accident_state',
    '10c_other_accident',
    '10d_code_1',
    '10d_code_2',
    '10d_code_3',
    '10d_code_4',
    'single_11aa__INSUREDS_SEX',
    '_12__SIGNATURE',
    '_12a__SIGNED_DATE',
    '3_pat_sex'
  ],
  step2: [
    '1a_insured_id',
    '4_ins_last_name',
    '4_ins_first_name',
    '4_ins_mi',
    '4_ins_gen',
    '7_ins_addr_1',
    '7_ins_city',
    '7_ins_state',
    '7_ins_zip',
    '_7e__INSUREDS_TELEPHONE',
    '11_ins_grp_no',
    '_11a__INSUREDS_DATE_OF_BIRTH',
    '11b_other_clm_qual',
    '_11c__INSURANCE_PLAN_NAME_OR_PROGRAM_NAME',
    '_11b__OTHER_CLAIM_ID',
    '11d_other_coverage',
    '13_insured_signature'
  ],
  step3: [
    '14_current_illness',
    '_14b__QUAL',
    '_16a__UNABLE_TO_WORK_FROM',
    '_16b__UNABLE_TO_WORK_TO',
    '_15__OTHER_DATE',
    '_15__OTHER_QUAL',
    '17_refer_prov_qual',
    '17a_refer_prov_id',
    '17b_referring_npi',
    '17a_ref_prov_id_qual',
    '17_ref_title',
    '17_ref_gen',
    '_18a__HOSPITALIZATION_FROM',
    '_18b__HOSPITALIZATION_TO',
    '_19_ADDITIONAL_CLAIM_INFORMATION',
    '19_additional',
    '20_outside_lab',
    '20_lab_charges',
    '25_billing_tin',
    '25_tin_type',
    '26_pat_id_acct_no',
    '27_assignment',
    '28_total_charges',
    '29_amt_paid',
    '_30__RESERVED_FOR_NUCC_USE',
    '_31a__SIGNATURE_OF_PHYSICIAN_SIGNED',
    '_31b__SIGNATURE_OF_PHYSICIAN_DATE',
    '32_facility_name',
    '32_facility_addr_1',
    '32_facility_city',
    '32_facility_state',
    '32_facility_zip',
    '32b_facility_id',
    '32a_facility_npi',
    '33_billing_addr_1',
    '33_billing_city',
    '33_billing_zip',
    '33_billing_state',
    '33_billing_phone',
    '33_billing_phone_code',
    '32b_facility_id_qual',
    '_33b_',
    '33_billing_name',
    '33a_billing_npi',
    '17_ref_last_name',
    '17_ref_first_name',
    '17_ref_mi',
    // HCFA_FIELDS.HCFA31psysiciancheckbox,
    HCFA_FIELDS.physicianLastName,
    HCFA_FIELDS.physicianFirstName,
    HCFA_FIELDS.physicianMiddleInitial,
    HCFA_FIELDS.HCFA33psysiciancheckbox,
    HCFA_FIELDS.billingName,
    HCFA_FIELDS.providersPhysicianLastname,
    HCFA_FIELDS.providersPhysicianFirstname,
    HCFA_FIELDS.providersPhysicianMiddleIntial,
    HCFA_FIELDS.isAmbulance
  ],
  step4: [
    '21_icd_ind',
    '21a_dx',
    '21b_dx',
    '21c_dx',
    '21d_dx',
    '21e_dx',
    '21f_dx',
    '21g_dx',
    '21h_dx',
    '21i_dx',
    '21j_dx',
    '21k_dx',
    '21l_dx',
    '22_resub_code',
    '22_original_ref_no',
    '23_authorization',
    '24Table',
    HCFA_FIELDS.numberType
  ],
  [ATTACHMENTS_STEP]: []
};

export const formTableFields: Record<string, any> = [
  '24_description',
  '24a_dos_from',
  '24a_dos_to',
  '24b_place_of_service',
  '24c_emg',
  '24d_modifier_1',
  '24e_dx_procedure',
  '24e_dx_pointer',
  '24f_charges',
  '24g_units',
  '24_epsdt',
  '24h_family_plan',
  '24i_render_id_qual',
  '24j_render_id',
  '24j_render_npi'
];

export const ub04FormFields: Record<string, any> = {
  step1: [
    'Payer_Name',
    '1a_billing_name',
    '1b_billing_addr_1',
    'billing_addr2',
    '1c_billing_city',
    '1d_billing_state',
    '1e_billing_zip',
    '1f_billing_phone_code',
    '1f_billing_phone',
    '4_type_of_bill',
    '5_federal_tax_number',
    '2a_pay_to_name',
    '2b_pay_to_address',
    'pay_to_addr2',
    'pat_addr2',
    '2c_pay_to_city',
    '2d_pay_to_state',
    '2e_pay_to_zip',
    '2f_phone',
    '5_billing_tin',
    '56_billing_npi',
    '76_attn_first_name',
    '76_attn_gen',
    '76_attn_id',
    '76_attn_last_name',
    '76_attn_npi',
    '76_attn_qual',
    '77_oper_first_name',
    '77_oper_id',
    '77_oper_last_name',
    '77_attn_gen',
    '77_oper_npi',
    '77_oper_qual',
    '78_other_first_name',
    '78_other_gen',
    '78_other_last_name',
    '78_other_npi',
    '78_other_prov_id',
    '78_other_prov_qual',
    '79_other_first_name',
    '79_other_gen',
    '79_other_last_name',
    '79_other_npi',
    '79_other_prov_id',
    '79_other_prov_qual'
  ],
  step2: [
    '3a_pat_ctrl_no',
    '3b_med_rec_no',
    '8a_pat_id',
    '8b_pat_first_name',
    '8b_pat_gen',
    '8b_pat_last_name',
    '8b_pat_mi',
    '9a_pat_address',
    '9b_pat_city',
    '9c_pat_state',
    '9d_pat_zip',
    '10_pat_dob',
    '3_pat_sex'
  ],
  step3: [
    '58a_ins_first_name',
    '58a_ins_gen',
    '58a_ins_last_name',
    '58a_ins_mid_name',
    '58b_ins_first_name',
    '58b_ins_gen',
    '58b_ins_last_name',
    '58b_ins_mid_name',
    '58c_ins_first_name',
    '58c_ins_gen',
    '58c_ins_last_name',
    '58c_ins_mid_name',
    '59a_pat_rel',
    '59b_pat_rel',
    '59c_pat_rel',
    '60a_ins_id',
    '60b_ins_id',
    '60c_ins_id',
    '61a_grp_name',
    '61b_grp_name',
    '61c_grp_name',
    '62a_ins_grp_no',
    '62b_ins_grp_no',
    '62c_ins_grp_no'
  ],
  step4: [
    '50a_payer',
    '50b_payer',
    '50c_payer',
    '51a_plan_id',
    '51b_plan_id',
    '51c_plan_id',
    '52a_rel_info',
    '52b_rel_info',
    '52c_rel_info',
    '53a_asg_ben',
    '53b_asg_ben',
    '53c_asg_ben',
    '54a_prior_pmt',
    '54b_prior_pmt',
    '54c_prior_pmt',
    '55a_est_amt_due',
    '55b_est_amt_due',
    '55c_est_amt_due',
    '57a_billing_id',
    '57b_billing_id',
    '57c_billing_id',
    // 'payer_destination_a',
    // 'payer_destination_b',
    // 'payer_destination_c',
    '42Services'
  ],
  step5: [
    '6_stmt_from_date',
    '6_stmt_to_date',
    '12_admit_date',
    '13_admission_hour',
    '14_admission_type',
    '16_discharge_hr',
    '17_discharge_stat',
    '18_condition_code',
    '19_condition_code',
    '20_condition_code',
    '21_condition_code',
    '22_condition_code',
    '23_condition_code',
    '24_condition_code',
    '25_condition_code',
    '26_condition_code',
    '27_condition_code',
    '28_condition_code',
    '29_accident_state',
    '31a_occur_code',
    '31a_occur_date',
    '31b_occur_code',
    '31b_occur_date',
    '32a_occur_code',
    '32a_occur_date',
    '32b_occur_code',
    '32b_occur_date',
    '33b_occur_code',
    '33b_occur_date',
    '35a_span_to',
    '35a_span_code',
    '35a_span_from',
    '35b_span_code',
    '35b_span_from',
    '35b_span_to',
    '36a_span_code',
    '36a_span_from',
    '36a_span_to',
    '36b_span_code',
    '36b_span_from',
    '36b_span_to',
    '38_rp_addr',
    '38_rp_city',
    '38_rp_first_name',
    '38_rp_gen',
    '38_rp_last_name',
    '38_rp_mi',
    '38_rp_state',
    '38_rp_zip_code',
    '39a_value_amount',
    '39a_value_code',
    '39b_value_amount',
    '39b_value_code',
    '39c_value_amount',
    '39c_value_code',
    '39d_value_amount',
    '39d_value_code',
    '40a_value_amount',
    '40a_value_code',
    '40b_value_amount',
    '40b_value_code',
    '40c_value_amount',
    '40c_value_code',
    '40d_value_amount',
    '40d_value_code',
    '41a_value_amount',
    '41a_value_code',
    '41b_value_amount',
    '41b_value_code',
    '41c_value_amount',
    '41c_value_code',
    '41d_value_amount',
    '41d_value_code',
    'revenueCodes',
    '33a_occur_code',
    '33a_occur_date',
    UB04_FIELDS.occurrenceCode34A,
    UB04_FIELDS.occurrenceDate34A,
    UB04_FIELDS.occurrenceCode34B,
    UB04_FIELDS.occurrenceDate34B
  ],
  step6: [
    '31a_occur_code',
    '31a_occur_date',
    '63a_auth',
    '63b_auth',
    '63c_auth',
    '64a_dcn',
    '64b_dcn',
    '64c_dcn',
    '65a_employer',
    '65b_employer',
    '65c_employer',
    '66_dx_ind',
    '67_principal_dx',
    '67_principal_poa',
    '67a_oth_diag',
    '67a_oth_poa',
    '67b_oth_diag',
    '67b_oth_poa',
    '67c_oth_diag',
    '67c_oth_poa',
    '67d_oth_diag',
    '67d_oth_poa',
    '67e_oth_diag',
    '67e_oth_poa',
    '67f_oth_diag',
    '67f_oth_poa',
    '67g_oth_diag',
    '67g_oth_poa',
    '67h_oth_diag',
    '67h_oth_poa',
    '67i_oth_diag',
    '67i_oth_poa',
    '67j_oth_diag',
    '67j_oth_poa',
    '67k_oth_diag',
    '67k_oth_poa',
    '67l_oth_diag',
    '67l_oth_poa',
    '67m_oth_diag',
    '67m_oth_poa',
    '67n_oth_diag',
    '67n_oth_poa',
    '67o_oth_diag',
    '67o_oth_poa',
    '67p_oth_diag',
    '67p_oth_poa',
    '67q_oth_diag',
    '67q_oth_poa',
    '69_admit_dx',
    '70a_pat_reason_dx',
    '70b_pat_reason_dx',
    '70c_pat_reason_dx',
    '71_pps',
    '72a_eci',
    '72a_poa',
    '72b_eci',
    '72b_poa',
    '72c_eci',
    '72c_poa',
    '80_remarks',
    '81a_code',
    '81a_code_qual',
    '81a_code_val',
    '81b_code',
    '81b_code_qual',
    '81b_code_val',
    '81c_code',
    '81c_code_qual',
    '81c_code_val',
    '81d_code',
    '81d_code_qual',
    '81d_code_val'
  ],
  step7: [
    UB04_FIELDS.principalProcedureCode,
    UB04_FIELDS.principalProcedureDate,
    UB04_FIELDS.procedureCode74a,
    UB04_FIELDS.procedureDate74a,
    UB04_FIELDS.procedureCode74b,
    UB04_FIELDS.procedureDate74b,
    UB04_FIELDS.procedureCode74c,
    UB04_FIELDS.procedureDate74c,
    UB04_FIELDS.procedureCode74d,
    UB04_FIELDS.procedureDate74d,
    UB04_FIELDS.procedureCode74e,
    UB04_FIELDS.procedureDate74e
  ],
  [ATTACHMENTS_STEP]: []
};

export const dentalformFields: Record<string, any> = {
  step1: [
    DENTAL_FIELDS.typeOfTransaction,
    DENTAL_FIELDS.predeterminationNo,
    DENTAL_FIELDS.companyName,
    DENTAL_FIELDS.companyCity,
    DENTAL_FIELDS.companyState,
    DENTAL_FIELDS.companyNameAddress,
    DENTAL_FIELDS.companyZipcode,
    DENTAL_FIELDS.subscriberLastName,
    DENTAL_FIELDS.subscriberFirstName,
    DENTAL_FIELDS.subscriberMiddle,
    DENTAL_FIELDS.subscriberSuffix,
    DENTAL_FIELDS.subscriberDateOfBirth,
    DENTAL_FIELDS.subscriberGender,
    DENTAL_FIELDS.subscriberNumber,
    DENTAL_FIELDS.otherCoverage,
    DENTAL_FIELDS.planGroupNumber,
    DENTAL_FIELDS.patientsRelationship,
    DENTAL_FIELDS.insCompanyName,
    DENTAL_FIELDS.insCompanyAddress,
    DENTAL_FIELDS.insCompanyCity,
    DENTAL_FIELDS.insCompanyState,
    DENTAL_FIELDS.insCompanyZipcode
  ],
  step2: [
    DENTAL_FIELDS.subLastName,
    DENTAL_FIELDS.subFirstName,
    DENTAL_FIELDS.subMiddle,
    DENTAL_FIELDS.subSuffix,
    DENTAL_FIELDS.subNameAddress,
    DENTAL_FIELDS.subCity,
    DENTAL_FIELDS.subState,
    DENTAL_FIELDS.subZip,
    DENTAL_FIELDS.subDateOfBirth,
    DENTAL_FIELDS.subGender,
    DENTAL_FIELDS.subId,
    DENTAL_FIELDS.subGroupNumber,
    DENTAL_FIELDS.subEmployerName,
    DENTAL_FIELDS.reservedForFuture,
    DENTAL_FIELDS.patientsRelationship2,
    DENTAL_FIELDS.patLastName,
    DENTAL_FIELDS.patFirstName,
    DENTAL_FIELDS.patMiddle,
    DENTAL_FIELDS.patSuffix,
    DENTAL_FIELDS.patAddress,
    DENTAL_FIELDS.patCity,
    DENTAL_FIELDS.patState,
    DENTAL_FIELDS.patZip,
    DENTAL_FIELDS.patDateOfBirth,
    DENTAL_FIELDS.patGender,
    DENTAL_FIELDS.patIdAccNo
  ],
  step3: [
    DENTAL_FIELDS.diagCodeList,
    DENTAL_FIELDS.diagCodeList,
    DENTAL_FIELDS.dxCodeB,
    DENTAL_FIELDS.dxCodeC,
    DENTAL_FIELDS.dxCodeD,
    DENTAL_FIELDS.remarks,
    '24Table',
    DENTAL_FIELDS.misTeethInfo
  ],
  step4: [
    DENTAL_FIELDS.patGuardSignature,
    DENTAL_FIELDS.enclosures,
    DENTAL_FIELDS.patGuardSignatureDate,
    DENTAL_FIELDS.subSignatureInd,
    DENTAL_FIELDS.subSignatureDate,
    DENTAL_FIELDS.placeOfTreat,
    DENTAL_FIELDS.orthoTreatment,
    DENTAL_FIELDS.appPlaceDate,
    DENTAL_FIELDS.monthOfTreat,
    DENTAL_FIELDS.replacePros,
    DENTAL_FIELDS.priorPlacement,
    DENTAL_FIELDS.tretResultingForm,
    DENTAL_FIELDS.accDate,
    DENTAL_FIELDS.accState,
    DENTAL_FIELDS.billingName,
    DENTAL_FIELDS.billingNameAddress,
    DENTAL_FIELDS.billingCity,
    DENTAL_FIELDS.billingState,
    DENTAL_FIELDS.billingZip,
    DENTAL_FIELDS.billingNpi,
    DENTAL_FIELDS.billingLic,
    DENTAL_FIELDS.billingTin,
    DENTAL_FIELDS.billingAddId,
    DENTAL_FIELDS.treatSign,
    DENTAL_FIELDS.treatSignDate,
    DENTAL_FIELDS.treatAddress,
    DENTAL_FIELDS.treatCity,
    DENTAL_FIELDS.treatState,
    DENTAL_FIELDS.treatZip,
    DENTAL_FIELDS.treatNpi,
    DENTAL_FIELDS.treatLic,
    DENTAL_FIELDS.providerId,
    DENTAL_FIELDS.treatingPhoneCode,
    DENTAL_FIELDS.treatingPhone,
    DENTAL_FIELDS.billingPhoneCode,
    DENTAL_FIELDS.billingPhone,
    DENTAL_FIELDS.provSpecCode
  ],
  [ATTACHMENTS_STEP]: []
};

export const dateInputs: string[] = [
  //hcfa
  '3_pat_dob',
  '_12a__SIGNED_DATE',
  '24a_dos_from',
  '24a_dos_to',
  '_11a__INSUREDS_DATE_OF_BIRTH',
  '14_current_illness',
  '_16a__UNABLE_TO_WORK_FROM',
  '_16b__UNABLE_TO_WORK_TO',
  '_15__OTHER_DATE',
  '_18a__HOSPITALIZATION_FROM',
  '_18b__HOSPITALIZATION_TO',
  '_31b__SIGNATURE_OF_PHYSICIAN_DATE',
  HCFA_FIELDS.insuredSignatureDate,
  //ub
  '6_stmt_from_date',
  '6_stmt_to_date',
  '10_pat_dob',
  '12_admit_date',
  '31a_occur_date',
  '31b_occur_date',
  '32a_occur_date',
  '32b_occur_date',
  '33a_occur_date',
  '33b_occur_date',
  '34a_occur_date',
  '34b_occur_date',
  '35a_span_from',
  '35a_occur_date',
  '35b_span_to',
  '35b_span_from',
  '36a_span_from',
  '36b_span_from',
  '36b_span_to',
  '45_dos',
  '74_prin_proc_date',
  '74a_other_proc_date',
  '74b_other_proc_date',
  '74c_other_proc_date',
  '74d_other_proc_date',
  '74e_other_proc_date',
  '24_procedure_date',
  '13_sub_dob',
  '21_pat_dob',
  '6_oc_sub_dob',
  '41_appliance_placed_date',
  '44_prior_placement',
  '37_sub_sign_date',
  '36_pat_guard_sign_date',
  '46_accident_date',
  '53_treating_sign_date',
  '36a_span_to',
  '35a_occur_to',
  '35_first_visit_date',
  '35a_span_to'
];

export const currencyInputs: string[] = [];
