import { CONFIG } from 'config/constants';
import * as React from 'react';
import { Helmet } from 'react-helmet';

type CustomHelmetProps = {
  section: string;
};
function CustomHelmet({ section }: CustomHelmetProps): JSX.Element {
  return (
    <Helmet>
      <title>
        {CONFIG.APP_NAME} - {section}
      </title>
    </Helmet>
  );
}

export default CustomHelmet;
