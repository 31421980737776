import { TestContext } from 'yup';
import { AnyObject } from 'yup/lib/types';
import moment from 'moment';
import { isValidLuhn } from 'helpers/checkLuhnLogic';
import { HCFA_FIELDS } from 'utils/hcfa/fields';
import { DENTAL_FIELDS } from './fields';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import { checkIsPostBox } from 'helpers/checkIsPostBox';

type ValueType = string | null | undefined;
type ContextType = TestContext<AnyObject>;
export type HcfaTableChange = [...undefined[], Record<string, string>];
export type HcfaFormValueChange = Record<string, string | HcfaTableChange>;

const ValidationsDependenciesMap = {
  [DENTAL_FIELDS.patAddress]: [DENTAL_FIELDS.patCity, DENTAL_FIELDS.patState, DENTAL_FIELDS.patZip],

  [DENTAL_FIELDS.patientsRelationship2]: [
    DENTAL_FIELDS.subLastName,
    DENTAL_FIELDS.subFirstName,
    DENTAL_FIELDS.patLastName,
    DENTAL_FIELDS.patState,
    DENTAL_FIELDS.patFirstName,
    DENTAL_FIELDS.treatState
  ],
  [DENTAL_FIELDS.subLastName]: [DENTAL_FIELDS.patientsRelationship2],
  [DENTAL_FIELDS.subFirstName]: [DENTAL_FIELDS.patientsRelationship2],
  [DENTAL_FIELDS.patLastName]: [DENTAL_FIELDS.patientsRelationship2],
  [DENTAL_FIELDS.treatState]: [DENTAL_FIELDS.patientsRelationship2],
  [DENTAL_FIELDS.patState]: [DENTAL_FIELDS.patientsRelationship2],
  [DENTAL_FIELDS.patFirstName]: [DENTAL_FIELDS.patientsRelationship2],
  [DENTAL_FIELDS.resubmissionCode]: [DENTAL_FIELDS.originalRefNumber],

  [DENTAL_FIELDS.subscriberLastName]: [DENTAL_FIELDS.subscriberFirstName, DENTAL_FIELDS.otherCoverage],
  [DENTAL_FIELDS.subscriberFirstName]: [DENTAL_FIELDS.subscriberLastName, DENTAL_FIELDS.otherCoverage],
  [DENTAL_FIELDS.otherCoverage]: [
    DENTAL_FIELDS.subscriberLastName,
    DENTAL_FIELDS.subscriberFirstName,
    DENTAL_FIELDS.subscriberGender,
    DENTAL_FIELDS.subscriberNumber,
    DENTAL_FIELDS.planGroupNumber,
    DENTAL_FIELDS.patientsRelationship,
    DENTAL_FIELDS.dentalBenefitPlanName,
    DENTAL_FIELDS.insCompanyAddress
  ],
  [DENTAL_FIELDS.treatNpi]: [DENTAL_FIELDS.treatLic],
  [DENTAL_FIELDS.treatLic]: [DENTAL_FIELDS.treatNpi],
  [DENTAL_FIELDS.billingFirstName]: [DENTAL_FIELDS.organizationName, DENTAL_FIELDS.billingLastName],
  [DENTAL_FIELDS.organizationName]: [DENTAL_FIELDS.billingFirstName, DENTAL_FIELDS.billingLastName],
  [DENTAL_FIELDS.billingLastName]: [DENTAL_FIELDS.organizationName, DENTAL_FIELDS.billingFirstName],
  [DENTAL_FIELDS.billingNpi]: [
    DENTAL_FIELDS.organizationName,
    DENTAL_FIELDS.billingFirstName,
    DENTAL_FIELDS.billingLastName
  ],
  [DENTAL_FIELDS.patState]: [DENTAL_FIELDS.billingNameAddress],
  [DENTAL_FIELDS.subState]: [DENTAL_FIELDS.billingNameAddress],
  [DENTAL_FIELDS.billingAddress2]: [DENTAL_FIELDS.billingNameAddress]
};

const ValidationTableDependenciesMap = {
  '24j_render_id': ['24g_units', '24i_render_id_qual'],
  '24a_dos_from': ['24a_dos_to'],
  '24d_procedure': ['24d_modifier_1', '24d_modifier_2', '24d_modifier_3', '24d_modifier_4'],
  '24d_modifier_1': ['24d_modifier_2', '24d_modifier_3', '24d_modifier_4'],
  '24d_modifier_2': ['24d_modifier_1', '24d_modifier_3', '24d_modifier_4'],
  '24d_modifier_3': ['24d_modifier_1', '24d_modifier_2', '24d_modifier_4'],
  '24d_modifier_4': ['24d_modifier_1', '24d_modifier_2', '24d_modifier_3'],
  '34_dx_code_a': ['29_diag_pointer'],
  '34_dx_code_b': ['29_diag_pointer'],
  '34_dx_code_c': ['29_diag_pointer'],
  '34_dx_code_d': ['29_diag_pointer']
};
/**get index for 24Table[index].fieldName */
function extractIndexFor24TableYup(inputString: string): number | null {
  const regexPattern = /24Table\[(\d+)\]\.\w+/;

  const match = inputString.match(regexPattern);

  if (match && match[1]) {
    return parseInt(match[1], 10);
  } else {
    return null;
  }
}
/**get index for 24Table.index.fieldName */
function extractIndexFor24TableAntd(modelString: string): number | null {
  const regex = /^24Table\.(\d+)\..+$/;
  const match = modelString.match(regex);

  if (match && match[1]) {
    const index = parseInt(match[1], 10);
    return isNaN(index) ? null : index;
  }

  return null;
}

function getFormValues(context: ContextType) {
  const data = getFormProvidersDataFromGlobalObject();
  return data ? data : context.parent;
}

function testRelationRegardingPatientInsured(value: ValueType, context: ContextType) {
  if (value !== 'self') return true;
  const formValues = getFormValues(context);
  const patFirstName = formValues[DENTAL_FIELDS.subLastName];
  const patlastName = formValues[DENTAL_FIELDS.subFirstName];
  const insFirstName = formValues[DENTAL_FIELDS.patLastName];
  const inslastName = formValues[DENTAL_FIELDS.patFirstName];
  console.log('is same ', patFirstName, insFirstName, patlastName, inslastName, Date.now());
  return patFirstName == insFirstName && patlastName == inslastName;
}

function testPatientInsuredRegardingRelation(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patientsRelationship2];

  if (relationValue === 'self') {
    return true;
  }

  return Boolean(value);
}

function testPatientsRelationship2(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patientsRelationship2];
  if (relationValue === 'self') {
    return true;
  }
  return Boolean(value);
}

function testPatientsRelationship22isOtherThenSelf(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patientsRelationship2];
  if (relationValue === 'self' && relationValue) {
    return true;
  }

  return Boolean(value);
}

function testPatientsRelationshipIsSelf(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patientsRelationship2];
  if (relationValue === 'self' || relationValue == null) {
    return true;
  }

  return Boolean(value);
}

function testOtherCoverage1(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.otherCoverage];

  if (!(relationValue?.length > 0)) {
    return true;
  }

  return Boolean(value);
}

function testOtherCoverage2(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.otherCoverage];

  if (!(relationValue?.length > 0)) {
    return true;
  }

  return Boolean(value);
}

function testOtherCoverage3(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.otherCoverage];

  if (!(relationValue?.length > 0)) {
    return true;
  }

  return Boolean(value);
}

function testBillingOrTreatingNpi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const treatNpi = formValues[DENTAL_FIELDS.treatNpi];
  const billNpi = formValues[DENTAL_FIELDS.billingNpi];
  if (!treatNpi || !billNpi) {
    return true;
  }

  return Boolean(value);
}

function testIsBillingIdAndIdBlank(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const npi = formValues[DENTAL_FIELDS.billingNpi];
  const id = formValues[DENTAL_FIELDS.billingLic];
  if (!npi && !id) return false;
  return true;
}

function testNPIforLuhnAlghoritm(value: ValueType, context: ContextType) {
  if (!value) return true;
  return isValidLuhn(value);
}

function testBillingAndTreatNpi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const treatNpi = formValues[DENTAL_FIELDS.treatNpi];
  const billNpi = formValues[DENTAL_FIELDS.billingNpi];
  const provSpecCode = formValues[DENTAL_FIELDS.provSpecCode];
  if (treatNpi !== billNpi && !provSpecCode) {
    return true;
  }

  return Boolean(value);
}

function testCompanyNameAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.companyNameAddress];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testBillingAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingNameAddress];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testSubSignatureInd(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.subSignatureInd];
  if (relationValue !== 'Signed') {
    return true;
  }

  return Boolean(value);
}

function testOrthoTreatment(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.orthoTreatment];
  if (relationValue !== 'Y') {
    return true;
  }

  return Boolean(value);
}

function testTreatSign(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.treatSign];
  if (relationValue !== 'Signed') {
    return true;
  }

  return Boolean(value);
}

function testPatGuardSignature(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patGuardSignature];

  if (relationValue !== 'Signed') {
    return true;
  }

  return Boolean(value);
}

function testReplacePros(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.replacePros];

  if (relationValue !== 'Y') {
    return true;
  }

  return Boolean(value);
}

function testPatientDateOfBirth(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value).endOf('day');
  if (!date.isValid()) return true;
  const patDateOfBirth = formValues[DENTAL_FIELDS.patDateOfBirth];

  if (!patDateOfBirth) return true;
  return !date.isSameOrBefore(patDateOfBirth);
}

function testAnyAccident(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const tretResulting = formValues[DENTAL_FIELDS.tretResultingForm];
  if (!tretResulting?.length) return true;
  return Boolean(value);
}

function testInsCompanyAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.insCompanyAddress];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testSubFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.subFirstName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testSubLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.subLastName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testSubscriberFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.subscriberFirstName];

  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testSubscriberLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.subscriberLastName];

  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testTreatFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.treatFirstName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testTreatLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.treatLastName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testreatNpi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.treatNpi];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testBillingFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingFirstName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testBillingLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingLastName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testHasBillingFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingFirstName];
  if (relationValue) {
    return true;
  }

  return Boolean(value);
}

function testHasBillingLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingLastName];
  if (relationValue) {
    return true;
  }

  return Boolean(value);
}

function testNpi49ForFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingNpi];
  const billingLastName = formValues[DENTAL_FIELDS.billingLastName];
  const organizationName = formValues[DENTAL_FIELDS.organizationName];
  if (!relationValue) {
    return true;
  }
  if (organizationName) return true;
  if (billingLastName && !value) return false;
  return Boolean(value);
}
function testNpi49ForLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingNpi];
  const billingFirstName = formValues[DENTAL_FIELDS.billingFirstName];
  const organizationName = formValues[DENTAL_FIELDS.organizationName];
  if (!relationValue) {
    return true;
  }
  if (organizationName) return true;
  if (billingFirstName && !value) return false;
  return Boolean(value);
}
function testNpi49ForOrganizationName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.billingNpi];
  const billingFirstName = formValues[DENTAL_FIELDS.billingFirstName];
  const billingLastName = formValues[DENTAL_FIELDS.billingLastName];
  if (!relationValue) {
    return true;
  }
  if (billingFirstName || billingLastName) return true;
  return Boolean(value);
}
function testNpi54(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.treatNpi];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testPatLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patLastName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testPatFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patFirstName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testPatAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patAddress];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testSubNameAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.subNameAddress];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testPatState(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patState];
  if (relationValue) {
    return true;
  }

  return Boolean(value);
}
function testPatRel(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.patientsRelationship2];
  if (relationValue !== 'self') {
    return true;
  }

  return Boolean(value);
}

function testTreatAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.treatAddress];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testFutureDate(value: ValueType) {
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const today = moment(Date.now()).endOf('day');
  return date.isSameOrBefore(today);
}

function testRequiredByOtherCoverage(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const otherCoverage = formValues[DENTAL_FIELDS.otherCoverage];
  if (otherCoverage && otherCoverage.length === 0) return true;
  if (otherCoverage && !value) return false;
  return true;
}

export const test100YearsPast = (value: ValueType) => {
  if (!value) return true;
  const _value = moment(value, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true);
  const oneHundredYearsAgo = moment().subtract(100, 'years');
  if (!_value.isValid()) return true;
  return _value.isSameOrAfter(oneHundredYearsAgo);
};

function testTypeOfTransaction(value: any, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[DENTAL_FIELDS.typeOfTransaction];

  if (relationValue && relationValue.includes('1_request_for_preauth')) {
    return false;
  }

  return true;
}

function testAccidentStateWhenAutoAccident(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const tretResulting = formValues[DENTAL_FIELDS.tretResultingForm];
  if (!tretResulting?.length || tretResulting?.[0] !== '2') return true;
  return Boolean(value);
}

function testCurrentDate14ToAccidentAndQualifier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const otherAccidentValue = formValues[HCFA_FIELDS.otherAccident];
  const autoAccidentValue = formValues[HCFA_FIELDS.autoAccident];
  const qualifierValue = formValues[HCFA_FIELDS.quality];
  const isAutoAccidentYes =
    autoAccidentValue && typeof autoAccidentValue === 'string' && autoAccidentValue.toLowerCase() === 'yes';
  const isOtherAccidentYes =
    otherAccidentValue && typeof otherAccidentValue === 'string' && otherAccidentValue.toLowerCase() === 'yes';
  if (qualifierValue || isAutoAccidentYes || isOtherAccidentYes) return Boolean(value);
  return true;
}

function testQualifierToCurrentDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const currentDateValue = formValues[HCFA_FIELDS.currentIllness];
  if (currentDateValue) return Boolean(value);
  return true;
}

function testOtherDateToQualifier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const qualifierValue = formValues[HCFA_FIELDS.otherQuality];
  if (qualifierValue) return Boolean(value);
  return true;
}

function testOtherQualifierToOtherDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const dateValue = formValues[HCFA_FIELDS.otherDate];
  if (dateValue) return Boolean(value);
  return true;
}

function testLabCharges(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const outsideLab = formValues[HCFA_FIELDS.outsideLab];
  if (outsideLab && typeof outsideLab === 'string' && outsideLab.toLowerCase() === 'yes') return Boolean(value);
  return true;
}

function testOriginalRefNumToRessubmission(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const ressValues = ['6', '7', '8'];
  const selectedRessValue = formValues[DENTAL_FIELDS.resubmissionCode];
  if (!selectedRessValue) return true;
  if (ressValues.includes(selectedRessValue) && !value) return false;
  return true;
}

function testTableUnits(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;

  const idValue = row['24j_render_id'];

  if (idValue) return Boolean(value);
  return true;
}

function testProviderNameToSupplier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physicianValue = formValues[HCFA_FIELDS.HCFA33psysiciancheckbox];
  const isSupplier =
    physicianValue && typeof physicianValue === 'string' && physicianValue.toLowerCase() === 'supplier';
  if (!isSupplier) return true;
  return Boolean(value);
}

function testProviderNameToPhysician(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physicianValue = formValues[HCFA_FIELDS.HCFA33psysiciancheckbox];
  const isPhysician =
    physicianValue && typeof physicianValue === 'string' && physicianValue.toLowerCase() === 'physician';
  if (!isPhysician) return true;
  return Boolean(value);
}

function testAmountPaidToTotal(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const totalCharges = formValues[HCFA_FIELDS.totalCharges];
  if (!totalCharges || !value) return true;
  const totalChargesParsed = parseFloat(totalCharges);
  const valueParsed = parseFloat(value);
  if (isNaN(totalChargesParsed) || isNaN(valueParsed)) return true;
  return valueParsed <= totalChargesParsed;
}

function testTotalChargeToTableFields(value: ValueType, context: ContextType) {
  if (!value) return true;
  const valueParsed = parseFloat(value);
  if (isNaN(valueParsed)) return true;
  const formValues = getFormValues(context);
  const tableValues: any[] = formValues['24Table'];
  if (!tableValues && !Array.isArray(tableValues)) return valueParsed == 0;
  let sum = 0;
  tableValues.forEach((row) => {
    const chargeValue = row?.[HCFA_FIELDS.chargesf24];
    if (chargeValue && !isNaN(parseFloat(chargeValue))) {
      sum += parseFloat(chargeValue);
    }
  });
  return valueParsed == sum;
}

function testIsBeforeBirthDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const birthDate = formValues['3_pat_dob'];
  if (!birthDate) return true;
  const birthDateMom = moment(birthDate).startOf('day');
  if (!birthDateMom.isValid()) return true;
  return date.isSameOrAfter(birthDateMom);
}

function testIsToAfterFrom(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const fromDate = row?.['24a_dos_from'];
  if (!fromDate) return true;
  const fromDateMom = moment(fromDate).startOf('day');
  if (!fromDateMom.isValid()) return true;
  return date.isSameOrAfter(fromDateMom);
}

function testModifiersToCPT1(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_2', '24d_modifier_3', '24d_modifier_4']);
}
function testModifiersToCPT2(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_1', '24d_modifier_3', '24d_modifier_4']);
}
function testModifiersToCPT3(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_1', '24d_modifier_2', '24d_modifier_4']);
}
function testModifiersToCPT4(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_1', '24d_modifier_2', '24d_modifier_3']);
}

function testModifiers(value: ValueType, context: ContextType, toTest: [string, string, string]) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;
  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const cptValue = row['24d_procedure'];
  if (!cptValue) return true;
  const hasDuplicate = toTest.some((path) => row[path] && row[path] == value);
  return !hasDuplicate;
}

function testSubIdIsNumeric(value: any, context: ContextType) {
  if (!value) return true;
  const numericRegex = /^[0-9]+$/;
  const lob = sessionStorage.getItem('lob');
  const formValues = getFormValues(context);
  const patState = formValues[DENTAL_FIELDS.patState];
  const subState = formValues[DENTAL_FIELDS.subState];
  if (lob !== 'mi' && patState !== 'MI' && subState !== 'MI' && !numericRegex.test(value as string)) return false;
  return true;
}
function testSubIdIsAlphanumeric(value: ValueType, context: ContextType) {
  if (!value) return true;
  const alphaNumStartsEndsWithNumber = /^[0-9]+[0-9a-zA-Z]*[0-9]+$/;
  const lob = sessionStorage.getItem('lob');
  const formValues = getFormValues(context);
  const patState = formValues[DENTAL_FIELDS.patState];
  const subState = formValues[DENTAL_FIELDS.subState];
  if (!(lob !== 'mi' || patState !== 'MI' || subState !== 'MI')) return true;
  if ((lob === 'mi' || patState === 'MI' || subState === 'MI') && !alphaNumStartsEndsWithNumber.test(value as string)) {
    return false;
  }
  return true;
}
function testSubIdLength(value: ValueType, context: ContextType) {
  if (!value) return true;
  const lob = sessionStorage.getItem('lob');
  const formValues = getFormValues(context);
  const patState = formValues[DENTAL_FIELDS.patState];
  const subState = formValues[DENTAL_FIELDS.subState];
  if (lob === 'mi' || patState === 'MI' || subState === 'MI') return true;
  if ((lob !== 'mi' || patState !== 'MI' || subState !== 'MI') && value && value.length >= 2 && value.length <= 80) {
    return true;
  }
  return false;
}

function testICD002(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  console.log('stop');

  if (!value) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const dxCodeA = formValues[DENTAL_FIELDS.dxCodeA];
  const dxCodeB = formValues[DENTAL_FIELDS.dxCodeB];
  const dxCodeC = formValues[DENTAL_FIELDS.dxCodeC];
  const dxCodeD = formValues[DENTAL_FIELDS.dxCodeD];

  const tableValues = formValues['24Table'];
  console.log(tableValues);
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;

  const diagPointer = value;

  if (diagPointer) {
    if (/(A|1)/.test(diagPointer) && !dxCodeA) {
      return false;
    } else if (/(B|2)/.test(diagPointer) && !dxCodeB) {
      return false;
    } else if (/(C|3)/.test(diagPointer) && !dxCodeC) {
      return false;
    } else if (/(D|4)/.test(diagPointer) && !dxCodeD) {
      return false;
    } else {
      return true;
    }
  }

  return Boolean(value);
}

function testSubIdAlphanumericLenght(value: ValueType, context: ContextType) {
  if (!value) return true;
  const lob = sessionStorage.getItem('lob');
  const formValues = getFormValues(context);
  const patState = formValues[DENTAL_FIELDS.patState];
  const subState = formValues[DENTAL_FIELDS.subState];
  if (lob !== 'mi' && patState !== 'MI' && subState !== 'MI') return true;
  if (
    (lob === 'mi' || patState === 'MI' || subState === 'MI') &&
    testSubIdIsAlphanumeric(value, context) &&
    value &&
    value.length !== 11
  ) {
    return false;
  }
  if (value && value.length !== 11) return false;

  return true;
}

function testPhysicalAddress(value: ValueType, context: ContextType) {
  if (!value || typeof value !== 'string') return true;
  // const lob = sessionStorage.getItem('lob');
  const statesExcluded = ['in', 'ky', 'ga', 'wv'];
  const formValues = getFormValues(context);
  const patientstate = formValues[DENTAL_FIELDS.patState];
  const state = formValues[DENTAL_FIELDS.subState];

  if (
    // (lob && !states.includes(lob.toLowerCase())) ||
    (patientstate && !statesExcluded.includes(patientstate.toLowerCase())) ||
    (state && !statesExcluded.includes(state.toLowerCase()))
  ) {
    const isPOBox = checkIsPostBox(value);
    const formValues = getFormValues(context);
    const addressTwo = formValues[DENTAL_FIELDS.billingAddress2];

    if (isPOBox && !addressTwo) return false;
  }
  return true;
}

export {
  testPatientInsuredRegardingRelation,
  testCompanyNameAddress,
  testBillingAddress,
  testInsCompanyAddress,
  testSubLastName,
  testSubscriberFirstName,
  testSubscriberLastName,
  testSubFirstName,
  testTreatFirstName,
  testTreatLastName,
  testreatNpi,
  testBillingFirstName,
  testBillingLastName,
  testHasBillingFirstName,
  testHasBillingLastName,
  testNpi49ForFirstName,
  testNpi49ForLastName,
  testNpi49ForOrganizationName,
  testNpi54,
  testPatLastName,
  testPatFirstName,
  testSubNameAddress,
  testPatState,
  testPatRel,
  testTreatAddress,
  testFutureDate,
  testTypeOfTransaction,
  testPatAddress,
  testPatientsRelationship2,
  testPatientsRelationship22isOtherThenSelf,
  testRelationRegardingPatientInsured,
  testPatientsRelationshipIsSelf,
  testOtherCoverage1,
  testOtherCoverage2,
  testOtherCoverage3,
  testBillingOrTreatingNpi,
  testBillingAndTreatNpi,
  ValidationsDependenciesMap,
  testAccidentStateWhenAutoAccident,
  testCurrentDate14ToAccidentAndQualifier,
  testQualifierToCurrentDate,
  testOtherDateToQualifier,
  testOriginalRefNumToRessubmission,
  testOtherQualifierToOtherDate,
  testSubSignatureInd,
  testTreatSign,
  testAnyAccident,
  testPatGuardSignature,
  testReplacePros,
  testOrthoTreatment,
  testPatientDateOfBirth,
  testLabCharges,
  testTableUnits,
  testProviderNameToSupplier,
  testProviderNameToPhysician,
  testAmountPaidToTotal,
  testTotalChargeToTableFields,
  testIsBeforeBirthDate,
  testIsToAfterFrom,
  ValidationTableDependenciesMap,
  testModifiersToCPT1,
  testModifiersToCPT2,
  testModifiersToCPT3,
  testModifiersToCPT4,
  testIsBillingIdAndIdBlank,
  testNPIforLuhnAlghoritm,
  testSubIdIsNumeric,
  testSubIdIsAlphanumeric,
  testSubIdLength,
  testICD002,
  testSubIdAlphanumericLenght,
  testRequiredByOtherCoverage,
  testPhysicalAddress
};
