import * as React from 'react';
import { Avatar, Button, Header, Popover } from '@datum/react-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from 'heroicons/outline/user.svg';
import { ReactComponent as LogoutIcon } from 'heroicons/outline/logout.svg';

import { ReactComponent as CareSourceLogo } from 'assets/CareSourceLogo.svg';
import { ReactComponent as CareSourceOnlyHeartLogo } from 'assets/caresourceonlyheart.svg';
import CareSourceLogoHeart from 'assets/heart-hap-caresource.png';
import { ReactComponent as HapCareSourceLogo } from 'assets/hap-caresource.svg';
import { ReactComponent as TrueCareLogo } from 'assets/truecare.svg';

import { useOidc, useOidcAccessToken, useOidcUser } from '@axa-fr/react-oidc';
import { CONFIG, AUTH_PROVIDER_TYPES, LOGOUT_URL, THANK_YOU_PATH } from 'config/constants';

import { Affix, List } from 'antd';
import { useAccessTokenRoleInfo } from 'utils/hooks/useAccessTokenRoleInfo';
import { useLocation } from 'react-router-dom';
import { useBrandingContext } from 'components/common/BrandingProvider';
import { getImage } from 'api/branding';

interface AppHeaderProps {
  themeClass: string;
}

function AppHeader(props: AppHeaderProps): JSX.Element {
  const { themeClass } = props;
  const { t } = useTranslation();
  const { oidcUser } = useOidcUser();
  const { logout } = useOidc();
  const { userRoles } = useAccessTokenRoleInfo();
  const currentLocation = useLocation();
  const { accessToken } = useOidcAccessToken();
  const isThankYouPage = currentLocation?.pathname === THANK_YOU_PATH;
  const { branding, setBranding } = useBrandingContext();
  const [logoImgUrl, setLogoImgUrl] = React.useState<string>(CareSourceLogoHeart);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (branding && branding.logoStorageId) {
      getImageFunction();
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [branding]);

  const handleLogout = React.useCallback(async () => {
    try {
      //PARAMS ONLY FOR AUTH0
      if (CONFIG.AUTH_PROVIDER_TYPE === AUTH_PROVIDER_TYPES.AUTH0)
        await logout('', {
          client_id: window._env_.AUTH_CLIENT_ID,
          returnTo: window.location.origin
        });
      else await logout(LOGOUT_URL);
    } catch (error) {
      console.error(error);
    }
  }, [logout]);

  const getImageFunction = async () => {
    setLoading(true);
    let logoStorageId = branding?.logoStorageId;
    if (logoStorageId) {
      try {
        const data = await getImage(logoStorageId, accessToken);
        if (data) {
          setLogoImgUrl(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  if (isThankYouPage) return <></>;

  return (
    <Affix>
      <Header
        logo={
          logoImgUrl && logoImgUrl.length > 0
            ? logoImgUrl && (
                <img style={{ maxHeight: 40, objectFit: 'cover' }} src={logoImgUrl} alt="Logo" className="logo-img" />
              )
            : !loading && <CareSourceOnlyHeartLogo />
        }
        // logo={null}
        // title={t('appName')}
        title={''}
        sidebar
      >
        <div className="desktop-links">
          {/*allowedSectionsTree.map((linkItem, i) => (
          <NavLink key={i} to={linkItem.route}>
            {linkItem.label}
          </NavLink>
        ))*/}
        </div>
        {/* <AboutModal /> */}
        {/* <div className="datum-header__user"> */}
        <div>
          <Popover.Root>
            <Popover.Trigger className={`rapid-header__user-popover-button`}>
              <Avatar size="lg" icon={<UserIcon />} name={oidcUser?.name} />
            </Popover.Trigger>
            <Popover.Content className={`rapid-header__user-popover ${themeClass}`}>
              {oidcUser && (
                <React.Fragment>
                  <Avatar
                    size="lg"
                    icon={<UserIcon />}
                    name={oidcUser?.name}
                    description={oidcUser?.email}
                    expanded={true}
                  />
                </React.Fragment>
              )}
              {userRoles && (
                <List
                  size="small"
                  header={'User roles'}
                  footer={false}
                  bordered={false}
                  dataSource={userRoles}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              )}
              <Button variant="outline" leftIcon={<LogoutIcon />} onClick={handleLogout}>
                {t('logout')}
              </Button>
            </Popover.Content>
          </Popover.Root>
        </div>
      </Header>
    </Affix>
  );
}

export { AppHeader };
