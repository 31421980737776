import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'locales/en';

i18n.use(initReactI18next).init({
  resources: {
    en
  },
  ns: ['common', 'pages'],
  defaultNS: 'common', //default namespace
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
