import React from 'react';
import { Checkbox, Form } from 'antd';
import { DENTAL_FIELDS } from 'utils/dental/fields';

function MissingTeethInformation() {
  return (
    <Form.Item name={DENTAL_FIELDS.misTeethInfo}>
      <Checkbox.Group>
        <Checkbox value="1">1</Checkbox>
        <Checkbox value="2">2</Checkbox>
        <Checkbox value="3">3</Checkbox>
        <Checkbox value="4">4</Checkbox>
        <Checkbox value="5">5</Checkbox>
        <Checkbox value="6">6</Checkbox>
        <Checkbox value="7">7</Checkbox>
        <Checkbox value="8">8</Checkbox>
        <Checkbox value="9">9</Checkbox>
        <Checkbox value="10">10</Checkbox>
        <Checkbox value="11">11</Checkbox>
        <Checkbox value="12">12</Checkbox>
        <Checkbox value="13">13</Checkbox>
        <Checkbox value="14">14</Checkbox>
        <Checkbox value="15">15</Checkbox>
        <Checkbox value="16">16</Checkbox>
        <Checkbox value="32">32</Checkbox>
        <Checkbox value="31">31</Checkbox>
        <Checkbox value="30">30</Checkbox>
        <Checkbox value="29">29</Checkbox>
        <Checkbox value="28">28</Checkbox>
        <Checkbox value="27">27</Checkbox>
        <Checkbox value="26">26</Checkbox>
        <Checkbox value="25">25</Checkbox>
        <Checkbox value="24">24</Checkbox>
        <Checkbox value="23">23</Checkbox>
        <Checkbox value="22">22</Checkbox>
        <Checkbox value="21">21</Checkbox>
        <Checkbox value="20">20</Checkbox>
        <Checkbox value="19">19</Checkbox>
        <Checkbox value="18">18</Checkbox>
        <Checkbox value="17">17</Checkbox>
      </Checkbox.Group>
    </Form.Item>
  );
}

export default MissingTeethInformation;
