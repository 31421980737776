import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Divider, List, Typography, notification } from 'antd';
import defaultColorsJson from '../styles/defaultColors.json';
import { getAllBrands } from 'api/branding';
import React, { useEffect } from 'react';
import { useBrandingContext } from 'components/common/BrandingProvider';
import ColorPalette from './Branding/ColorPalette';
import { STATE_OPTIONS } from './Branding/Branding';
import { useUserData } from 'utils/hooks/useUserData';
import NotAllowedPage from 'components/app/NotAllowedPage';

function AllBrands() {
  const { accessToken } = useOidcAccessToken();
  const { allBrands, setAllBrands } = useBrandingContext();
  const { isAdmin } = useUserData();

  useEffect(() => {
    getBrandingFunction();
  }, []);

  function getState(value: string) {
    return STATE_OPTIONS.find((o) => o.value === value)?.label || '-';
  }

  const getBrandingFunction = async () => {
    if (accessToken) {
      try {
        const data = await getAllBrands(accessToken);
        if (data) {
          setAllBrands(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: <div>No brands added!</div>
        });
      } finally {
        //
      }
    }
  };
  if (!isAdmin) return <NotAllowedPage />;
  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">ALL {(allBrands as unknown as any[])?.length} BRANDS</Divider>
        </div>
        <div className="box all-brands-list">
          {allBrands &&
            (allBrands as unknown as any[])?.map((item: any, i: number) => {
              const colorObject = item?.colorPalette ? JSON.parse(item?.colorPalette) : defaultColorsJson;
              return (
                <div className="brand" key={i}>
                  <Typography.Text mark>[{i + 1}]</Typography.Text> Title: {item.title ? item.title : '-'}
                  <p style={{ marginBottom: 0 }}>
                    State: {item.state ? getState(item.state) : '-'} <br />
                    LOB: {item.lob ? item.lob : '-'}{' '}
                  </p>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    Color Palette:{' '}
                    {colorObject ? (
                      // <div className="color-palette" style={{ marginTop: '7px' }}>
                      //   <span style={{ backgroundColor: `${colorObject['primary-color']}` }}></span>
                      //   <span style={{ backgroundColor: `${colorObject['neutral-link-color']}` }}></span>
                      //   <span style={{ backgroundColor: `${colorObject['primary-link-color']}` }}></span>
                      //   <span style={{ backgroundColor: `${colorObject['selected-text-color']}` }}></span>
                      //   <span style={{ backgroundColor: `${colorObject['regular-text-color']}` }}></span>
                      // </div>
                      <ColorPalette colorObject={colorObject} />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default AllBrands;
