import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';

import { HCFA_FIELDS } from '../../../utils/hcfa/fields';
import { getYupValidator } from 'utils/hcfa/validations';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';

type Props = {
  id: string;
  required?: boolean;
};

function FormItemSex({ required }: Props) {
  const { t } = useTranslation();
  const isRequired = required ? true : false;

  return (
    <Form.Item
      label={t('common:fields.sex')}
      name={HCFA_FIELDS.patientSex}
      required={isRequired}
      rules={
        isRequired
          ? [getYupValidator(t, HCFA_FIELDS.patientSex), { required: true, message: t('common:fieldErrors.MUST001') }]
          : [getYupValidator(t, HCFA_FIELDS.patientSex)]
      }
    >
      <Radio.Group name={HCFA_FIELDS.patientSex} style={{ width: '180px' }}>
        <Radio onClick={nullifyRadioGroup} value="Male">
          {t('common:fields.male')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="Female">
          {t('common:fields.female')}
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default FormItemSex;
