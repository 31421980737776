import { InternalNamePath, NamePath, StoreValue } from 'antd/lib/form/interface';
import { FormType } from 'components/common/FormProvider';
import { getFormInstanceFromGlobalObject } from 'utils/hcfa/putFormInstanceInGlobalObject';
import { ValidationError } from 'yup';

export interface Meta {
  touched: boolean;
  validating: boolean;
  errors: string[];
  warnings: string[];
  name: InternalNamePath;
  validated: boolean;
}

export interface InternalFieldData extends Meta {
  value: StoreValue;
}

export interface FieldData extends Partial<Omit<InternalFieldData, 'name'>> {
  name: NamePath;
}

export function setFieldsDataErrorsFromYupErrors(errors: ValidationError, formType: FormType) {
  const form = getFormInstanceFromGlobalObject();
  let tableName = '24Table';
  if (formType === 'ub04') {
    tableName = '42Services';
  }
  const errorsArray = errors.inner;
  const errorsObj = errorsArray.reduce<Record<string, ValidationError>>((acc, curr) => {
    if (curr.path) acc[curr.path] = curr;
    return acc;
  }, {});
  const fieldDataToSet: FieldData[] = [];
  const allValues = form.getFieldsValue();
  const formNames = Object.keys(allValues);
  formNames.forEach((name) => {
    if (name !== tableName) {
      const data: FieldData = { name };
      if (errorsObj[name]) {
        data.errors = errorsObj[name].errors;
      } else {
        data.errors = [];
      }
      fieldDataToSet.push(data);
    }
  });
  const tableValues: any[] = allValues[tableName];
  if (tableValues && tableValues.length) {
    tableValues.forEach((row, idx) => {
      const rowKeys = Object.keys(row);
      rowKeys.forEach((key) => {
        const yupErrorsKey = `${tableName}[${idx}].${key}`;
        const data: FieldData = { name: [tableName, idx, key] };
        if (errorsObj[yupErrorsKey]) {
          data.errors = errorsObj[yupErrorsKey].errors;
        } else {
          data.errors = [];
        }
        fieldDataToSet.push(data);
      });
    });
  }
  form.setFields(fieldDataToSet);
}

export function setFieldsDataWarningsFromYupErrors(errors: ValidationError, formType: FormType) {
  const form = getFormInstanceFromGlobalObject();
  let tableName = '24Table';
  if (formType === 'ub04') {
    tableName = '42Services';
  }
  const errorsArray = errors.inner;
  const errorsObj = errorsArray.reduce<Record<string, ValidationError>>((acc, curr) => {
    if (curr.path) acc[curr.path] = curr;
    return acc;
  }, {});
  const fieldDataToSet: FieldData[] = [];
  const allValues = form.getFieldsValue();
  const formNames = Object.keys(allValues);
  formNames.forEach((name) => {
    if (name !== tableName) {
      const data: FieldData = { name };
      if (errorsObj[name]) {
        data.warnings = errorsObj[name].errors;
      } else {
        data.warnings = [];
      }
      fieldDataToSet.push(data);
    }
  });
  const tableValues: any[] = allValues[tableName];
  if (tableValues && tableValues.length) {
    tableValues.forEach((row, idx) => {
      const rowKeys = Object.keys(row);
      rowKeys.forEach((key) => {
        const yupErrorsKey = `${tableName}[${idx}].${key}`;
        const data: FieldData = { name: [tableName, idx, key] };
        if (errorsObj[yupErrorsKey]) {
          data.warnings = errorsObj[yupErrorsKey].errors;
        } else {
          data.warnings = [];
        }
        fieldDataToSet.push(data);
      });
    });
  }
  form.setFields(fieldDataToSet);
}
