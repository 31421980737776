import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';

import SelectPhoneCode from '../../common/SelectPhoneCode';
import { getYupValidator as ub04YupValidator } from 'utils/ub04/validataions';
import { getYupValidator as hcfaYupValidator } from 'utils/hcfa/validations';
import { getYupValidator as dentalYupValidator } from 'utils/dental/validations';
import SelectState from 'components/hcfa/shared/SelectState';
import { DENTAL_FIELDS } from 'utils/dental/fields';
import { toZipCode } from 'helpers/toZipCode';

type Fields = 'name' | 'address' | 'plan' | 'city' | 'state' | 'zipcode' | 'phone';

type FieldsetFullNameProps = {
  form: 'hcfa' | 'ub04' | 'dental';
  ids: Partial<Record<Fields, string>>;
  labels?: Partial<Record<Fields, string>>;
  breakPoints?: Partial<Record<Fields, number>>;
};

const validatorsMap = {
  hcfa: hcfaYupValidator,
  ub04: ub04YupValidator,
  dental: dentalYupValidator
};

function FieldsetLocation({ form, ids, labels, breakPoints }: FieldsetFullNameProps): JSX.Element {
  const { t } = useTranslation();

  const yupValidator = validatorsMap[form];

  function handle56ZipDentalKeyDown(e: any) {
    if (e.key === 'Tab') {
      e.preventDefault();
      const nextInput = document.getElementById('basic_57_treating_phone');
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  return (
    <Row gutter={0}>
      {ids.plan && (
        <Col xs={24} md={12} lg={6} xxl={breakPoints?.plan || 24}>
          <Form.Item
            className="field--first field--last"
            label={labels?.plan || t('common:fields.companyPan')}
            name={ids.plan}
            rules={[ids.plan ? yupValidator(t, ids.plan) : {}]}
          >
            <Input placeholder="My plan" />
          </Form.Item>
        </Col>
      )}

      {ids.name && (
        <Col xs={24} md={12} lg={6} xxl={breakPoints?.name || 24}>
          <Form.Item
            className="field--first field--last"
            label={labels?.name || t('common:fields.companyName')}
            name={ids.name}
            rules={[ids.name ? yupValidator(t, ids.name) : {}]}
          >
            <Input placeholder="NAME" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}

      {ids.address && (
        <Col xs={24} md={12} lg={6} xxl={breakPoints?.address || 24}>
          <Form.Item
            className="field--first field--last"
            label={
              labels?.address || t('common:fields.companyAddress') + ' (' + t('common:fields.companyAddress') + ')'
            }
            name={ids.address}
            rules={[ids.address ? yupValidator(t, ids.address) : {}]}
          >
            <Input placeholder="ADDRESS" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}
      <Col xs={24} md={12} lg={6} xxl={breakPoints?.city || 12}>
        <div className="FieldsetLocation__disabled-container">
          <Form.Item
            className="field--first"
            label={labels?.city || t('common:fields.city')}
            name={ids.city}
            rules={[ids.city ? yupValidator(t, ids.city) : {}]}
          >
            <Input placeholder="CITY" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </div>
      </Col>
      <Col xs={24} md={12} lg={6} xxl={breakPoints?.state || 12} className="field--last">
        <div className="FieldsetLocation__disabled-container">
          <Form.Item
            label={labels?.state || t('common:fields.state')}
            name={ids.state}
            rules={[ids.state ? yupValidator(t, ids.state) : {}]}
          >
            <SelectState placeholder="STATE" inputKey={ids.state as string} />
          </Form.Item>
        </div>
      </Col>
      <Col xs={24} md={12} lg={6} xxl={breakPoints?.zipcode || 12}>
        <Form.Item
          className="field--first"
          label={labels?.zipcode || t('common:fields.zipCode')}
          name={ids.zipcode}
          rules={[ids.zipcode ? yupValidator(t, ids.zipcode) : {}]}
        >
          <Input
            onKeyDown={ids.zipcode === DENTAL_FIELDS.treatZip ? (e) => handle56ZipDentalKeyDown(e) : undefined}
            onInput={toZipCode}
            placeholder="ZIP CODE"
          />
        </Form.Item>
      </Col>

      {ids.phone && (
        <Col xs={24} xxl={breakPoints?.phone || 12}>
          <Form.Item
            className="mb-0"
            label={labels?.phone || t('common:fields.telephone') + ' (' + t('common:fields.telephoneIncludeArea') + ')'}
            rules={[ids.phone ? yupValidator(t, ids.phone) : {}]}
          >
            <Row gutter={8} wrap={false}>
              <Col>
                <Form.Item name={`${ids.phone}_code`}>
                  <SelectPhoneCode />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item name={ids.phone}>
                  <Input placeholder="PHONE NUMBER" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

export default FieldsetLocation;
