import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Col, Divider, Form, Input, Modal, Row, Select, Skeleton, Spin, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { useBrandingContext } from 'components/common/BrandingProvider';
import { useAccessTokenRoleInfo } from 'utils/hooks/useAccessTokenRoleInfo';

function DocumentStausTracking() {
  const { accessToken } = useOidcAccessToken();
  const { branding, setBranding } = useBrandingContext();
  const [loading, setLoading] = useState(false);
  const { userRoles } = useAccessTokenRoleInfo();

  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">DOCUMENT STATUS TRACKING</Divider>
          <p>Welcome to your Document Staus Tracking Page</p>
        </div>
        <div className="box">...</div>
      </div>
    </div>
  );
}

export default DocumentStausTracking;
