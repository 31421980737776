import { AUTH_PROVIDER_TYPES } from '../config/constants';
import { ENV_VARIABLES } from '../typings';

function checkCustomAuthConfig(varErrors: string[], configManagement: ENV_VARIABLES) {
  if (!configManagement.AUTH_CUSTOM_ISSUER) varErrors.push('AUTH_CUSTOM_ISSUER');
  if (!configManagement.AUTH_CUSTOM_AUTH) varErrors.push('AUTH_CUSTOM_AUTH');
  if (!configManagement.AUTH_CUSTOM_REVOCATION) varErrors.push('AUTH_CUSTOM_REVOCATION');
  if (!configManagement.AUTH_CUSTOM_TOKEN) varErrors.push('AUTH_CUSTOM_TOKEN');
  if (!configManagement.AUTH_CUSTOM_END_SESSION) varErrors.push('AUTH_CUSTOM_END_SESSION');
  if (!configManagement.AUTH_CUSTOM_USER_INFO) varErrors.push('AUTH_CUSTOM_USER_INFO');
  return varErrors;
}

export function checkValidAuthConfig(configManagement: ENV_VARIABLES) {
  let _varErrors: string[] = [];
  if (!configManagement.AUTH_PROVIDER_TYPE) _varErrors.push('AUTH_PROVIDER_TYPE');
  if (!configManagement.AUTH_CLIENT_ID) _varErrors.push('AUTH_CLIENT_ID');
  if (!configManagement.AUTH_AUTHORITY) _varErrors.push('AUTH_AUTHORITY');
  switch (configManagement.AUTH_PROVIDER_TYPE) {
    case undefined:
    case AUTH_PROVIDER_TYPES.KEYCLOAK:
    case AUTH_PROVIDER_TYPES.OKTA: {
      break;
    }
    case AUTH_PROVIDER_TYPES.AUTH0: {
      if (!configManagement.AUTH_AUD) _varErrors.push('AUTH_AUD');
      break;
    }
    default: {
      _varErrors = checkCustomAuthConfig(_varErrors, configManagement);
    }
  }
  return _varErrors;
}
