import React from 'react';
import { Button } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import './PageViewerPagination.scss';
import { IconButton } from '@datum/react-components';

interface Props {
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const PageViewerPagination: React.FC<Props> = ({ totalPages, currentPage, setCurrentPage }) => {
  function onNext() {
    if (currentPage < totalPages) {
      setCurrentPage((p) => p + 1);
    }
  }
  function onPrev() {
    if (currentPage > 1) {
      setCurrentPage((p) => p - 1);
    }
  }

  return (
    <div className="PageViewerPagination__container">
      <IconButton
        variant={'outlined'}
        tooltip={'Previous page'}
        onClick={onPrev}
        disabled={currentPage <= 1}
        icon={<DoubleLeftOutlined />}
      />
      <IconButton
        variant={'outlined'}
        onClick={onNext}
        icon={<DoubleRightOutlined />}
        tooltip={'Next page'}
        disabled={currentPage === totalPages}
      />
      <p>
        Page&nbsp;{currentPage}&nbsp;of&nbsp;{totalPages}
      </p>
    </div>
  );
};

export default PageViewerPagination;
