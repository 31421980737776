//EXTERNAL LIBRARIES
import React, { useEffect, useRef } from 'react';
import { FallbackProps, ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Button, Spinner, Stack } from '@datum/react-components';

//INTERNAL IMPORTS
import { useLoading } from 'context/loading.context';
import { AppSidebar } from 'components/app/AppSidebar';
import { AppHeader } from 'components/app/AppHeader';
import { AppRoutes } from 'components/app/AppRoutes';
import { AppBreadcrumb } from 'components/app/AppBreadcrumb';
import { FormProvider } from './components/common/FormProvider';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import AppFooter from 'components/app/AppFooter';
import { Affix, ConfigProvider } from 'antd';
import { getBranding } from 'api/branding';
import { useBrandingContext } from 'components/common/BrandingProvider';
import { useLocation, useParams } from 'react-router';
import { THANK_YOU_PATH } from 'config/constants';
import { FORM_ACTIONS_CONTAINER_ID } from 'utils/hcfa/constants';
import { getResubmitStatuses } from 'api/resubmitStatuses';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <pre>{error?.message}</pre>
      <Button onClick={() => resetErrorBoundary()}>Try again</Button>
    </div>
  );
}

function App(): JSX.Element {
  const { t } = useTranslation();
  const { loading, description } = useLoading();
  const { accessToken } = useOidcAccessToken();
  const { scssVariables, setBranding, setBrandingLob } = useBrandingContext();

  let themeClass = 'theme'; // caresource-theme (default) // truecare-theme // hap-caresource-theme

  useEffect(() => {
    getBrandingFunction();
    /**Cache resubmit sttauses */
    getResubmitStatuses(accessToken);
  }, []);
  const getBrandingFunction = async () => {
    if (accessToken) {
      try {
        const data = await getBranding(accessToken);
        if (data) {
          setBranding(data);
          setBrandingLob(data.lob);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };

  const currentLocation = useLocation();

  const isThankYouPage = currentLocation?.pathname === THANK_YOU_PATH;

  const scrollableDivRef = useRef<any>(null);

  const isClaimPage =
    currentLocation.pathname.includes('/ub04/edit/') ||
    currentLocation.pathname.includes('/hcfa/edit/') ||
    currentLocation.pathname.includes('/dental/edit/') ||
    currentLocation.pathname.includes('/ub04/new') ||
    currentLocation.pathname.includes('/hcfa/new') ||
    currentLocation.pathname.includes('/dental/new');

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;

    if (isClaimPage) {
      const handleFocus = (event: any) => {
        const element = event.target;

        const focusableTags = ['INPUT', 'SELECT', 'TEXTAREA', 'BUTTON', 'A'];
        const formActionsContainer = document.getElementById(FORM_ACTIONS_CONTAINER_ID);

        const isActionsElement = formActionsContainer && formActionsContainer.contains(element);
        if (scrollableDiv?.contains(element) && focusableTags.includes(element.tagName) && !isActionsElement) {
          const elementBottom = element.getBoundingClientRect().bottom;
          const divBottom = scrollableDiv.getBoundingClientRect().bottom;

          const bottomDistance = divBottom - elementBottom;

          if (bottomDistance < 100) {
            const scrollAmount = 100 - bottomDistance + 270;
            scrollableDiv.scrollBy({
              top: scrollAmount,
              behavior: 'smooth'
            });
          }
        }
      };

      scrollableDiv?.addEventListener('focus', handleFocus, true);

      return () => {
        scrollableDiv?.removeEventListener('focus', handleFocus, true);
      };
    }
  }, [isClaimPage]);

  return (
    <ConfigProvider prefixCls={`${themeClass} ant`}>
      {scssVariables && (
        <div className={`app ${themeClass}`}>
          {scssVariables && <style>{`.rapid { ${scssVariables} }`}</style>}
          <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => document.location.reload()}>
            {loading && <Spinner active fullPage description={description} showDescription={!!description} />}
            <AppSidebar />
            <FormProvider>
              <div className="app-container">
                <AppHeader themeClass={themeClass} />
                <div className={`app__content`} ref={scrollableDivRef}>
                  <React.Fragment>
                    <main>
                      <Stack>
                        {!isThankYouPage && <AppBreadcrumb />}
                        <AppRoutes />
                      </Stack>
                    </main>
                  </React.Fragment>

                  <AppFooter />
                </div>
              </div>
            </FormProvider>
          </ErrorBoundary>
        </div>
      )}
    </ConfigProvider>
  );
}
export default App;
