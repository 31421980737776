import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import { Page } from 'components/common/Page';
import FormActions from 'components/common/FormActions';

import { PaperForm } from '../components/hcfa/PaperForm';
import { FORM_ACTIONS, SECTIONS } from 'config/constants';
import { useParams } from 'react-router-dom';
import { useFormProvider } from 'context/form.context';
import { getClaimData, getClaimDataBySteps } from 'utils/search/search';
import { FormContext, useResetFormProvider } from '../components/common/FormProvider';
import { serializeFormData } from '../utils/common';
import { getClaimdataById } from 'api/claims';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { ATTACHMENTS_STEP } from 'config/search';
import PageViewerWithAttachments from 'components/common/PageViewerWithAttachments';
import AuditTrail from 'components/common/AuditTrail';

type HcfaProps = {
  modern?: boolean;
};

function Hcfa({ modern }: HcfaProps): JSX.Element {
  const { t } = useTranslation();

  const { id, action, category } = useParams();
  const disabled = action === FORM_ACTIONS.VIEW;

  const hasClaim = action !== FORM_ACTIONS.NEW;

  const {
    form,
    handleSave,
    handleSend,
    setData,
    setClaimId,

    setAttachmentsNew,
    setFileRepresentationsList
  } = useContext(FormContext);

  const [formState] = useFormProvider();

  const { accessToken } = useOidcAccessToken();

  useResetFormProvider();

  const tabsItems = [
    {
      label: 'Form',
      key: 'form',
      children: <PaperForm />
    },
    {
      label: 'Attachments',
      key: 'Attachments',
      children: <PageViewerWithAttachments form={'hcfa'} readOnly={disabled} />
    }
  ];

  if (hasClaim) {
    tabsItems.push({
      label: 'Audit trail',
      key: 'AuditTrail',
      children: <AuditTrail claimId={id} />
    });
  }

  const pageTitle = React.useMemo(() => {
    switch (action) {
      case FORM_ACTIONS.EDIT:
        return 'Edit HCFA Claim';
      case FORM_ACTIONS.COPY:
        return 'New HCFA Claim from existing';
      default:
        return t('common:newItem', { item: 'HCFA' });
    }
  }, [action]);

  // const [count, setCount] = React.useState(1);
  // const [dataSource, setDataSource] = React.useState<any>([]);

  React.useEffect(() => {
    const handleEffect = async () => {
      if (id) setClaimId(id);

      if (action === FORM_ACTIONS.EDIT || action === FORM_ACTIONS.VIEW) {
        const claimData = await getClaimdataById(
          id!,
          SECTIONS.HCFA,
          accessToken,
          category === 'archive' ? true : false
        );
        if (claimData) {
          const _data = serializeFormData(getClaimData(claimData), 'hcfa');

          setData(_data);
          setFileRepresentationsList(claimData.fileRepresentations);
          setAttachmentsNew(claimData.attachments);
          form && form.setFieldsValue(_data);
        }
      }
      if (action === FORM_ACTIONS.COPY) {
        if (formState.filters) {
          const claimData = await getClaimdataById(
            formState.claimId!,
            SECTIONS.HCFA,
            accessToken,
            category === 'archive' ? true : false
          );
          if (claimData) {
            const _data = serializeFormData(getClaimDataBySteps(SECTIONS.HCFA, claimData, formState.filters), 'hcfa');
            setData(_data);
            if (formState?.filters?.includes(ATTACHMENTS_STEP)) {
              setAttachmentsNew(claimData.attachments);
            }

            form && form.setFieldsValue(_data);
          }
        }
      }
    };
    handleEffect();
  }, [action, formState, form, id]);

  return (
    <div>
      <Page pageHelmet={pageTitle}>
        <Page.Back url={`/${SECTIONS.HCFA}`} />
        <Page.Header title={pageTitle} description={t(`hcfa:formDescription`)}>
          <div>
            {/* {status && <Badge color={status === 'Pended' ? 'gray' : 'blue'}>{status}</Badge>} */}
            <div className="datum-toggle-group-form-view-wrapper"> </div>
          </div>
        </Page.Header>
        <Page.Content>
          <Tabs className="claim-wrapper" items={tabsItems} />

          <FormActions
            onSend={() => handleSend('hcfa')}
            onSave={() => handleSave('hcfa')}
            action={action as FORM_ACTIONS}
          />
        </Page.Content>
      </Page>
    </div>
  );
}

export default Hcfa;
