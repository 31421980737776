import { OidcSecure } from '@axa-fr/react-oidc';
import React from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

const SecureRoute: React.FC<RouteProps> = (props) => {
  return (
    <OidcSecure>
      <Route {...props}></Route>
    </OidcSecure>
  );
};

export default SecureRoute;
