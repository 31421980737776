import * as React from 'react';
import { Col, Form, Input, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { getYupValidator, getYupWarning } from 'utils/ub04/validataions';
import { InfoCircleOutlined } from '@ant-design/icons';

type Fields = 'treatmentCode' | 'documentNumber' | 'employerName';

type FieldsetInsuredInformationProps = {
  treatmentCodeId: string;
  documentNumberId: string;
  employerNameId: string;
  labels?: Partial<Record<Fields, string>>;
  docControlNumLeng?: {
    minLen: number;
    maxLen: number;
  };
  showAdditionalInfo?: boolean;
};

function FieldsetInsuredInformation(props: FieldsetInsuredInformationProps): JSX.Element {
  const { treatmentCodeId, documentNumberId, employerNameId, showAdditionalInfo } = props;

  const { t } = useTranslation();

  return (
    <div className="grid-container7_a no-border">
      <div className="item37_a">
        <Form.Item name={treatmentCodeId} rules={[getYupValidator(t, treatmentCodeId)]}>
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </div>
      <div className="item37_b">
        <Form.Item name={documentNumberId} rules={[getYupValidator(t, documentNumberId)]}>
          <Input
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
            suffix={
              <Tooltip title={t('ub04:form.controlNumberEOP')}>
                {showAdditionalInfo && <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
              </Tooltip>
            }
          />
        </Form.Item>
        {/* <Alert style={{ margin: '5px 5px 0px 5px' }} message={t('ub04:form.controlNumberEOP')} type="info" showIcon /> */}
      </div>
      <div className="item37_c">
        <Form.Item name={employerNameId} rules={[getYupValidator(t, employerNameId)]}>
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </div>
    </div>
  );
}

export default FieldsetInsuredInformation;
