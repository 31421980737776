function removeNonNumericAndDotCharacters(input: string) {
  return input.replace(/[^0-9.]/g, '');
}

function removeDotFromBeginning(input: string) {
  return input.replace(/^\./, '');
}

function removeAdditionalDots(input: string) {
  return input.replace(/(\..*?)\./g, '$1');
}

export const toPositiveFloat: React.FormEventHandler<HTMLInputElement> = (e) => {
  const target = e.target as HTMLInputElement;
  let value = target.value;
  if (value) {
    value = removeNonNumericAndDotCharacters(value);
    value = removeDotFromBeginning(value);
    value = removeAdditionalDots(value);
  }

  target.value = target.value ? value : '';
};
