import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonList } from '@datum/react-components';
import { ReactComponent as LeftArrowIcon } from 'heroicons/outline/arrow-left.svg';

import CustomHelmet from './CustomHelmet';

type PageProps = {
  pageHelmet: string;
  children?: React.ReactNode;
};

function Page({ pageHelmet, children }: PageProps): JSX.Element {
  return (
    <div className="app-page">
      <CustomHelmet section={pageHelmet} />
      {children}
    </div>
  );
}

type PageHeaderProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
  description?: React.ReactNode;
  isArchive?: boolean;
};

function PageHeader({ children, title, description, isArchive }: PageHeaderProps): JSX.Element {
  return (
    <>
      <div className="app-page__header--wrapper">
        <div style={{ width: '100%' }}>
          <div className="app-page__header">
            <h2 className="page__title">
              {title} {isArchive && 'ARCHIVE'}
            </h2>
            {!isArchive && children}
          </div>
          {!isArchive && description && <div className="app-page__description">{description}</div>}
        </div>
      </div>
    </>
  );
}

function PageBack({ url, formChanged }: { url: string; formChanged?: boolean }): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function onBack() {
    navigate(url);
  }
  return (
    <div className="app-page__back">
      <ButtonList position="left">
        <ButtonList.Item
          confirm={
            formChanged
              ? { title: t('confirmModal.back.title'), content: t('confirmModal.back.content'), onOk: onBack }
              : undefined
          }
          variant="outline"
          leftIcon={<LeftArrowIcon />}
          onClick={onBack}
        >
          {t('back')}
        </ButtonList.Item>
      </ButtonList>
    </div>
  );
}

type PageContentProps = {
  children?: React.ReactNode;
};

function PageContent({ children }: PageContentProps): JSX.Element {
  return <div className="app-page__content">{children}</div>;
}

Page.Header = PageHeader;
Page.Content = PageContent;
Page.Back = PageBack;

export { Page };
