import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';
import { getYupValidator } from 'utils/dental/validations';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';

type Props = {
  patientRelationship: string;
};

function PatientsRelatopnshipToPartner({ patientRelationship }: Props) {
  const { t } = useTranslation();

  return (
    <Form.Item name={patientRelationship} rules={[getYupValidator(t, patientRelationship)]}>
      <Radio.Group name={patientRelationship} className="flex-column">
        <Radio onClick={nullifyRadioGroup} value="self">
          {t('common:self')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="spouse">
          {t('common:spouse')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="dependent">
          {t('common:dependent')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="other">
          {t('common:other')}
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default PatientsRelatopnshipToPartner;
