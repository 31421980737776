import { Form } from 'antd';
import { FormContext } from 'components/common/FormProvider';
import React, { useContext, useEffect, useState } from 'react';
import { PLACE_SERVICE_FOR_AMBULANCE, PROCEDURE_CODE_FOR_AMBULANCE } from 'utils/hcfa/constants';
import { HCFA_FIELDS } from 'utils/hcfa/fields';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';

interface Props {
  setDisabledSwitch: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableTotals: React.FC<Props> = ({ setDisabledSwitch }) => {
  const tableValues = Form.useWatch('24Table');
  const [totalFee, setTotalFee] = useState<number>(0);
  const { form, setData } = useContext(FormContext);

  useEffect(() => {
    calculateTotalFees(tableValues);
    handleAmbulanceLogic();
  }, [tableValues]);

  function calculateTotalFees(tableValues: any[]) {
    if (!tableValues && !Array.isArray(tableValues)) return;
    let sum = 0;
    tableValues.forEach((row) => {
      const chargeValue = row?.[HCFA_FIELDS.chargesf24];
      if (chargeValue && !isNaN(parseFloat(chargeValue))) {
        sum += parseFloat(chargeValue);
      }
    });
    setTotalFee(sum);
    setData({ ...getFormProvidersDataFromGlobalObject(), [HCFA_FIELDS.totalCharges]: sum.toFixed(2) });
    form?.setFieldsValue({ [HCFA_FIELDS.totalCharges]: sum.toFixed(2) });
    form?.validateFields([HCFA_FIELDS.totalCharges]);
  }

  function handleAmbulanceLogic() {
    let isAmbulance = false;
    if (tableValues) {
      tableValues.forEach((element: any) => {
        if (
          element &&
          '24b_place_of_service' in element &&
          PLACE_SERVICE_FOR_AMBULANCE.includes(element['24b_place_of_service']) &&
          '24d_procedure' in element &&
          PROCEDURE_CODE_FOR_AMBULANCE.includes(element['24d_procedure'])
        ) {
          isAmbulance = true;
        }
      });
    }
    if (isAmbulance) {
      setData({ ...getFormProvidersDataFromGlobalObject(), [HCFA_FIELDS.isAmbulance]: true });
      form?.setFieldValue(HCFA_FIELDS.isAmbulance, true);

      setDisabledSwitch(true);
    } else {
      // setData({ ...getFormProvidersDataFromGlobalObject(), [HCFA_FIELDS.isAmbulance]: true });
      // form?.setFieldValue(HCFA_FIELDS.isAmbulance, false);
      setDisabledSwitch(false);
    }
  }

  return (
    <>
      <br />
      <div style={{ alignItems: 'center', marginLeft: 'auto' }}>
        TOTAL CHARGES:{' '}
        <b>
          <span style={{ marginLeft: 'auto' }}>${totalFee.toFixed(2)}</span>
        </b>
      </div>
    </>
  );
};

export default TableTotals;
