import { useAccessTokenProvidedInfo } from './useAccessTokenProvidedInfo';
import { useUserData } from './useUserData';

export const useCanHandleDental = () => {
  const { isAdmin, isProvidersAdmin, isProviderAdmin } = useUserData();
  const { LobOidc } = useAccessTokenProvidedInfo();
  if (isAdmin || isProvidersAdmin || isProviderAdmin) return true;
  if (!LobOidc) return false;
  const lcLobOidc = LobOidc.toLowerCase();
  return lcLobOidc === 'in' || lcLobOidc === 'oh' || lcLobOidc === 'ga';
};
