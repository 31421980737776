import React, { useContext } from 'react';
import { DatePicker, Form } from 'antd';

import { DATE_FORMAT } from '../../../utils/hcfaConstants';
import { useTranslation } from 'react-i18next';
import { getYupValidator, getYupWarning } from 'utils/hcfa/validations';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import { FormContext } from 'components/common/FormProvider';

type Props = {
  label?: string;
};

function FormItemPatientBirthday({ label }: Props) {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  return (
    <Form.Item
      name="3_pat_dob"
      label={label || t('common:fields.patient.birthDate')}
      rules={[getYupValidator(t, '3_pat_dob'), getYupWarning(t, '3_pat_dob')]}
    >
      <DatePickerAutoPopulate
        suffixIcon={null}
        panelRender={() => false}
        format={DATE_FORMAT}
        placeholder={t('common:fields.dateFormat')}
        style={{ width: '100%' }}
        name="3_pat_dob"
        form={form!}
      />
    </Form.Item>
  );
}

export default FormItemPatientBirthday;
