import { Form } from 'antd';
import { FormContext } from 'components/common/FormProvider';
import React, { useContext, useEffect, useState } from 'react';
import { DENTAL_FIELDS } from 'utils/dental/fields';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';

const TableTotals: React.FC = () => {
  const { form, setData } = useContext(FormContext);
  const tableValues = Form.useWatch('24Table', form);
  const otherFee1 = Form.useWatch(DENTAL_FIELDS.otherFee1, form);
  const otherFee2 = Form.useWatch(DENTAL_FIELDS.otherFee2, form);
  const [totalFee, setTotalFee] = useState<number>(0);

  useEffect(() => {
    calculateTotalFees(tableValues);
  }, [tableValues, otherFee1, otherFee2]);

  function calculateTotalFees(tableValues: any[]) {
    let sum = 0;
    (tableValues || []).forEach((row) => {
      const feeValue = row?.[DENTAL_FIELDS.fee];
      if (feeValue && !isNaN(parseFloat(feeValue))) {
        sum += parseFloat(feeValue);
      }
    });
    // if (otherFee1 && !isNaN(parseFloat(otherFee1))) {
    //   sum += parseFloat(otherFee1);
    // }
    // if (otherFee2 && !isNaN(parseFloat(otherFee1))) {
    //   sum += parseFloat(otherFee2);
    // }
    setTotalFee(sum);
    setData({ ...getFormProvidersDataFromGlobalObject(), [DENTAL_FIELDS.totalFee]: sum.toFixed(2) });
    form?.setFieldsValue({ [DENTAL_FIELDS.totalFee]: sum.toFixed(2) });
  }

  return (
    <div style={{ alignItems: 'center', marginLeft: '8px', marginTop: 10 }}>
      <b>
        32. TOTAL FEE: <span style={{ marginLeft: 'auto' }}>${totalFee.toFixed(2)}</span>
      </b>
    </div>
  );
};

export default TableTotals;
