import React, { useState, useContext } from 'react';
import { Col, Form, Input, Row, Select, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { RELATIONSHIP_CODES } from '../../../utils/ub04';
import { getYupValidator } from 'utils/ub04/validataions';
import { FormContext } from 'components/common/FormProvider';
import { onSearchSelect } from 'utils/search/search';

type Fields = 'patientsRelationship' | 'uniqueIdentifier' | 'groupName' | 'groupNumber';

type FieldsetInsuredInformationProps = {
  patientsRelationshipId: string;
  uniqueIdentifierId: string;
  groupNameId: string;
  groupNumberId: string;
  labels?: Partial<Record<Fields, string>>;
  isRequired?: Partial<Record<Fields, boolean>>;
};

function FieldsetInsuredInformation(props: FieldsetInsuredInformationProps): JSX.Element {
  const { patientsRelationshipId, uniqueIdentifierId, groupNameId, groupNumberId } = props;
  const { form } = useContext(FormContext);
  const { t } = useTranslation();

  return (
    <>
      <section>
        <Form.Item name={patientsRelationshipId} label={<span></span>}>
          <Select
            // onChange={handleChange}
            showSearch
            allowClear
            options={RELATIONSHIP_CODES.map((code) => ({ label: code, value: code }))}
            onSearch={(input) => {
              onSearchSelect(form, patientsRelationshipId, input, RELATIONSHIP_CODES);
            }}
          />
        </Form.Item>
      </section>
      <section>
        <Form.Item name={uniqueIdentifierId} rules={[getYupValidator(t, uniqueIdentifierId)]} label={<span></span>}>
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </section>
      <section>
        <Form.Item name={groupNameId} rules={[getYupValidator(t, groupNameId)]} label={<span></span>}>
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </section>
      <section>
        <Form.Item name={groupNumberId} rules={[getYupValidator(t, groupNumberId)]} label={<span></span>}>
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </section>
    </>
  );
}

export default FieldsetInsuredInformation;
