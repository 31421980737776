import axios from 'axios';
import { ClaimDistributionDTO } from 'models/DTO/ClaimDistributionDTO';
import { ProcessedByDayDTO } from 'models/DTO/ProcessedByDayDTO';
import { ProcessedByProviderDTO } from 'models/DTO/ProcessedByProviderDTO';
import { SubmittedByUserDTO } from 'models/DTO/SubmittedByUserDTO';
import { join } from 'path';

export const getDashboardCount = async (claimType: string, token?: string, isArchive?: boolean) => {
  const res = await axios.get<any>(
    window._env_.API_URL + `/api/v1/claims/${claimType}/count?archived=${isArchive ? 'true' : 'false'}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.data;
};

export const getReportsProcessedByDay = async (token?: string) => {
  const res = await axios.get<ProcessedByDayDTO[]>(window._env_.API_URL + `/api/v1/reports/processed-by-day`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getReportsMostSubmittedByUser = async (token?: string) => {
  const res = await axios.get<SubmittedByUserDTO[]>(window._env_.API_URL + `/api/v1/reports/most-submitted-by-user`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getReportsMostProcessedByProvider = async (token?: string) => {
  const res = await axios.get<ProcessedByProviderDTO[]>(
    window._env_.API_URL + `/api/v1/reports/most-processed-by-provider`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.data;
};

// export const getClaimPercentageDistribution = async (token?: string) => {
//   const res = await axios.get<ClaimDistributionDTO>(window._env_.API_URL + `/api/v1/reports/submission-count`, {
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   });
//   return res.data;
// };

export const getFilterOptions = async (token?: string) => {
  const res = await axios.get<any>(window._env_.API_URL + `/api/v1/reports/document-status-filter-options`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getDocumentStatusReports = async ({
  token,
  dateFrom,
  dateTo,
  portalDateFrom,
  portalDateTo,
  lob,
  claimType,
  state,
  incomingMode
}: {
  token?: string;
  dateFrom?: string;
  dateTo?: string;
  portalDateFrom?: string;
  portalDateTo?: string;
  lob?: string[];
  claimType?: string[];
  state?: string[];
  incomingMode?: string[];
}) => {
  console.log('params ', lob, claimType, state, incomingMode);
  const queryParams = [
    dateFrom ? `dateFrom=${dateFrom}` : '',
    dateTo ? `dateTo=${dateTo}` : '',
    portalDateFrom ? `portalDateFrom=${portalDateFrom}` : '',
    portalDateTo ? `portalDateTo=${portalDateTo}` : '',
    lob && lob.length > 0 ? `lob=${lob.join(',')}` : '',
    claimType && claimType.length > 0 ? `claimType=${claimType.join(',')}` : '',
    state && state.length > 0 ? `state=${state.join(',')}` : '',
    incomingMode && incomingMode.length > 0 ? `incomingMode=${incomingMode.join(',')}` : ''
  ];

  const existingParams = queryParams.filter(Boolean);
  const queryString = existingParams ? existingParams.join('&') : '';

  const res = await axios.get<any>(
    window._env_.API_URL + `/api/v1/reports/document-status${queryString ? '?' + queryString : ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.data;
};
