export const DENTAL_FIELDS = {
  typeOfTransaction: '1_type_of_transaction',
  predeterminationNo: '2_predeterm_no',
  predeterminationpreauthorization_number: 'predeterminationpreauthorization_number',

  companyName: '3_company_name',
  companyPlan: '3_company_plan',
  companyNameAddress: '3_company_name_address',

  companyAdress1: '3_company_addr_1',
  companyAdress2: '3_company_addr_2',
  servicesTable: '24Table',

  companyCity: '3_company_city',
  companyState: '3_company_state',
  companyZipcode: '3_company_zip',
  // companyZipcodeExt: '3_company_zip_ext',

  otherCoverage: '4_other_coverage',

  subscriberName: '5_oc_subscriber_name',
  subscriberLastName: '5_oc_sub_last_name',
  subscriberFirstName: '5_oc_sub_first_name',
  subscriberMiddle: '5_oc_sub_mi',
  subscriberSuffix: '5_oc_sub_suffix',

  subscriberDateOfBirth: '6_oc_sub_dob',
  subscriberGender: '7_oc_sub_sex',
  subscriberNumber: '8_oc_sub_ssn',
  planGroupNumber: '9_oc_plan_grp_no',
  patientsRelationship: '10_oc_pat_rel',

  insCompanyPlan: '11_oc_ins_company_name',
  dentalBenefitPlanName: '11_oc_ins_company_name',
  insCompanyName: '11_oc_ins_company_name',
  insCompanyAddress: '11_oc_ins_company_name_address',
  insCompanyAddress1: '11_oc_ins_company_addr_1',
  insCompanyAddress2: '11_oc_ins_company_addr_2',
  insCompanyCity: '11_oc_ins_company_city',
  insCompanyState: '11_oc_ins_company_state',
  insCompanyZipcode: '11_oc_ins_company_zip',
  // insCompanyZipcodeExt: '11_oc_ins_company_zip_ext',

  subLastName: '12_subscriber_last_name',
  subFirstName: '12_subscriber_first_name',
  subMiddle: '12_subscriber_mi',
  subInitial: '12_subscriber_initial',
  subSuffix: '12_subscriber_suffix',
  subNameAddress: '12_sub_name_address',
  subAddress1: '12_subscriber_addr_1',
  subAddress2: '12_sub_addr_2',
  subCity: '12_sub_city',
  subState: '12_subscriber_state',
  subZip: '12_sub_zip',
  // subZipExt: '12_sub_zip_ext',

  subDateOfBirth: '13_sub_dob',
  subGender: '14_sub_sex',
  subId: '15_subscriber_id',
  subGroupNumber: '16_sub_grp_no',
  subEmployerName: '17_sub_employer',

  reservedForFuture: '19_reserved_for_future_use',
  patientsRelationship2: '19_pat_rel',

  patLastName: '20_pat_last_name',
  patFirstName: '20_pat_first_name',
  patMiddle: '20_pat_mi',
  patSuffix: '20_pat_suffix',

  patAddress: '20_pat_name_address',
  patAddress1: '20_pat_addr_1',
  patAddress2: '20_pat_addr_2',
  patCity: '20_pat_city',
  patState: '20_pat_state',
  patZip: '20_pat_zip',
  // patZipExt: '20_pat_zip_ext',

  patDateOfBirth: '21_pat_dob',
  patGender: '22_pat_sex',
  patIdAccNo: '23_pat_id_acct_no',

  serialNumber: 'serialNumber',
  procedureDate: '24_procedure_date',
  cavityArea: '25_cavity_area',
  cavityArea1: '25_cavity_area_1',
  cavityArea2: '25_cavity_area_2',
  cavityArea3: '25_cavity_area_3',
  cavityArea4: '25_cavity_area_4',
  toothSystem: '26_tooth_system',
  toothNumOrLetters: '27_tooth_numbers_letters',
  toothSurface: '28_tooth_surface',
  toothSurface1: '28_tooth_surface_1',
  toothSurface2: '28_tooth_surface_2',
  toothSurface3: '28_tooth_surface_3',
  toothSurface4: '28_tooth_surface_4',
  procedureCode: '29_procedure_code',
  procedure: '29_procedure',
  modifier1: '29_modifier_1',
  modifier2: '29_modifier_2',
  modifier3: '29_modifier_3',
  modifier4: '29_modifier_4',
  diagPointer: '29_diag_pointer',
  dxPointerA: '29_dx_pt_a',
  dxPointerB: '29_dx_pt_b',
  dxPointerC: '29_dx_pt_c',
  dxPointerD: '29_dx_pt_d',
  qty: '29b_qty',
  desc: '30_description',
  fee: '31_fee',
  otherFee1: '31_other_fee_1',
  otherFee2: '31_other_fee_2',
  totalFee: '32_total_fee',

  misTeethInfo: '33_mis_teeth_info',
  diagCodeList: '34_diag_code_list_qual',
  dxCodeA: '34_dx_code_a',
  dxCodeB: '34_dx_code_b',
  dxCodeC: '34_dx_code_c',
  dxCodeD: '34_dx_code_d',
  remarks: '35_remarks',
  firstVisitDate: '35_first_visit_date',

  frequencyCode: 'frequencyCode',

  patGuardSignature: '36_pat_guard_signature_ind',
  patGuardSignatureDate: '36_pat_guard_sign_date',
  subSignatureInd: '37_sub_signature_ind',
  subSignatureDate: '37_sub_sign_date',

  placeOfTreat: '38_place_of_treatment',
  enclosures: '39_enclosures',
  orthoTreatment: '40_ortho_treatment',
  appPlaceDate: '41_appliance_placed_date',
  monthOfTreat: '42_months_of_treatment',
  replacePros: '43_replace_prosthesis',
  priorPlacement: '44_prior_placement',
  tretResultingForm: '45_trtmt_resulting_from',
  accDate: '46_accident_date',
  accState: '47_accident_state',

  billingName: '48_billing_name',

  billingLastName: 'billing_last_name',
  billingFirstName: 'billing_first_name',
  billingMiddleName: 'billing_mi',
  billingSuffix: 'billing_suffix',

  billingTitle: 'billing_title',
  billingAddress2: 'billing_addr_2',

  organizationName: '48_organization_name',
  billingNameAddress: '48_billing_name_address',
  billingAddress1: '48_billing_addr_1',
  billingCity: '48_billing_city',
  billingState: '48_billing_state',
  billingZip: '48_billing_zip_code',
  // billingZipExt: '48_billing_zip_code_ext',
  billingPhoneCode: '52_billing_phone_code',
  billingPhone: '52_billing_phone',
  treatingPhoneCode: '57_treating_phone_code',
  treatingPhone: '57_treating_phone',

  billingNpi: '49_billing_npi',
  billingLic: '50_billing_license',
  billingTin: '51_billing_tin',
  billingSSNorTIN: '51_billing_tin',
  billingAddId: '58_billing_additional_id',

  treatSign: '53_treating_signature',
  treatSignDate: '53_treating_sign_date',
  treatNpi: '54_treating_npi',
  treatLic: '55_treating_license_number',

  treatLastName: 'treating_last_name',
  treatFirstName: 'treating_first_name',
  treatMiddleName: 'treating_mi',
  treatSuffix: 'treating_suffix',

  treatCity: '56_treating_city',
  treatAddress: '56_treating_address',

  treatTitle: 'treating_title',
  treatAddress2: 'treating_addr_2',

  treatZip: '56_treating_zip',
  // treatZipExt: '56_treating_zip_ext',
  treatState: '56_treating_state',
  treatAddress1: '56_treating_addr_1',
  // treatPhoneCode: '57_treating_phone_code',
  // treatPhone: '57_treating_phone_number',

  treatSignInd: '53_treating_signature_ind',

  providerId: '58_billing_additional_id',
  additionalProviderIdDental: '52_treating_additional_id',

  provSpecCode: '56_prov_speciality_code',

  patRelInfo: '18_pat_rel_info',
  patGuardSign: '36_pat_guard_signature',
  subSignature: '37_sub_signature',
  genderCheckboxDetection: 'gender_checkbox_detection',
  indicatorForDentalVersion: 'indicator_for_dental_version',

  policyholderSubscriberID: '8_policyholderSubscriberId',
  patientId: '18_patient_id_account',
  patientsRelationshipToPerson: '11_patients_relationship_to_person',
  missingTeethInfo: '21_Missing_teeth_Info',
  diagnosisCodeListQualifier: '22_diagnosis_code_list',
  diagnosisCodeListQualifier2: '22_2_diagnosis_code_list',
  diagnosisCodeListQualifier3: '22_3_diagnosis_code_list',
  diagnosisCodesprimaryA: '23?diagnosis?codes',

  patientLastName: '2_pat_last_name',
  patientFirstName: '2_pat_first_name',
  patientMiddleInitial: '2_pat_mi',
  patientGen: '2_pat_gen',
  patientSex: '3_pat_sex',
  patientAddress: '5_pat_addr_1',
  patientCity: '5_pat_city',
  patientState: '5_pat_state',
  patientZip: '5_pat_zip',
  patientPhone: '_5e__PATIENTS_TELEPHONE',
  //patientPhoneCode: '_5e__PATIENTS_TELEPHONE_code',
  patientSignature: '_12__SIGNATURE',
  patientSignedDate: '_12a__SIGNED_DATE',

  insuredId: '1a_insured_id',
  insuredLastName: '4_ins_last_name',
  insuredFirstName: '4_ins_first_name',
  insuredMiddleInitial: '4_ins_mi',
  insuredGen: '4_ins_gen',
  insuredSex: 'single_11aa__INSUREDS_SEX',
  insuredAddress: '7_ins_addr_1',
  insuredCity: '7_ins_city',
  insuredState: '7_ins_state',
  insuredZip: '7_ins_zip',
  insuredPhone: '_7e__INSUREDS_TELEPHONE',
  insuredPhoneCode: '_7e__INSUREDS_TELEPHONE_code',
  insuredDateOfBirth: '_11a__INSUREDS_DATE_OF_BIRTH',
  insuredPolicyGroup: '11_ins_grp_no',
  insuredSignature: '13_insured_signature',

  insurancePlanProgramName: '_11c__INSURANCE_PLAN_NAME_OR_PROGRAM_NAME',

  otherInsuredId: '9a_oc_ins_id',
  otherInsuredLastName: '9_oc_insured_last_name',
  otherInsuredFirstName: '9_oc_insured_first_name',
  otherInsuredMiddleInitial: '9_oc_insured_middle_name',
  otherInsuredGen: '9_oc_insured_generation',
  otherInsuredPlanName: '9d_oc_plan_name',

  employmentStatus: '10a_job_related',

  autoAccident: '10b_auto_accident',
  otherAccident: '10c_other_accident',

  claimCode1: '10d_code_1',
  claimCode2: '10d_code_2',
  claimCode3: '10d_code_3',
  claimCode4: '10d_code_4',

  otherClaimQuality: '11b_other_clm_qual',
  otherClaimId: '_11b__OTHER_CLAIM_ID',
  //otherCoverage: '11d_other_coverage',
  otherDate: '_15__OTHER_DATE',
  otherQuality: '_15__OTHER_QUAL',

  currentIllness: '14_current_illness',
  quality: '_14b__QUAL',
  unableToWorkFromDate: '_16a__UNABLE_TO_WORK_FROM',
  unableToWorkToDate: '_16b__UNABLE_TO_WORK_TO',

  referringProviderLastName: '17_ref_last_name',
  referringProviderFirstName: '17_ref_first_name',
  referringProviderMiddleInitial: '17_ref_mi',
  referringProviderGen: '17_ref_gen',
  referringTitle: '17_ref_title',
  referringProviderQuality: '17_refer_prov_qual',
  referringProviderId: '17a_refer_prov_id',
  referringProviderIdQuality: '17a_ref_prov_id_qual',

  referringNpi: '17b_referring_npi',

  hospitalizationFromDate: '_18a__HOSPITALIZATION_FROM',
  hospitalizationToDate: '_18b__HOSPITALIZATION_TO',

  outsideLab: '20_outside_lab',
  labCharges: '20_lab_charges',
  icdInd: '21_icd_ind',

  dxCodeE: '21e_dx',
  dxCodeF: '21f_dx',
  dxCodeG: '21g_dx',
  dxCodeH: '21h_dx',
  dxCodeI: '21i_dx',
  dxCodeJ: '21j_dx',
  dxCodeK: '21k_dx',
  dxCodeL: '21l_dx',

  resubmissionCode: '22_resub_code',
  originalRefNumber: '22_original_ref_no',
  authorizationNumber: '23_authorization',
  federalTaxId: '25_billing_tin',
  federalTaxIdType: '25_tin_type',

  patientAccountNumber: '26_pat_id_acct_no',
  assignment: '27_assignment',
  totalCharges: '28_total_charges',
  amountPaid: '29_amt_paid',

  signatureOfPhysician: '_31a__SIGNATURE_OF_PHYSICIAN_SIGNED',
  signatureOfPhysicianDate: '_31b__SIGNATURE_OF_PHYSICIAN_DATE',

  facilityName: '32_facility_name',
  facilityAddress: '32_facility_addr_1',
  facilityCity: '32_facility_city',
  facilityState: '32_facility_state',
  facilityZip: '32_facility_zip',
  facilityNpi: '32a_facility_npi',
  facilityId: '32b_facility_id',
  facilityIdQuality: '32b_facility_id_qual',

  transactionTypeServices: '1_stmt_actual_services',
  transactionTypeRequestForPreauth: '1_request_for_preauth',
  transactionTypeEpsdtTitle: '1_epsdt_title_xix'
};

export const PHONE_CODES_MAP = {
  //_5e__PATIENTS_TELEPHONE_code: '_5e__PATIENTS_TELEPHONE',
  _7e__INSUREDS_TELEPHONE_code: '_7e__INSUREDS_TELEPHONE',
  '33_billing_phone_code': '33_billing_phone'
};

export const DIAGNOSIS_CODES_MAP = {
  a: DENTAL_FIELDS.dxCodeA,
  b: DENTAL_FIELDS.dxCodeB,
  c: DENTAL_FIELDS.dxCodeC,
  d: DENTAL_FIELDS.dxCodeD,
  e: DENTAL_FIELDS.dxCodeE,
  f: DENTAL_FIELDS.dxCodeF,
  g: DENTAL_FIELDS.dxCodeG,
  h: DENTAL_FIELDS.dxCodeH,
  i: DENTAL_FIELDS.dxCodeI,
  j: DENTAL_FIELDS.dxCodeJ,
  k: DENTAL_FIELDS.dxCodeK,
  l: DENTAL_FIELDS.dxCodeL
};
