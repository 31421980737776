import React, { useEffect, useState } from 'react';
import { OriginTypeEnum, SearchClaim } from 'models/DTO/SearchClaim';
import { FORM_ACTIONS, SECTIONS } from 'config/constants';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';
import { Button } from '@datum/react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormProvider } from 'context/form.context';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import { resubmitClaimStraightThrough, releaseClaimLock, exportRetryClaim, deleteClaimById } from 'api/claims';
import { ReactComponent as EyeIcon } from 'heroicons/outline/eye.svg';
import { ReactComponent as PencilIcon } from 'heroicons/outline/pencil.svg';
import { ReactComponent as CopyIcon } from 'heroicons/outline/duplicate.svg';
import { Modal, notification } from 'antd';
import { CopyModal } from 'components/forms/CopyModal';
import { AxiosError } from 'axios';
import { useUserData } from 'utils/hooks/useUserData';
import { UnlockOutlined } from '@ant-design/icons';
import { AiOutlineRedo } from 'react-icons/ai';
import { getResubmitStatuses } from 'api/resubmitStatuses';

const { confirm } = Modal;

interface Props {
  row: SearchClaim;
  section: SECTIONS;
  setData: React.Dispatch<React.SetStateAction<SearchClaim[]>>;
  isArchive?: boolean;
}

const ActionsTableRow: React.FC<Props> = ({ row, section, setData, isArchive }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [copyModalIsOpen, setCopyModalIsOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [resubmitInProgress, setResubmitInProgress] = useState(false);

  const [_, formDispatch] = useFormProvider();
  const { accessToken } = useOidcAccessToken();
  /**Lock */
  const { isAdmin, username, isProvidersAdmin, isProviderAdmin, isUserRole } = useUserData();
  const isMyLock = row.lockedBy === username;
  const isLockedByUser = row.lockedBy && row.lockedBy === username;
  const canReleaseLock = isAdmin || isProvidersAdmin || (isLockedByUser && !isUserRole) || isProviderAdmin;
  const canEditClaim = !row.lockedBy || isLockedByUser;
  const canDeleteClaim = isAdmin;
  const isClaimLocked = Boolean(row.lockedBy);
  const isClaimRejected = row.status?.externalId === ClaimStatusEnum.REJECTED;
  const isClaimResubmited = Boolean(row.resubmittedId);
  const isExportError = row.status?.externalId === ClaimStatusEnum.EXPORT_MQ_ERROR;

  const currentStatus: ClaimStatusEnum = row?.status?.externalId;
  const claimSubmittedSuccessfully: boolean =
    currentStatus === ClaimStatusEnum.SUBMIT_STARTED ||
    currentStatus === ClaimStatusEnum.SUBMIT_FINISHED ||
    currentStatus === ClaimStatusEnum.EXTERNAL_EXPORT_FINISHED ||
    currentStatus === ClaimStatusEnum.REJECTED;

  const isSng837: boolean =
    currentStatus === ClaimStatusEnum.SNG_837_SUBMISSION_IN_PROGRESS ||
    currentStatus === ClaimStatusEnum.SNG_837_SUBMITTED_AND_WAITING_FOR_ACK ||
    currentStatus === ClaimStatusEnum.SNG_837_SUCCESSFULLY_SUBMITTED;

  const isExportRetryActive =
    currentStatus === ClaimStatusEnum.SUBMIT_ERROR || currentStatus === ClaimStatusEnum.EXPORT_MQ_ERROR;

  const [resubmitAllowedStatuses, setResubmitAllowedStatuses] = useState<ClaimStatusEnum[]>([]);
  const isClaimDirectUpload =
    row.originType === OriginTypeEnum.DIRECT_UPLOAD &&
    currentStatus !== ClaimStatusEnum.EXTERNAL_EXPORT_FINISHED &&
    currentStatus !== ClaimStatusEnum.REJECTED;
  useEffect(() => {
    async function fetchResubmitStatues() {
      const statuses = await getResubmitStatuses(accessToken);
      setResubmitAllowedStatuses(statuses);
    }
    fetchResubmitStatues();
  }, []);
  const handleResubmit = async () => {
    setResubmitInProgress(true);
    try {
      const claimData = await resubmitClaimStraightThrough(row.docId, section, accessToken);

      formDispatch({ type: 'SET_CLAIM_ID', claimId: claimData.docId });
      navigate(`/${section}/${FORM_ACTIONS.EDIT}/${claimData.docId}`);
    } catch (error) {
      if (error instanceof AxiosError)
        notification.error({
          message: 'Server error occured: ',
          /**@ts-ignore */
          description: error.response?.data?.message
        });
      console.error('An error occurred:', error);
    } finally {
      setResubmitInProgress(false);
    }
  };

  const handleReleaseLock = async () => {
    Modal.confirm({
      title: 'Warning',
      content: `This action will remove ${isMyLock ? 'YOUR' : "SOMEONE ELSE'S"} lock`,
      onOk: async () => {
        try {
          await releaseClaimLock(row.docId, section, accessToken);
          notification.success({
            message: 'Success',
            description: 'Claim unlocked'
          });
          setData((claims) => {
            return claims.map((c) => (c.docId === row.docId ? { ...c, lockedBy: null } : c));
          });
        } catch (e) {
          notification.error({
            message: 'Server error occured',
            description: 'Claim lock not released'
          });
        }
      },
      okText: 'Unlock'
    });
  };

  const handleDelete = () => {
    confirm({
      title: 'Delete?',
      content: `Are you sure you want to delete claim ${row.docId}?`,
      okButtonProps: { loading: deleteInProgress },
      onOk: async () => {
        await deleteClaim(row.docId);
      }
    });
  };

  const deleteClaim = async (claimId: string) => {
    setDeleteInProgress(true);
    try {
      await deleteClaimById(claimId, section, accessToken);
      notification.success({ message: 'Claim deleted' });
      setData((data) => data.filter((d) => d.docId !== claimId));
    } catch (e) {
      notification.error({ message: 'Claim delete failed', description: 'Server error occurred' });
    } finally {
      setDeleteInProgress(false);
    }
  };

  const exportRetryClaimAction = async (claimId: string) => {
    await exportRetryClaim(claimId, section, accessToken, false);
  };

  const handleRetry = () => {
    confirm({
      title: 'Retry claim?',
      content: `Are you sure you want to retry claim ${row.docId}?`,
      okButtonProps: { loading: deleteInProgress },
      onOk: async () => {
        await exportRetryClaimAction(row.docId);
        setData((data) =>
          data.map((d) => {
            if (d.docId === row.docId) {
              return { ...d, status: { ...d.status, externalId: ClaimStatusEnum.SUBMIT_FINISHED } };
            }
            return { ...d };
          })
        );
      }
    });
  };

  const closeCopyModal = () => {
    setCopyModalIsOpen(false);
  };

  const canResubmit = (status: ClaimStatusEnum) => {
    return resubmitAllowedStatuses.includes(status);
  };

  return !isArchive ? (
    <div className="cell-actions">
      <div className={'label-top-btn'}>
        <Button
          variant={'outline'}
          disabled={
            claimSubmittedSuccessfully ||
            isSng837 ||
            !canEditClaim ||
            isClaimRejected ||
            isClaimDirectUpload ||
            isExportError ||
            isClaimResubmited
          }
          type="button"
          onClick={() => {
            formDispatch({ type: 'SET_CLAIM_ID', claimId: row.docId });
            navigate(`/${section}/${FORM_ACTIONS.EDIT}/${row.docId}`);
          }}
        >
          <PencilIcon /> {t('Edit')}
        </Button>
      </div>
      {!isClaimDirectUpload && (
        <div className={'label-top-btn'}>
          <Button
            variant={'outline'}
            disabled={isClaimDirectUpload}
            type="button"
            onClick={() => {
              formDispatch({ type: 'SET_CLAIM_ID', claimId: row.docId });
              navigate(`/${section}/${FORM_ACTIONS.VIEW}/${row.docId}`);
            }}
          >
            <EyeIcon /> {t('View')}
          </Button>
        </div>
      )}
      <div className={'label-top-btn'}>
        <Button
          variant={'outline'}
          disabled={isClaimDirectUpload}
          type="button"
          onClick={() => {
            formDispatch({ type: 'SET_CLAIM_ID', claimId: row.docId });
            setCopyModalIsOpen(true);
          }}
        >
          <CopyIcon /> {t('Copy')}
        </Button>
      </div>
      {canResubmit(currentStatus) && (
        <div className={'label-top-btn'}>
          <Button disabled={resubmitInProgress} variant={'outline'} type="button" onClick={() => handleResubmit()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
              />
            </svg>{' '}
            {t('Resubmit')}
          </Button>
        </div>
      )}
      {canDeleteClaim && (
        <div className={'label-top-btn'}>
          <Button disabled={resubmitInProgress} variant={'outline'} type="button" onClick={() => handleDelete()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-179.000000, -360.000000)">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path
                      fill="currentColor"
                      d="M130.35,216 L132.45,216 L132.45,208 L130.35,208 L130.35,216 Z M134.55,216 L136.65,216 L136.65,208 L134.55,208 L134.55,216 Z M128.25,218 L138.75,218 L138.75,206 L128.25,206 L128.25,218 Z M130.35,204 L136.65,204 L136.65,202 L130.35,202 L130.35,204 Z M138.75,204 L138.75,200 L128.25,200 L128.25,204 L123,204 L123,206 L126.15,206 L126.15,220 L140.85,220 L140.85,206 L144,206 L144,204 L138.75,204 Z"
                      id="delete-[#1487]"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
            {t('Delete')}
          </Button>
        </div>
      )}
      {isExportRetryActive && (
        <div className={'label-top-btn'}>
          <Button disabled={resubmitInProgress} variant={'outline'} type="button" onClick={() => handleRetry()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                d="M10.0001 17H8.00098C4.68727 17 2.00098 14.3137 2.00098 11C2.00098 7.68629 4.68727 5 8.00098 5H16.0001C19.3138 5 22.0001 7.68629 22.0001 11C22.0001 14.3137 19.3138 17 16.0001 17H14.0001M17.0001 20L14.0001 17M14.0001 17L17.0001 14"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {t('ExportRetry')}
          </Button>
        </div>
      )}
      {isExportError && (
        <div className={'label-top-btn'}>
          <Button variant={'outline'} disabled={!isMyLock} type="button" onClick={handleRetry}>
            <AiOutlineRedo /> {t('Export Retry')}
          </Button>
        </div>
      )}
      {copyModalIsOpen && (
        <CopyModal isOpen={copyModalIsOpen} onClose={closeCopyModal} formModel={section} claimId={row.docId} />
      )}
      {canReleaseLock && isClaimLocked && (
        <div className={'label-top-btn'}>
          <Button
            variant={'outline'}
            // disabled={!row.jsonStorageId}
            type="button"
            onClick={handleReleaseLock}
          >
            <UnlockOutlined /> {t('Unlock')}
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div className="cell-actions">
      {claimSubmittedSuccessfully && (
        <div className={'label-top-btn'}>
          <Button
            variant={'outline'}
            // disabled={!row.jsonStorageId}
            type="button"
            onClick={() => {
              formDispatch({ type: 'SET_CLAIM_ID', claimId: row.docId });
              navigate(`/archive/${section}/${FORM_ACTIONS.VIEW}/${row.docId}`);
            }}
          >
            <EyeIcon /> {t('View')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActionsTableRow;
