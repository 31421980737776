import * as React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { toCurrency, toCurrencyPaste } from 'helpers/toCurrency';
import { getYupValidator } from 'utils/ub04/validataions';

import { toAlphaNumUppercase } from 'helpers/toAlphaNumUppercase';

type Fields = 'code' | 'amount';

type FieldsetValueCodeProps = {
  codeId: string;
  amountId: string;
  prefix?: string;
  labels?: Partial<Record<Fields, string>>;
  isRequired?: Partial<Record<Fields, boolean>>;
};

function FieldsetValueCode(props: FieldsetValueCodeProps): JSX.Element {
  const { codeId, amountId, prefix, labels, isRequired } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={0}>
      <Col span={12}>
        <Form.Item
          className="field--first"
          label={labels?.code || 'Code'}
          name={codeId}
          rules={[getYupValidator(t, codeId)]}
        >
          <Input prefix={prefix} placeholder="AA" onInput={toAlphaNumUppercase} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={labels?.amount || 'Amount'} name={amountId} rules={[getYupValidator(t, amountId)]}>
          <Input
            allowClear
            onPaste={toCurrencyPaste}
            onInput={toCurrency}
            style={{ width: '100%' }}
            placeholder={'1.00'}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FieldsetValueCode;
