import common from './common.json';
import auth from './auth.json';
import hcfa from './hcfa.json';
import ub04 from './ub04.json';
import dental from './dental.json';
import archive from './archive.json';
import { rapidComponentsLocaleEn } from '@datum/react-components';

const en = {
  common,
  auth,
  hcfa,
  ub04,
  dental,
  archive,
  ...rapidComponentsLocaleEn
};

export default en;
