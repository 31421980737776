import * as React from 'react';

import PaperServicesList from './PaperServicesList';

type ServicesFieldsListProps = {
  readOnly: boolean;
};

function ServicesFieldsList({ readOnly }: ServicesFieldsListProps): JSX.Element {
  return <PaperServicesList readOnly={readOnly} />;
}

export default ServicesFieldsList;
