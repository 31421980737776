import { useReducer } from 'react';

export interface PaginationOptions {
  pageSize: number;
  totalCount: number;
  offset: number;
  currentPage: number;
}

interface Action {
  type: ActionType;
  payload: number;
}

export enum ActionType {
  SET_PAGE_SIZE = 'SET_PAGE_SIZE',
  SET_TOTAL_COUNT = 'SET_TOTAL_COUNT',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  RESET = 'RESET'
}

const initialState: PaginationOptions = {
  currentPage: 0,
  offset: 0,
  pageSize: 10,
  totalCount: 0
};

function reducer(state: PaginationOptions, action: Action): PaginationOptions {
  switch (action.type) {
    case ActionType.SET_PAGE_SIZE:
      return { ...state, offset: state.currentPage * action.payload, pageSize: action.payload };
    case ActionType.SET_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case ActionType.SET_CURRENT_PAGE:
      return { ...state, offset: state.pageSize * action.payload, currentPage: action.payload };
    case ActionType.RESET:
      return { ...initialState };
    default:
      throw new Error('No such action');
  }
}

export const usePaginationOptions = () => {
  const stateDispatch = useReducer(reducer, initialState);
  return stateDispatch;
};
