import { FormType } from 'components/common/FormProvider';
import { DENTAL_TABLE_EXTERNAL_ID, HCFA_TABLE_EXTERNAL_ID, SECTIONS, UB04_TABLE_EXTERNAL_ID } from 'config/constants';
import { ClaimDTO } from 'models/DTO/ClaimDTO';
import { mapUIValueToDTO } from 'models/helpers/mapUIValueToDTO';
import uuid from 'react-uuid';

export function mapFormValuesToClaimData(formValues: any, formType: FormType | SECTIONS, claimJson: ClaimDTO) {
  if (formType === 'hcfa' || formType === 'dental') {
    for (const externalId in formValues) {
      if (externalId !== '24Table') {
        const fieldData = {
          externalId: externalId
        };

        const fieldEntry = {
          id: uuid(),
          externalId: fieldData.externalId,
          values: mapUIValueToDTO(formValues[externalId])
        };

        claimJson.documents[0].fields!.push(fieldEntry);
      }
      if (
        externalId === '24Table' &&
        formValues[externalId] !== undefined &&
        formValues[externalId] !== null &&
        Array.isArray(formValues[externalId])
      ) {
        formValues[externalId].forEach((entry: any) => {
          if (typeof entry === 'object' && entry !== null) {
            const newRow = {
              id: uuid(),
              fields: Object.entries(entry).map(([fieldName, fieldValue]) => ({
                id: uuid(),
                name: fieldName,
                fieldDefinitionName: fieldName,
                externalId: fieldName,
                values: mapUIValueToDTO(fieldValue as string[] | string | null)
              }))
            };
            claimJson.documents[0].tables![0].rows.push(newRow);
          }
        });
      }
      if (externalId === '24Table') {
        claimJson.documents[0].tables![0].externalId =
          formType === 'hcfa' ? HCFA_TABLE_EXTERNAL_ID : DENTAL_TABLE_EXTERNAL_ID;
      }
    }
  }
  if (formType === 'ub04') {
    for (const externalId in formValues) {
      if (externalId !== '42Services') {
        const fieldData = {
          externalId: externalId
        };

        const fieldEntry = {
          id: uuid(),
          externalId: fieldData.externalId,
          values: mapUIValueToDTO(formValues[externalId])
        };

        claimJson.documents[0].fields!.push(fieldEntry);
      }
      if (externalId === '42Services' && formValues[externalId] && Array.isArray(formValues[externalId])) {
        formValues[externalId].forEach((entry: any) => {
          if (typeof entry === 'object' && entry !== null) {
            const newRow = {
              id: uuid(),
              fields: Object.entries(entry).map(([fieldName, fieldValue]) => ({
                id: uuid(),
                name: fieldName,
                fieldDefinitionName: fieldName,
                externalId: fieldName,
                values: mapUIValueToDTO(fieldValue as string[] | string | null)
              }))
            };
            claimJson.documents[0].tables![0].rows.push(newRow);
          }
        });
      }
      if (externalId === '42Services') {
        claimJson.documents[0].tables![0].externalId = UB04_TABLE_EXTERNAL_ID;
      }
    }
  }
}
