import * as React from 'react';

function Logo(): JSX.Element {
  return (
    <svg className="logo" version="1.1" x="0" y="0" viewBox="0 0 754 100" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(368.509 76.006)">
        <path
          d="M30.88-56H6.64V0H19.6v-15.44h11.28c14.96 0 24.32-7.76 24.32-20.24C55.2-48.24 45.84-56 30.88-56Zm-.72 30H19.6v-19.44h10.56c7.92 0 11.92 3.6 11.92 9.76 0 6.08-4 9.68-11.92 9.68z"
          fill="#1452ad"
        />
        <path
          d="M92.32.96c17.6 0 30.64-12.24 30.64-28.96s-13.04-28.96-30.64-28.96c-17.68 0-30.64 12.32-30.64 28.96S74.64.96 92.32.96zm0-11.04c-10 0-17.52-7.28-17.52-17.92s7.52-17.92 17.52-17.92 17.52 7.28 17.52 17.92-7.52 17.92-17.52 17.92z"
          fill="#1452ad"
        />
        <path
          d="m182.32 0-12.56-18c7.28-3.12 11.52-9.28 11.52-17.68 0-12.56-9.36-20.32-24.32-20.32h-24.24V0h12.96v-15.6h11.92L168.4 0zm-14.16-35.68c0 6.08-4 9.76-11.92 9.76h-10.56v-19.52h10.56c7.92 0 11.92 3.6 11.92 9.76z"
          fill="#1452ad"
        />
        <path d="M202.32 0h12.96v-45.44h17.92V-56h-48.8v10.56h17.92z" fill="#1452ad" />
        <path
          d="M279.2 0h13.6l-25.04-56h-12.8L230 0h13.28l4.96-12h26zm-26.8-21.84 8.88-21.44 8.88 21.44z"
          fill="#1452ad"
        />
        <path d="M298.64 0h41.04v-10.56H311.6V-56h-12.96z" fill="#1452ad" />
      </g>
      <path d="M734.2 20.5v2.9h-6.7v13.4h-2.9V23.5H718v-2.9h16.2z" fill="#1452ad" />
      <path
        d="M753.2 20.5v16.3h-2.9V26.4l-2.9 2.2-2.4 1.8-2.4-1.8-2.9-2.2v10.4h-2.9V20.5l2.9 2.2 5.2 4 5.2-4z"
        fill="#1452ad"
      />

      <path
        d="m177.4 76.5-12.1-17.2c-1.5.1-2.7.2-3.5.2H148v17h-8v-56h21.8c7.3 0 13 1.7 17.1 5.2 4.2 3.5 6.2 8.2 6.2 14.3.1 3.9-1 7.8-3.2 11-2.3 3.1-5.4 5.5-9.1 6.6l13.3 18.8zm-4.2-27.1c2.7-2.2 4-5.3 4-9.4 0-4.1-1.3-7.2-4-9.3-2.7-2.2-6.5-3.2-11.6-3.2H148v25.2h13.6c5.1 0 8.9-1.1 11.6-3.3z"
        fill="#001835"
      />
      <path
        d="M225.6 38c3.1 2.9 4.7 7.2 4.7 12.9v25.6H223v-5.6c-1.3 2-3.2 3.6-5.5 4.5-2.7 1.1-5.6 1.7-8.5 1.6-4.7 0-8.5-1.1-11.4-3.4-2.8-2.1-4.4-5.5-4.3-9-.1-3.5 1.4-6.8 4.1-9 2.7-2.3 7.1-3.4 13-3.4h12.2v-1.6c0-3.3-1-5.9-2.9-7.6-1.9-1.7-4.7-2.6-8.5-2.6-2.5 0-5 .4-7.4 1.2-2.2.7-4.3 1.9-6.1 3.3l-3.2-5.8c2.3-1.8 5-3.2 7.8-4 3.1-.9 6.4-1.4 9.7-1.4 5.9 0 10.4 1.4 13.6 4.3zm-7.7 31.1c2.1-1.3 3.8-3.2 4.6-5.6v-5.9h-11.8c-6.5 0-9.8 2.2-9.8 6.6-.1 2 .9 3.9 2.5 5 2 1.3 4.4 2 6.9 1.8 2.7.1 5.3-.5 7.6-1.9z"
        fill="#001835"
      />
      <path
        d="M278 36.4c3.2 1.8 5.9 4.4 7.6 7.6 1.9 3.5 2.8 7.3 2.8 11.3.1 3.9-.9 7.9-2.8 11.3-1.8 3.2-4.4 5.9-7.6 7.6-3.4 1.8-7.2 2.8-11 2.7-2.9 0-5.7-.6-8.4-1.8-2.5-1.2-4.7-2.9-6.4-5.1v22h-7.7V34.1h7.4v6.7c1.7-2.3 3.9-4.1 6.5-5.3 2.7-1.2 5.7-1.8 8.6-1.8 3.8 0 7.6.9 11 2.7zm-4.4 32c2.2-1.3 3.9-3.1 5.1-5.3 1.3-2.4 1.9-5.1 1.9-7.8.1-2.7-.6-5.4-1.9-7.8-1.2-2.2-3-4-5.1-5.2-2.2-1.2-4.8-1.9-7.3-1.8-2.5 0-5 .6-7.2 1.9-2.1 1.2-3.9 3-5.1 5.2-1.3 2.4-1.9 5.1-1.9 7.8-.1 2.7.6 5.4 1.8 7.8 1.1 2.2 2.9 4 5 5.3 4.6 2.4 10.2 2.4 14.7-.1z"
        fill="#001835"
      />
      <path
        className="st3"
        d="M299.2 24.5c-1-.9-1.5-2.2-1.5-3.5 0-1.3.5-2.6 1.5-3.6s2.3-1.5 3.7-1.4c1.4 0 2.7.5 3.7 1.4 1 .9 1.5 2.2 1.5 3.5 0 1.4-.5 2.7-1.5 3.6-1 1-2.3 1.5-3.7 1.5-1.4 0-2.8-.5-3.7-1.5zm-.2 9.6h7.7v42.4H299Z"
        fill="#001835"
      />
      <path
        d="M361.4 17.2v59.4H354v-6.7c-1.7 2.3-3.9 4.2-6.5 5.4-2.7 1.2-5.7 1.9-8.6 1.8-3.8.1-7.6-.9-11-2.7-3.2-1.8-5.9-4.4-7.6-7.6-1.9-3.5-2.8-7.4-2.8-11.3-.1-3.9.8-7.9 2.7-11.3 1.8-3.2 4.4-5.8 7.6-7.6 3.4-1.8 7.2-2.8 11-2.7 2.9 0 5.8.6 8.4 1.7 2.5 1.1 4.7 2.9 6.4 5.1V17.2Zm-14.6 51.2c2.2-1.2 3.9-3.1 5.1-5.3 1.3-2.4 1.9-5.1 1.8-7.8.1-2.7-.6-5.4-1.8-7.8-1.2-2.2-2.9-4-5.1-5.2-4.6-2.5-10-2.5-14.6 0-2.2 1.2-3.9 3-5.1 5.2-1.3 2.4-1.9 5.1-1.9 7.8-.1 2.7.6 5.4 1.9 7.8 1.2 2.2 3 4 5.1 5.3 4.5 2.5 10.1 2.5 14.6 0z"
        fill="#001835"
      />

      <path
        d="M83.3 100H16.7C7.5 100 0 92.5 0 83.3V16.7C0 7.5 7.5 0 16.7 0h66.7C92.5 0 100 7.5 100 16.7v66.7c0 9.1-7.5 16.6-16.7 16.6z"
        fill="#1452ad"
      />
      <g transform="translate(5.316 -189.48)">
        <path
          d="M25.01 204.168a7.168 7.168 0 0 0-4.283 1.05 7.2 7.2 0 0 0-3.213 7.76l-.04-.208 6.924 46.933a2.5 2.5 0 0 0 .041.211 7.197 7.197 0 0 0 5.84 5.438 7.199 7.199 0 0 0 7.26-3.315 2.5 2.5 0 0 0 .086-.152l3.416-6.506 9.967 16.133c1.064 1.72 2.792 3.075 4.998 3.81 2.206.735 5.024.678 7.486-.92 2.467-1.6 3.768-4.052 4.082-6.338.315-2.284-.175-4.401-1.207-6.07v-.002L56.4 245.863l7.356-.138a2.5 2.5 0 0 0 .178-.01 7.199 7.199 0 0 0 6.209-5.014 7.196 7.196 0 0 0-2.256-7.654 2.5 2.5 0 0 0-.164-.127l-38.784-27.235.165.127a7.176 7.176 0 0 0-4.094-1.644zm-.352 4.978c.445.032.88.202 1.244.506a2.5 2.5 0 0 0 .164.125l38.631 27.127c.671.571.937 1.465.674 2.307a2.167 2.167 0 0 1-1.871 1.518l-11.578.216a2.5 2.5 0 0 0-2.08 3.815l12.271 19.861a2.5 2.5 0 0 0 0 .002c.328.53.652 1.701.506 2.76-.146 1.059-.58 2.004-1.848 2.826-1.128.733-2.13.72-3.185.37-1.054-.352-2.027-1.215-2.324-1.694l-.002-.002-12.27-19.862a2.5 2.5 0 0 0-4.34.153l-5.377 10.244a2.164 2.164 0 0 1-2.193.998 2.164 2.164 0 0 1-1.762-1.629L22.42 212.04a2.5 2.5 0 0 0-.04-.21 2.163 2.163 0 0 1 .975-2.358 2.5 2.5 0 0 0 .002 0c.404-.25.857-.356 1.301-.325z"
          fill="#ffffff"
        />
      </g>
    </svg>
  );
}

export default Logo;
