import * as React from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'utils/hcfaConstants';
import { getYupValidator, getYupWarning } from 'utils/ub04/validataions';
import { FormContext } from 'components/common/FormProvider';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import { toAlphaNumUppercase } from '../../../helpers/toAlphaNumUppercase';

type Fields = 'code' | 'dateFrom' | 'dateTo';

type FieldsetSpanOccurrenceProps = {
  codeId: string;
  dateFromId: string;
  dateToId: string;
  labels?: Partial<Record<Fields, string>>;
};

function FieldsetSpanOccurrence(props: FieldsetSpanOccurrenceProps): JSX.Element {
  const { codeId, dateFromId, dateToId, labels } = props;

  const { t } = useTranslation();
  const { form } = React.useContext(FormContext);

  return (
    <Row gutter={0}>
      <Col span={8}>
        <Form.Item
          label={labels?.code || 'Code'}
          name={codeId}
          rules={[getYupValidator(t, codeId, getFormProvidersDataFromGlobalObject())]}
        >
          <Input placeholder="11" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={labels?.dateFrom || 'Date from'}
          name={dateFromId}
          rules={[getYupValidator(t, dateFromId, getFormProvidersDataFromGlobalObject()), getYupWarning(t, dateFromId)]}
        >
          <DatePickerAutoPopulate
            suffixIcon={null}
            style={{ width: '100%' }}
            panelRender={() => false}
            format={DATE_FORMAT}
            placeholder={t('common:fields.dateFormat')}
            name={dateFromId}
            form={form!}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={labels?.dateTo || 'Date to'}
          name={dateToId}
          rules={[getYupValidator(t, dateToId, getFormProvidersDataFromGlobalObject()), getYupWarning(t, dateToId)]}
        >
          <DatePickerAutoPopulate
            suffixIcon={null}
            style={{ width: '100%' }}
            panelRender={() => false}
            format={DATE_FORMAT}
            placeholder={t('common:fields.dateFormat')}
            name={dateToId}
            form={form!}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FieldsetSpanOccurrence;
