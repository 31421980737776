import { Tooltip } from 'antd';
import React from 'react';

interface Props {
  colorObject: Record<string, string>;
}
const ColorPalette: React.FC<Props> = ({ colorObject }) => {
  return (
    <div className="color-palette">
      <Tooltip title="Primary Color">
        <span style={{ backgroundColor: `${colorObject['primary-color']}` }}></span>
      </Tooltip>
      <Tooltip title="Neutral Link Color">
        <span style={{ backgroundColor: `${colorObject['neutral-link-color']}` }}></span>
      </Tooltip>
      <Tooltip title="Primary Link Color">
        <span style={{ backgroundColor: `${colorObject['primary-link-color']}` }}></span>
      </Tooltip>
      <Tooltip title="Selected Text Color">
        <span style={{ backgroundColor: `${colorObject['selected-text-color']}` }}></span>
      </Tooltip>
      <Tooltip title="Regular Text Color">
        <span style={{ backgroundColor: `${colorObject['regular-text-color']}` }}></span>
      </Tooltip>
    </div>
  );
};

export default ColorPalette;
