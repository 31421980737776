import { toAlphaNum } from './toAlphaNum';
import { uppercaseInputValue } from './uppercaseInputValue';

export const toAlphaNumUppercase = (e: React.FormEvent<HTMLInputElement>) => {
  uppercaseInputValue(e);
  toAlphaNum(e);
};

export const toModifier = (e: React.FormEvent<HTMLInputElement>) => {
  toAlphaNumUppercase(e);
  const target = e.target as HTMLInputElement;
  if (target.value.length > 2) target.value = target.value.substring(0, 2);
};
