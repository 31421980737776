import axios from 'axios';

const adminRoute = () => {
  const authRoute = window._env_.AUTH_AUTHORITY;
  const adminPath = '/admin';
  const adminInsertion = window._env_.AUTH_AUTHORITY.indexOf('realms') - 1;
  const adminRoute = authRoute.substring(0, adminInsertion) + adminPath + authRoute.substring(adminInsertion);
  return adminRoute;
};

export const getUsers = async (token: string, data?: Record<string, string>) => {
  let queryParameter = '';
  if (data) {
    queryParameter = '?q=';
    Object.entries(data).forEach(([_k, _v]) => {
      if (_k !== 'providerType' && _v) {
        queryParameter += `${_k}:"${_v}" `;
      }
    });
  }
  const res = await axios.get<any>(adminRoute() + `/users${queryParameter}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};
export const getGroups = async (token: string, data?: Record<string, string>) => {
  let queryParameter = '';
  if (data) {
    queryParameter = '?q=';
    Object.entries(data).forEach((_k, _v) => {
      queryParameter += `${_k}:"${_v}" `;
    });
  }
  const res = await axios.get<any>(adminRoute() + `/groups${queryParameter}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getRoles = async (token: string) => {
  const res = await axios.get<any>(adminRoute() + `/roles`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};
