import { notification } from 'antd';
import axios from 'axios';
import { SECTIONS } from 'config/constants';
import { isAxiosError } from 'helpers/isAxiosError';
import { FileRepresentationsDTO } from 'models/DTO/AttachmentDTO';
import { AuditTrailDTO } from 'models/DTO/AuditTrailDTO';
import { ClaimDTO } from 'models/DTO/ClaimDTO';
import { Paginator } from 'models/DTO/Paginator';
import { SearchClaim } from 'models/DTO/SearchClaim';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';
import moment from 'moment';
import uuid from 'react-uuid';

type Moment = moment.Moment;

export interface SearchParams {
  patFirstName: string;
  patLastName: string;
  insuredId: string;
  insFirstName: string;
  insLastName: string;
  status: string;
  claimId: string;
  patDob: number | Moment;
  insDob: number | Moment;
  totalCharges: number;
  submitStart: number | Moment;
  submitEnd: number | Moment;
  originType: string;
  dcn: string;
  size: number;
  page: number;
  groupId: string;
}

type SearchParamsKeys = (keyof SearchParams)[];

const dateSearchProps: SearchParamsKeys = ['patDob', 'insDob', 'submitStart', 'submitEnd'];

function transformSearchParams(params: Partial<SearchParams>) {
  const paramsTransformed = { ...params };

  dateSearchProps.forEach((dateProp) => {
    if (paramsTransformed[dateProp]) {
      if (dateProp === 'patDob' || dateProp === 'insDob' || dateProp === 'submitStart') {
        /**hour(7) weird workaround */
        paramsTransformed[dateProp] = moment(paramsTransformed[dateProp]).hour(7).utc().startOf('day').unix() * 1000;
      }
      if (dateProp === 'submitEnd') {
        /**hour(7) weird workaround */
        paramsTransformed[dateProp] = moment(paramsTransformed[dateProp]).hour(7).utc().endOf('day').unix() * 1000;
      }
    }
  });

  return paramsTransformed;
}

export const searchClaims = async (
  params: Partial<SearchParams>,
  form: SECTIONS,
  isArchive?: boolean,
  token?: string
) => {
  const queryString = new URLSearchParams();
  const transformedParams = transformSearchParams(params);
  for (const key in transformedParams) {
    // @ts-ignore
    if (params.hasOwnProperty(key) && (transformedParams[key] || typeof transformedParams[key] === 'number')) {
      // @ts-ignore
      queryString.append(key, transformedParams[key]);
    }
  }

  const qStr = queryString.toString();
  const res = await axios.get<Paginator<SearchClaim>>(
    window._env_.API_URL + `/api/v1/claims/${form}?archived=${isArchive ? isArchive : false}${qStr ? '&' : ''}${qStr}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.data;
};

export const getClaimdataById = async (
  id: string,
  section: SECTIONS,
  token: string,
  isArchive: boolean
): Promise<ClaimDTO> => {
  try {
    const res = await axios.get<ClaimDTO>(
      window._env_.API_URL + `/api/v1/claims/${section}/${id}?archived=${isArchive}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return res.data;
  } catch (err: any) {
    notification.error({ message: `Unable to fetch ${section?.toUpperCase()} claim` });
    throw new Error(err);
  }
};

export const getOriginClaimdataByOriginId = async (
  id: string,
  section: SECTIONS,
  token: string,
  isArchive: boolean
): Promise<SearchClaim[]> => {
  try {
    const res = await axios.get<Paginator<SearchClaim>>(
      window._env_.API_URL + `/api/v1/claims/${section}?archived=${isArchive}&versionId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return res.data.content;
  } catch (err: any) {
    notification.error({ message: `Unable to fetch ${section?.toUpperCase()} claim` });
    throw new Error(err);
  }
};

export const uploadClaimStraightThrough = async (
  fileReps: FileRepresentationsDTO[],
  formType: SECTIONS,
  token: string
) => {
  const claimId = uuid();
  const claimJson = new ClaimDTO(claimId, ClaimStatusEnum.SUBMIT_STARTED, [
    {
      id: uuid(),
      documentDefinitionExternalId: null,
      name: formType
    }
  ]);
  const implicitType = formType.toUpperCase();
  claimJson.fileRepresentations = fileReps.map((r) => ({
    ...r,
    fileRepresentationType: 'INGESTED_FILE',
    documentTypes: r.containsOriginalClaim ? [implicitType, ...r.documentTypes] : r.documentTypes
  }));
  const res = await axios.post(window._env_.API_URL + `/api/v1/claims/${formType}/submit`, claimJson, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const resubmitClaimStraightThrough = async (claimId: string, formType: SECTIONS, token: string) => {
  const res = await axios.post(window._env_.API_URL + `/api/v1/claims/${formType}/${claimId}/resubmit`, undefined, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};
/**
 * @deprecated Use `copyClaim()` instead.
 */
export const copyClaimStraightThrough = async (
  claimId: string,
  formType: SECTIONS,
  token: string,
  formGroupJson: any,
  includeAttachments: boolean
) => {
  const res = await axios.post(
    window._env_.API_URL + `/api/v1/claims/${formType}/${claimId}/copy?includeAttachments=${includeAttachments}`,
    formGroupJson,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return res.data;
};

export const copyClaim = async (formType: SECTIONS, token: string, formGroupJson: any, includeAttachments: boolean) => {
  const res = await axios.post(
    window._env_.API_URL + `/api/v1/claims/${formType}/copy?includeAttachments=${includeAttachments}`,
    formGroupJson,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return res.data;
};

export const deleteClaimById = async (claimId: string, formType: SECTIONS, token: string) => {
  const res = await axios.delete(window._env_.API_URL + `/api/v1/claims/${formType}/${claimId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const releaseClaimLock = async (claimId: string, formType: SECTIONS, token: string) => {
  const res = await axios.delete(window._env_.API_URL + `/api/v1/claims/${formType}/${claimId}/lock`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const lockClaim = async (claimId: string, formType: SECTIONS, token: string) => {
  const res = await axios.post(window._env_.API_URL + `/api/v1/claims/${formType}/${claimId}/lock`, undefined, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const exportRetryClaim = async (claimId: string, section: SECTIONS, token: string, isArchive: boolean) => {
  try {
    const claimData = await getClaimdataById(claimId, section, token, isArchive);
    const retryClaimBody = { ...claimData, retry: true };
    const res = await axios.post(window._env_.API_URL + `/api/v1/claims/${section}/submit`, retryClaimBody, {
      headers: { Authorization: `Bearer ${token}` }
    });

    return res.data;
  } catch (e) {
    if (isAxiosError(e))
      notification.error({ message: `Unable retry claim ${claimId}`, description: 'Server error occured' });
  }
};

export const getAuditTrailByClaimId = async (claimId: string, token: string) => {
  const res = await axios.get<AuditTrailDTO[]>(window._env_.API_URL + `/api/v1/reports/audit-trail/${claimId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};
