import { Form, Input, InputRef } from 'antd';
import { toNum } from 'helpers/toNum';
import moment from 'moment';
import React, { useRef } from 'react';
import { DATE_FORMAT } from 'utils/hcfaConstants';

type Props = {
  format: string | string[];
  property: Record<any, any>;
};

const SearchDateRangeAutoPopulate: React.FC<Props> = (props) => {
  const { property } = props;
  const leftInput = useRef<InputRef>(null);
  const rightInput = useRef<InputRef>(null);

  const dateFormat = (props.format as string | string[]) || DATE_FORMAT;

  const dateValidator = React.useMemo(() => {
    return ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(_: any, _value: any, _cb: any) {
        const _field = _.field;
        const _date = moment(_value, dateFormat, true);
        const _startDate = getFieldValue(property.key + 'Start');
        const _finishDate = getFieldValue(property.key + 'End');
        if (!_date?.isValid() && _value) _cb(`This is not a valid date (MMDDYYYY)`);
        else {
          if (_field.endsWith('Start')) {
            if (_finishDate && !_value) _cb('This field is mandatory when value is indicated in end date field');
          } else {
            if (_startDate && !_value) _cb('This field is mandatory when value is indicated in start date field');
          }
        }
        _cb();
      }
    });
  }, [dateFormat, property.key]);

  const normalize = React.useCallback(
    (_val: any, _prev: any, _all: any, _name: string) => {
      const _endName = property.key + 'End';
      const _startName = property.key + 'Start';
      const _currDate = moment(_val, dateFormat, true);
      if (_currDate.isValid()) {
        if (_name === 'Start' && (!moment.isMoment(_all[_endName]) || !_all[_endName].isValid())) {
          rightInput?.current?.focus();
        } else if (_name === 'End' && (!moment.isMoment(_all[_startName]) || !_all[_startName].isValid()))
          leftInput?.current?.focus();
        return _currDate;
      }
      return _val;
    },
    [dateFormat, property.key]
  );

  return (
    <Form.Item label={property.name}>
      <div className="DatePickerAutoPopulate__input-warpper">
        <Form.Item
          key={property.key + 'Start'}
          name={property.key + 'Start'}
          rules={[dateValidator]}
          // dependencies={[property.key + 'End']}
          normalize={(val, prev, all) => normalize(val, prev, all, 'Start')}
          getValueProps={(_v) => _v}
        >
          <Input ref={leftInput} placeholder="Start Date (MMDDYYYY)" onInput={toNum} />
        </Form.Item>
        <span>&#x300B;</span>
        <Form.Item
          key={property.key + 'End'}
          name={property.key + 'End'}
          rules={[dateValidator]}
          // dependencies={[property.key + 'Start']}
          normalize={(val, prev, all) => normalize(val, prev, all, 'End')}
          getValueProps={(_v) => _v}
        >
          <Input ref={rightInput} placeholder="End Date (MMDDYYYY)" onInput={toNum} />
        </Form.Item>
      </div>
    </Form.Item>
  );
};

export default SearchDateRangeAutoPopulate;
