import React, { useState, useEffect, useImperativeHandle, useCallback } from 'react';
import { Button, ButtonList } from '@datum/react-components';
import { Page } from 'components/common/Page';
import { SECTIONS } from 'config/constants';
import { useTranslation } from 'react-i18next';
import { Affix, Checkbox, Dropdown, Form, Input, Select, Table, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIcon } from 'heroicons/outline/search.svg';

import { dentalFields, hcfaFields, ub04Fields } from 'config/search';
import claimSearchData from 'sampleData/claimSearchData.json';
import { useFormProvider } from 'context/form.context';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DownSquareOutlined, InfoCircleOutlined, SafetyOutlined, UserOutlined } from '@ant-design/icons';
import { SearchParams, searchClaims } from 'api/claims';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import moment from 'moment';

import { MdDateRange } from 'react-icons/md';
import type { MenuProps, TablePaginationConfig } from 'antd';
import { SearchClaim } from 'models/DTO/SearchClaim';
import ClaimInfoRow from 'components/common/ClaimInfoRow';
import ActionsTableRow from 'components/common/ActionsTableRow';

import { useResetFormProvider } from 'components/common/FormProvider';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';
import { AiOutlineClear } from 'react-icons/ai';
import { onSearchSelect } from 'utils/search/search';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import SearchDateRangeAutoPopulate from './SearchDateRangeAutoPopulate';
import { useCanHandleDental } from 'utils/hooks/useCanHandleDental';
import ForbiddenPage from './ForbiddenPage';
import { ActionType, usePaginationOptions } from 'utils/hooks/usePaginationOptions';
import { OriginTypeEnum } from 'models/enums/OriginTypeEnum';

const DEFAULT_FILTERS = ['submit', 'groupId', 'insuredId', 'status', 'dcn'];
type SearchProps = {
  section: SECTIONS;
  isArchive?: boolean;
};

type SectionProps = {
  properties: Record<any, any>[];
  tableViews: Record<any, any>[];
  data: Record<any, any>[];
};

const DATE_FORMAT = 'MMDDYYYY';

export const PER_PAGE_CLAIMS = 10;

function Search({ section, isArchive }: SearchProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = useForm();

  const [searchPerformed, setSearchPerformed] = React.useState(false);
  const [{ currentPage, pageSize, totalCount }, dispatch] = usePaginationOptions();
  const [data, setData] = React.useState<SearchClaim[]>([]);

  const canHandleDental = useCanHandleDental();
  const isDental = section === SECTIONS.DENTAL;
  const showForbidden = !canHandleDental && isDental;

  const [_, formDispatch] = useFormProvider();

  const { accessToken } = useOidcAccessToken();

  const [checksValues, setChecksValues] = React.useState<any>(DEFAULT_FILTERS);

  const pagination: TablePaginationConfig | false = searchPerformed
    ? {
        /**pageSizeOptions: ['10', '20', '50', '100'],*/ showSizeChanger: true,
        pageSize,
        current: currentPage + 1,
        total: totalCount,
        onChange(page, pageSize) {
          dispatch({ type: ActionType.SET_CURRENT_PAGE, payload: page - 1 });
          dispatch({ type: ActionType.SET_PAGE_SIZE, payload: pageSize });
          handleSearch({ page: page - 1, size: pageSize });
        }
      }
    : false;

  useResetFormProvider();

  useEffect(() => {
    form.resetFields();
    setData([]);
    setSearchPerformed(false);
  }, [section]);

  //MEMO
  const sectionProps = React.useMemo(() => {
    let _sectionProps: SectionProps = {
      properties: [],
      tableViews: [],
      data: []
    };
    switch (section) {
      case SECTIONS.HCFA: {
        _sectionProps = {
          ..._sectionProps,
          properties: hcfaFields,
          data: claimSearchData.hfca,
          tableViews: [
            {
              title: <span className="table-title">{<InfoCircleOutlined />} Claim Info</span>,
              dataIndex: 'docId',
              key: 'docId',
              fixed: 'left',
              width: 250,
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return <ClaimInfoRow form={section} record={record} text={text} />;
              }
            },
            {
              title: (
                <span className="table-title">
                  <UserOutlined /> Patient Info
                </span>
              ),
              width: 180,
              dataIndex: 'patientFirstName',
              key: 'patientFirstName',
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="claims-info">
                    <div>
                      <p title={text + ' ' + record.patientLastName}>
                        {text} {record.patientLastName}
                      </p>
                    </div>
                    <div>
                      <p>
                        {/* <Tooltip title="Address">
                          <TiLocationOutline title="Address" />
                        </Tooltip> */}
                        {/* <span title={`ADDRESS`}>
                          <b>Address |</b> ADDRESS
                        </span> */}
                      </p>
                    </div>
                  </div>
                );
              }
            },
            {
              title: (
                <span className="table-title">
                  <SafetyOutlined /> Insured Info
                </span>
              ),
              width: 180,
              dataIndex: 'insuredId',
              key: 'insuredId',

              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="insured-info">
                    {record.insuredId && (
                      <div>
                        <small>Insured ID: {record.insuredId}</small>
                        <p title={record.insuredFirstName + ' ' + record.insuredLastName}>
                          {record.insuredFirstName} {record.insuredLastName}
                        </p>
                      </div>
                    )}
                    {record.insuredDob && (
                      <div>
                        <p>
                          <Tooltip title="Date of Birth">
                            <MdDateRange title="Date of Birth" />
                          </Tooltip>
                          {record.insuredDob && (
                            <span title={`${moment(record.insuredDob).format(DATE_FORMAT)}`}>
                              <b>Dob |</b> {moment(record.insuredDob).format(DATE_FORMAT)}
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                );
              }
            },

            {
              //title: 'Action',
              dataIndex: '',
              key: 'x',
              fixed: 'right',
              width: 200,
              render: (_: any, row: SearchClaim) => (
                <ActionsTableRow isArchive={isArchive} row={row} section={section} setData={setData} />
              )
            }
          ]
        };
        break;
      }
      case SECTIONS.DENTAL: {
        _sectionProps = {
          ..._sectionProps,
          properties: dentalFields,
          data: claimSearchData.hfca,
          tableViews: [
            {
              title: <span className="table-title">{<InfoCircleOutlined />} Claim Info</span>,
              dataIndex: 'docId',
              key: 'docId',
              fixed: 'left',
              width: 250,
              render: (text: any, record: any, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return <ClaimInfoRow form={section} record={record} text={text} />;
              }
            },
            {
              title: (
                <span className="table-title">
                  <UserOutlined /> Patient Info
                </span>
              ),
              width: 250,
              dataIndex: 'patientFirstName',
              key: 'patientFirstName',
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="claims-info">
                    <div>
                      <p title={text + ' ' + record.patientLastName}>
                        {text} {record.patientLastName}
                      </p>
                    </div>
                    <div>
                      <p>
                        {/* <Tooltip title="Address">
                          <TiLocationOutline title="Address" />
                        </Tooltip> */}
                        {/* <span title={`ADDRESS`}>
                          <b>Address |</b> ADDRESS
                        </span> */}
                      </p>
                    </div>
                  </div>
                );
              }
            },
            {
              title: (
                <span className="table-title">
                  <SafetyOutlined /> Insured Info
                </span>
              ),
              width: 250,
              dataIndex: 'insuredId',
              key: 'insuredId',

              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="insured-info">
                    {record.insuredId && (
                      <div>
                        <small>Insured ID: {record.insuredId && record.insuredId}</small>
                        <p title={record.insuredFirstName + ' ' + record.insuredLastName}>
                          {record.insuredFirstName} {record.insuredLastName}
                        </p>
                      </div>
                    )}
                    {record.insuredDob && (
                      <div>
                        <p>
                          <Tooltip title="Date of Birth">
                            <MdDateRange title="Date of Birth" />
                          </Tooltip>
                          {record.insuredDob && (
                            <span title={`${moment(record.insuredDob).format(DATE_FORMAT)}`}>
                              <b>Dob |</b> {moment(record.insuredDob).format(DATE_FORMAT)}
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                );
              }
            },

            {
              //title: 'Action',
              dataIndex: '',
              key: 'x',
              fixed: 'right',
              width: 200,
              render: (_: any, row: SearchClaim) => (
                <ActionsTableRow isArchive={isArchive} row={row} section={section} setData={setData} />
              )
            }
          ]
        };
        break;
      }
      case SECTIONS.UB04: {
        _sectionProps = {
          ..._sectionProps,
          properties: ub04Fields,
          data: claimSearchData.ub,
          tableViews: [
            {
              title: <span className="table-title">{<InfoCircleOutlined />} Claim Info</span>,
              dataIndex: 'docId',
              key: 'docId',
              fixed: 'left',
              width: 250,
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return <ClaimInfoRow form={section} record={record} text={text} />;
              }
            },
            {
              title: (
                <span className="table-title">
                  <UserOutlined /> Patient Info
                </span>
              ),
              width: 250,
              dataIndex: 'patientFirstName',
              key: 'patientFirstName',
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="claims-info">
                    <div>
                      <p title={text + ' ' + record.patientLastName}>
                        {text} {record.patientLastName}
                      </p>
                    </div>
                    <div>
                      <p>
                        {/* <Tooltip title="Address">
                          <TiLocationOutline title="Address" />
                        </Tooltip> */}
                        {/* <span title={`ADDRESS`}>
                          <b>Address |</b> ADDRESS
                        </span> */}
                      </p>
                    </div>
                  </div>
                );
              }
            },
            {
              title: (
                <span className="table-title">
                  <SafetyOutlined /> Insured Info
                </span>
              ),
              width: 250,
              dataIndex: 'insuredId',
              key: 'insuredId',

              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="insured-info">
                    {record.insuredId && (
                      <div>
                        <small>Insured ID: {record.insuredId && record.insuredId}</small>
                        <p title={record.insuredFirstName + ' ' + record.insuredLastName}>
                          {record.insuredFirstName} {record.insuredLastName}
                        </p>
                      </div>
                    )}
                    {record.insuredDob && (
                      <div>
                        <p>
                          <Tooltip title="Date of Birth">
                            <MdDateRange title="Date of Birth" />
                          </Tooltip>
                          {record.insuredDob && (
                            <span title={`${moment(record.insuredDob).format(DATE_FORMAT)}`}>
                              <b>Dob |</b> {moment(record.insuredDob).format(DATE_FORMAT)}
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                );
              }
            },

            {
              //title: 'Action',
              dataIndex: '',
              key: 'x',
              fixed: 'right',
              width: 200,
              render: (_: any, row: SearchClaim) => (
                <ActionsTableRow isArchive={isArchive} row={row} section={section} setData={setData} />
              )
            }
          ]
        };
        break;
      }

      default: {
        break;
      }
    }

    return _sectionProps;
  }, [formDispatch, navigate, section]);

  const handleSearch = useCallback(
    async ({ page, size }: { page: number; size: number }) => {
      await form.validateFields();
      const formData = form.getFieldsValue();
      const searchParams: Partial<SearchParams> = {
        insFirstName: formData['insFirstName'],
        insLastName: formData['insLastName'],
        patFirstName: formData['patFirstName'],
        patLastName: formData['patLastName'],
        insuredId: formData['insuredId'],
        status: formData['status'],
        claimId: formData['claimId'],
        insDob: formData['insDob'],
        patDob: formData['patDob'],
        totalCharges: formData['totalCharges'],
        originType: formData['originType'],
        dcn: formData['dcn'],
        submitStart: formData['submitStart'],
        submitEnd: formData['submitEnd'],
        groupId: formData['groupId'],
        page,
        size
      };

      const data = await searchClaims(searchParams, section, isArchive, accessToken);
      setSearchPerformed(true);
      dispatch({ type: ActionType.SET_TOTAL_COUNT, payload: data.totalElements });
      setData(data.content.map((a: any) => ({ ...a, key: a.id })));
    },
    [form, setSearchPerformed, section, accessToken, isArchive, currentPage, pageSize, dispatch]
  );

  const handleSearchBtn = () => {
    dispatch({ type: ActionType.SET_CURRENT_PAGE, payload: 0 });
    handleSearch({ page: 0, size: pageSize });
  };

  const options = [
    { label: 'Claim ID', value: 'groupId', form: 'all' },
    { label: 'Submission date', value: 'submit', form: 'all' },
    { label: 'Status', value: 'status', form: 'all' },
    { label: 'ID', value: 'claimId', form: 'all' },

    { label: 'Ins. ID', value: 'insuredId', form: 'dental' },
    { label: 'Pat. last name', value: 'patLastName', form: 'dental' },
    { label: 'Pat. first name', value: 'patFirstName', form: 'dental' },
    { label: 'Ins. First Name', value: 'insFirstName', form: 'dental' },
    { label: 'Ins. Last Name', value: 'insLastName', form: 'dental' },
    { label: 'Pat. Birth Date', value: 'patDob', form: 'dental' },
    { label: 'Ins. Birth Date', value: 'insDob', form: 'dental' },
    { label: 'Total charges', value: 'totalCharges', form: 'dental' },
    { label: 'Origin type', value: 'originType', form: 'dental' },
    { label: 'DCN', value: 'dcn', form: 'dental' },

    { label: 'Ins. ID', value: 'insuredId', form: 'hcfa' },
    { label: 'Pat. first name', value: 'patFirstName', form: 'hcfa' },
    { label: 'Pat. last name', value: 'patLastName', form: 'hcfa' },
    { label: 'Ins. First Name', value: 'insFirstName', form: 'hcfa' },
    { label: 'Ins. Last Name', value: 'insLastName', form: 'hcfa' },
    { label: 'Pat. Birth Date', value: 'patDob', form: 'hcfa' },
    { label: 'Ins. Birth Date', value: 'insDob', form: 'hcfa' },
    { label: 'Total charges', value: 'totalCharges', form: 'hcfa' },
    { label: 'Origin type', value: 'originType', form: 'hcfa' },
    { label: 'DCN', value: 'dcn', form: 'hcfa' },

    { label: 'Pat. last name', value: 'patLastName', form: 'ub04' },
    { label: 'Pat. first name', value: 'patFirstName', form: 'ub04' },
    { label: 'Ins. ID', value: 'insuredId', form: 'ub04' },
    { label: 'Ins. Last Name', value: 'insLastName', form: 'ub04' },
    { label: 'Ins. First Name', value: 'insFirstName', form: 'ub04' },
    { label: 'Pat. Birth Date', value: 'patDob', form: 'ub04' },
    { label: 'Ins. Birth Date', value: 'insDob', form: 'ub04' },
    { label: 'Total charges', value: 'totalCharges', form: 'ub04' },
    { label: 'Origin type', value: 'originType', form: 'ub04' },
    { label: 'DCN', value: 'dcn', form: 'ub04' }
  ];

  const filteredOptions = options.filter((option) => option.form === section || option.form === 'all');

  const handleSearchLastTen = async () => {
    const searchParams: Partial<SearchParams> = {
      insFirstName: undefined,
      insLastName: undefined,
      patFirstName: undefined,
      patLastName: undefined,
      insuredId: undefined,
      status: undefined,
      claimId: undefined,
      groupId: undefined,
      page: 0,
      size: 10
    };
    const data = await searchClaims(searchParams, section, isArchive, accessToken);

    setData(data.content.map((a: any) => ({ ...a, key: a.id })));
  };

  useEffect(() => {
    handleSearchLastTen();
  }, [section]);

  useEffect(() => {
    form.resetFields();
    setData([]);
    setSearchPerformed(false);
  }, []);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setChecksValues(checkedValues);
  };

  const menu: MenuProps['items'] = [
    {
      key: '1',
      label: <Checkbox.Group options={filteredOptions} defaultValue={DEFAULT_FILTERS} onChange={onChange} />
    }
  ];
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [dropdownKey, setDropdownKey] = useState(0);

  function hanfleCleareFilter() {
    form.resetFields();

    dispatch({ type: ActionType.RESET, payload: -1 });
    handleSearch({ page: 0, size: PER_PAGE_CLAIMS });
    setChecksValues(DEFAULT_FILTERS);
    setDropdownKey((prevKey) => prevKey + 1);
  }

  function handleFilter(e: any, value: any) {
    e?.stopPropagation();
    setOverlayVisible(value);
  }

  const dateValidator = React.useMemo(() => {
    return {
      validator(_: any, _value: any, _cb: any) {
        if (!_value || _value.isValid()) _cb();
        else _cb('This is not a valid date (MMDDYYYY)');
      }
    };
  }, []);

  if (showForbidden) return <ForbiddenPage />;

  return (
    <div className={'search-page'}>
      <Page.Content>
        <div className="search-wrapper">
          <div className="search-form-wrapper">
            <div className="search-title-checks-wrapper">
              <h2>Search claims</h2>
              <div className="search-checkboxes" style={{ display: 'flex', alignItems: 'center' }}>
                <Dropdown
                  key={dropdownKey}
                  open={overlayVisible}
                  placement="bottom"
                  menu={{
                    items: menu,
                    onClick: (info: any): void => {
                      setOverlayVisible(true);
                    },
                    onMouseLeave: (): void => setOverlayVisible(false)
                  }}
                >
                  <Button
                    className="rapid-button rapid-button--primary-outline flex-center"
                    variant="outline"
                    onClick={(e: any) => handleFilter(e, !overlayVisible)}
                  >
                    <span className="label-btn-wrap">
                      <DownSquareOutlined /> Select filters
                    </span>
                  </Button>
                </Dropdown>
                <Button variant="outline" style={{ marginLeft: '1rem' }} onClick={hanfleCleareFilter}>
                  <span className="label-btn-wrap">
                    <AiOutlineClear style={{ marginRight: '7px' }} /> {t('common:clearText')}
                  </span>
                </Button>
              </div>
            </div>
            <Form layout={'inline'} form={form} validateTrigger={searchPerformed ? 'onChange' : ''}>
              <div className={'search-inputs-form'}>
                {sectionProps.properties.map((_property) => {
                  if (checksValues.includes(_property.key)) {
                    if (_property.type === 'date') {
                      return (
                        <Form.Item
                          key={_property.key}
                          label={_property.name}
                          name={_property.key}
                          rules={[dateValidator]}
                        >
                          <DatePickerAutoPopulate
                            suffixIcon={null}
                            panelRender={() => false}
                            format={DATE_FORMAT}
                            placeholder={t('common:fields.dateFormat')}
                            style={{ width: '200px' }}
                            form={form}
                            name={_property.key}
                          />
                        </Form.Item>
                      );
                    } else if (_property.key === 'status') {
                      return (
                        <Form.Item key={_property.key} label={_property.name} name={_property.key}>
                          <Select
                            placeholder="Select status"
                            style={{ width: '200px' }}
                            allowClear
                            showSearch
                            options={[
                              {
                                value: ClaimStatusEnum.SNG_837_SUBMISSION_IN_PROGRESS,
                                label: '837 Submission in Progress'
                              },
                              {
                                value: ClaimStatusEnum.SNG_837_SUBMITTED_AND_WAITING_FOR_ACK,
                                label: '837 Submitted & Waiting for Ack'
                              },
                              {
                                value: ClaimStatusEnum.SNG_837_SUCCESSFULLY_SUBMITTED,
                                label: '837 Successfully Submitted'
                              },
                              {
                                value: ClaimStatusEnum.IN_PROGRESS,
                                label: 'In progress'
                              },
                              {
                                value: ClaimStatusEnum.SUBMIT_STARTED,
                                label: 'Submit started'
                              },
                              {
                                value: ClaimStatusEnum.SUBMIT_FINISHED,
                                label: 'Submit finished'
                              },
                              {
                                value: ClaimStatusEnum.SUBMIT_ERROR,
                                label: 'Submit error'
                              },
                              {
                                value: ClaimStatusEnum.RESUBMITTED,
                                label: 'Resubmitted'
                              },
                              {
                                value: ClaimStatusEnum.EXTERNAL_EXPORT_FINISHED,
                                label: 'External export finished'
                              },
                              {
                                value: ClaimStatusEnum.REJECTED,
                                label: 'Rejected'
                              },
                              {
                                value: ClaimStatusEnum.EXPORT_MQ_ERROR,
                                label: 'Export error'
                              }
                            ]}
                            onSearch={(input) => {
                              onSearchSelect(form, _property.key, input, [
                                ClaimStatusEnum.SNG_837_SUBMISSION_IN_PROGRESS,
                                ClaimStatusEnum.SNG_837_SUBMITTED_AND_WAITING_FOR_ACK,
                                ClaimStatusEnum.SNG_837_SUCCESSFULLY_SUBMITTED,
                                ClaimStatusEnum.IN_PROGRESS,
                                ClaimStatusEnum.SUBMIT_STARTED,
                                ClaimStatusEnum.SUBMIT_FINISHED,
                                ClaimStatusEnum.SUBMIT_ERROR,
                                ClaimStatusEnum.EXTERNAL_EXPORT_FINISHED,
                                ClaimStatusEnum.REJECTED,
                                ClaimStatusEnum.EXPORT_MQ_ERROR
                              ]);
                            }}
                          />
                        </Form.Item>
                      );
                    } else if (_property.key === 'originType') {
                      return (
                        <Form.Item key={_property.key} label={_property.name} name={_property.key}>
                          <Select
                            placeholder="Select status"
                            style={{ width: '200px' }}
                            allowClear
                            showSearch
                            options={[
                              {
                                value: OriginTypeEnum.DIRECT_UPLOAD,
                                label: 'Direct upload'
                              },
                              {
                                value: OriginTypeEnum.DDE_PORTAL,
                                label: 'DDE portal'
                              },
                              {
                                value: OriginTypeEnum.COPY_PORTAL,
                                label: 'Copy portal'
                              },
                              {
                                value: OriginTypeEnum.CORRECTED,
                                label: 'Corrected'
                              },
                              {
                                value: OriginTypeEnum.RC,
                                label: 'RC'
                              }
                            ]}
                            onSearch={(input) => {
                              onSearchSelect(form, _property.key, input, [
                                OriginTypeEnum.DIRECT_UPLOAD,
                                OriginTypeEnum.DDE_PORTAL,
                                OriginTypeEnum.COPY_PORTAL,
                                OriginTypeEnum.CORRECTED,
                                OriginTypeEnum.RC
                              ]);
                            }}
                          />
                        </Form.Item>
                      );
                    } else if (_property.type === 'daterange') {
                      return <SearchDateRangeAutoPopulate format={DATE_FORMAT} property={_property} />;
                    } else if (_property.type === 'number') {
                      return (
                        <Form.Item key={_property.key} label={_property.name} name={_property.key}>
                          <Input type="number" key={_property.key} placeholder={`Type ${_property.name}`} />
                        </Form.Item>
                      );
                    }

                    return (
                      <Form.Item key={_property.key} label={_property.name} name={_property.key}>
                        <Input allowClear key={_property.key} placeholder={`Type ${_property.name}`} />
                      </Form.Item>
                    );
                  }
                })}
              </div>

              <div className={'search-button'}>
                <ButtonList position="left">
                  <ButtonList.Item leftIcon={<SearchIcon />} type="button" onClick={handleSearchBtn}>
                    {'Search'}
                  </ButtonList.Item>
                </ButtonList>
              </div>
            </Form>
          </div>

          <div className="search-table">
            <div>
              {searchPerformed ? (
                <div className="search-title-checks-wrapper">
                  <h2>
                    <span>{totalCount || 0}</span> Claims{' '}
                    <small>
                      <i>({section?.replace('04', '')})</i>
                    </small>
                  </h2>
                </div>
              ) : (
                <div className="search-title-checks-wrapper">
                  <h2>
                    <span>10</span> Latest claims{' '}
                    <small>
                      <i>({section?.replace('04', '')})</i>
                    </small>
                  </h2>
                </div>
              )}
            </div>
            <Table columns={sectionProps.tableViews} dataSource={data} scroll={{ x: 960 }} pagination={pagination} />
          </div>
        </div>
      </Page.Content>
      {/* </Page> */}
    </div>
  );
}

export default Search;
