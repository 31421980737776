import * as React from 'react';
import { Navigate, Route as ReactRouterRoute, Routes } from 'react-router-dom';

//PAGES
import NotFound from 'pages/NotFound';
import Home from 'pages/Home';
import Hcfa from 'pages/Hcfa';
import Ub04 from 'pages/Ub04';
import Search from 'pages/Search';
import { SECTIONS } from 'config/constants';
import Dental from 'pages/Dental';
import Dashboard from 'pages/Dashboard';
import Admin from 'pages/Admin';
import Branding from 'pages/Branding/Branding';
import Archive from 'pages/Archive';
import AllBrands from 'pages/AllBrands';
import { useAccessTokenRoleInfo } from 'utils/hooks/useAccessTokenRoleInfo';
import Reports from 'pages/DocumentStatusReports';
import Upload from 'pages/Upload';
import DocumentStausTracking from 'pages/DocumentStausTracking';
import Profile from 'pages/Profile';
import HelpOption from 'pages/HelpOption';
import DocumentStatusReports from 'pages/DocumentStatusReports';
import LogoutPage from 'pages/LogoutPage';
import Route from './SecureRoute';
import LandingPage from 'pages/LandingPage';
import UserProfileReports from 'pages/UserProfileReports';
import GeographyVolumeReports from 'pages/GeographyVolumeReports';

function AppRoutes(): JSX.Element {
  return (
    <Routes>
      {/* DASHBOARD */}
      <Route path="/" element={<Navigate replace to="/landing" />} />

      <Route path="/landing" element={<LandingPage />} />
      <Route path="/landing/:tabId" element={<LandingPage />} />
      {/* <Route path="/:tabId" element={<LandingPage />} /> */}
      <Route path="/dashboard" element={<Dashboard />} />

      {/* HOME */}
      <Route path="/sections" element={<Home />} />
      <Route path="/home" element={<Navigate replace to="/sections" />} />
      {/* HCFA */}

      <Route path="/hcfa" element={<Navigate replace to="/landing/tab-hcfa" />} />
      <ReactRouterRoute path="/thank-you" element={<LogoutPage />} />
      {/* <Route path="/hcfa/new" element={<Hcfa />} /> */}
      {/* <Route path="/hcfa/modern/new" element={<Hcfa modern={true} />} /> */}
      <Route path="/hcfa/:action" element={<Hcfa />} />
      <Route path="/hcfa/:action/:id" element={<Hcfa />} />
      <Route path="/hcfa/view" element={<Navigate replace to="/hcfa" />} />
      {/* UB04 */}
      <Route path="/Ub04" element={<Navigate replace to="/landing/tab-ub" />} />
      {/* <Route path="/Ub04/new" element={<Ub04 />} /> */}
      <Route path="/Ub04/:action" element={<Ub04 />} />
      <Route path="/Ub04/:action/:id" element={<Ub04 />} />
      <Route path="/Ub04/view" element={<Navigate replace to="/Ub04" />} />
      {/* Dental */}
      <Route path="/dental" element={<Navigate replace to="/landing/tab-dental" />} />
      {/* <Route path="/dental/new" element={<Dental />} /> */}
      <Route path="/dental/:action/:id" element={<Dental />} />
      <Route path="/dental/:action" element={<Dental />} />
      <Route path="/dental/view" element={<Navigate replace to="/dental" />} />

      <Route path="/admin" element={<Admin />} />
      <Route path="/admin/branding" element={<Branding />} />
      <Route path="/admin/all-brands" element={<AllBrands />} />

      <Route path="/document-status-reports" element={<DocumentStatusReports />} />
      <Route path="/user-profile-reports" element={<UserProfileReports />} />
      <Route path="/geography-volume-reports" element={<GeographyVolumeReports />} />
      <Route path="/upload" element={<Upload />} />
      <Route path="/document-status" element={<DocumentStausTracking />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/help-option" element={<HelpOption />} />

      <Route path="/archive" element={<LandingPage isArchive />} />
      <Route path="/:category/hcfa" element={<LandingPage isArchive />} />
      <Route path="/:category/ub04" element={<LandingPage isArchive />} />
      <Route path="/:category/dental" element={<LandingPage isArchive />} />

      <Route path="/:category/hcfa/:action/:id" element={<Hcfa />} />
      <Route path="/:category/ub04/:action/:id" element={<Ub04 />} />
      <Route path="/:category/dental/:action/:id" element={<Dental />} />

      {/* OTHERS */}
      <Route path="/" element={<Navigate to="/" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export { AppRoutes };
