import axios from 'axios';

export const getUserStates = async (token?: string) => {
  const res = await axios.get<any>(window._env_.API_URL + `/api/v1/reports/user-states`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getUserLobs = async (token?: string) => {
  const res = await axios.get<any>(window._env_.API_URL + `/api/v1/reports/lobs`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getUserProfileReports = async (params: { lob?: string; userState?: string }, token: string) => {
  const queryString = new URLSearchParams();

  if (params.lob) {
    queryString.append('lob', params.lob);
  }
  if (params.userState) {
    queryString.append('userState', params.userState);
  }

  const res = await axios.get<any>(
    window._env_.API_URL + `/api/v1/reports/user-profile-report${queryString ? '?' + queryString : ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.data;
};

export const getGeographyVolumeReports = async (
  params: { lob?: string; claimType?: string; portalDateFrom?: string; portalDateTo?: string },
  token: string
) => {
  const queryString = new URLSearchParams();

  if (params.lob) queryString.append('lob', params.lob);
  if (params.claimType) queryString.append('claimType', params.claimType);
  if (params.portalDateFrom) queryString.append('portalDateFrom', params.portalDateFrom);
  if (params.portalDateTo) queryString.append('portalDateTo', params.portalDateTo);

  const res = await axios.get<any>(
    window._env_.API_URL + `/api/v1/reports/geography-volume-status${queryString ? '?' + queryString : ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return res.data;
};

export const dlDocumentStatusReportsCsv = async ({
  token,
  dateFrom,
  dateTo,
  portalDateFrom,
  portalDateTo,
  lob,
  claimType,
  state,
  incomingMode
}: {
  token?: string;
  dateFrom?: string;
  dateTo?: string;
  portalDateFrom?: string;
  portalDateTo?: string;
  lob?: string[];
  claimType?: string[];
  state?: string[];
  incomingMode?: string[];
}) => {
  const queryString = new URLSearchParams();
  if (dateFrom) queryString.append('dateFrom', dateFrom);
  if (dateTo) queryString.append('dateTo', dateTo);
  if (portalDateFrom) queryString.append('portalDateFrom', portalDateFrom);
  if (portalDateTo) queryString.append('portalDateTo', portalDateTo);
  if (lob) queryString.append('lob', lob.join(','));
  if (claimType) queryString.append('claimType', claimType.join(','));
  if (state) queryString.append('state', state.join(','));
  if (incomingMode) queryString.append('incomingMode', incomingMode.join(','));

  const res = await axios.get<ArrayBuffer>(
    window._env_.API_URL + `/api/v1/reports/download-document-status-csv${queryString ? '?' + queryString : ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    }
  );
  return res.data;
};
