import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';

import SelectPhoneCode from '../../common/SelectPhoneCode';
import { getYupValidator as ub04YupValidator } from 'utils/ub04/validataions';
import { getYupValidator as hcfaYupValidator } from 'utils/hcfa/validations';
import { getYupValidator as dentalYupValidator } from 'utils/dental/validations';
import SelectState from 'components/hcfa/shared/SelectState';
import { FormContext } from 'components/common/FormProvider';
import { FORM_ACTIONS } from 'config/constants';
import { toNum } from '../../../helpers/toNum';
import { toZipCode } from 'helpers/toZipCode';

type Fields = 'address' | 'city' | 'state' | 'zipcode' | 'addressTwo' | 'phone';

type FieldsetFullNameProps = {
  form: 'hcfa' | 'ub04' | 'dental';
  ids: Partial<Record<Fields, string>>;
  labels?: Partial<Record<Fields, string>>;
  breakPoints?: Partial<Record<Fields, number>>;
  labelColoring?: boolean;
  limitedAddress?: boolean;
  consecutiveAddress?: boolean;
};

const validatorsMap = {
  hcfa: hcfaYupValidator,
  ub04: ub04YupValidator,
  dental: dentalYupValidator
};

function FieldsetLocation({
  form,
  ids,
  labels,
  breakPoints,
  labelColoring,
  limitedAddress,
  consecutiveAddress
}: FieldsetFullNameProps): JSX.Element {
  const { t } = useTranslation();
  const formContext = useContext(FormContext).form;
  const { action } = useParams();

  const disabled = action === FORM_ACTIONS.VIEW;

  function getTooltipText(input?: string): string {
    if (input && disabled) {
      const value = formContext?.getFieldValue(input);
      return value;
    }
    return '';
  }
  const yupValidator = validatorsMap[form];

  let idsAddress: string = ids.address ? ids.address : '';
  let addressLabel: string = limitedAddress ? 'useAddressTwo' : idsAddress;

  return (
    <>
      <Row gutter={0}>
        <Col xs={24} md={12} lg={12} xxl={breakPoints?.address || 24}>
          <Tooltip placement="bottomLeft" title={getTooltipText(ids.address)}>
            <Form.Item
              className="field--first field--last"
              label={
                labels?.address && labelColoring ? (
                  <span className={form === 'hcfa' ? 'form_label_color' : ''}>{labels?.address}</span>
                ) : (
                  <span>
                    {t('common:fields.address')} ({t('common:fields.streetNumber')})
                  </span>
                )
              }
              name={ids.address}
              rules={[ids.address ? yupValidator(t, addressLabel) : {}]}
            >
              <Input placeholder="ADDRESS" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
            </Form.Item>
          </Tooltip>
        </Col>
        {consecutiveAddress && (
          <Col xs={24} md={12} lg={12} xxl={breakPoints?.address || 24}>
            <Form.Item
              className="field--first field--last"
              label={labels?.addressTwo || 'ADDRESS II'}
              name={ids.addressTwo}
              rules={[ids.addressTwo ? yupValidator(t, ids.addressTwo) : {}]}
            >
              <Input placeholder="ADDRESS 2" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
            </Form.Item>
          </Col>
        )}

        {!consecutiveAddress && (
          <Col xs={24} md={12} lg={12} xxl={breakPoints?.address || 24}>
            <Form.Item
              className="field--first field--last"
              label={labels?.addressTwo || 'ADDRESS II'}
              name={ids.addressTwo}
              rules={[ids.addressTwo ? yupValidator(t, ids.addressTwo) : {}]}
            >
              <Input placeholder="ADDRESS 2" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={0}>
        <Col xs={24} md={12} lg={12} xxl={breakPoints?.city || 12}>
          <div className="FieldsetLocation__disabled-container">
            <Tooltip placement="bottomLeft" title={getTooltipText(ids.city)}>
              <Form.Item
                className="field--first"
                label={labels?.city || t('common:fields.city')}
                name={ids.city}
                rules={[ids.city ? yupValidator(t, ids.city) : {}]}
              >
                <Input placeholder="CITY" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </Tooltip>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12} xxl={breakPoints?.state || 12}>
          <div className="FieldsetLocation__disabled-container">
            <Form.Item
              label={labels?.state || t('common:fields.state')}
              name={ids.state}
              rules={[ids.state ? yupValidator(t, ids.state) : {}]}
            >
              <SelectState placeholder="STATE" inputKey={ids.state as string} />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12} xxl={breakPoints?.zipcode || 12} className="field--last">
          <Form.Item
            className="field--first"
            label={labels?.zipcode || t('common:fields.zipCode')}
            name={ids.zipcode}
            rules={[ids.zipcode ? yupValidator(t, ids.zipcode) : {}]}
          >
            <Input onInput={toZipCode} placeholder="ZIP CODE" />
          </Form.Item>
        </Col>

        {ids.phone && (
          <Col xs={24} md={12} lg={12} xxl={breakPoints?.zipcode || 12}>
            <Form.Item
              className="mb-0"
              label={
                labels?.phone || t('common:fields.telephone') + ' (' + t('common:fields.telephoneIncludeArea') + ')'
              }
              rules={[ids.phone ? yupValidator(t, ids.phone) : {}]}
            >
              <Row gutter={8} wrap={false}>
                {/* <Col>
                <Form.Item name={`${ids.phone}_code`}>
                  <SelectPhoneCode />
                </Form.Item>
              </Col> */}
                <Col flex="auto">
                  <Form.Item
                    name={ids.phone}
                    rules={[ids.phone ? yupValidator(t, ids.phone) : {}]}
                    style={{ padding: '0' }}
                  >
                    <Input placeholder="PHONE NUMBER" onInput={toNum} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
}

export default FieldsetLocation;
