import React, { useContext, useState } from 'react';
import { Col, Form, Input, Row, Select, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getYupValidator as ub04YupValidator } from 'utils/ub04/validataions';
import { getYupValidator as hcfaYupValidator } from 'utils/hcfa/validations';
import { getYupValidator as dentalYupValidator } from 'utils/dental/validations';
import { FormContext } from 'components/common/FormProvider';
import { DENTAL_FIELDS } from 'utils/dental/fields';
import { onSearchSelect } from 'utils/search/search';

type Fields = 'firstName' | 'lastName' | 'middleInitial' | 'generation' | 'suffix';

type FieldsetFullNameProps = {
  form: 'hcfa' | 'ub04' | 'dental';
  lastNameId: string;
  firstNameId: string;
  middleInitialId?: string;
  middleMiddleId?: string;
  generationId?: string;
  suffixId?: string;
  labels?: Partial<Record<Fields, string>>;
  breakPoints?: Partial<Record<Fields, number>>;
  isRequired?: Partial<Record<Fields, boolean>>;
};

const GENERATIONS = ['JR', 'SR', 'II', 'III', 'IV', 'V', 'VI'];

const validatorsMap = {
  hcfa: hcfaYupValidator,
  ub04: ub04YupValidator,
  dental: dentalYupValidator
};

function FieldsetFullName(props: FieldsetFullNameProps): JSX.Element {
  const {
    lastNameId,
    firstNameId,
    middleInitialId,
    middleMiddleId,
    generationId,
    suffixId,
    labels,
    breakPoints,
    isRequired
  } = props;
  const formModel = props.form;
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  const yupValidator = validatorsMap[formModel];

  return (
    <Row gutter={0}>
      <Col span={breakPoints?.lastName || 12}>
        <Form.Item
          className="field--first"
          label={labels?.lastName || t('common:fields.lastName')}
          name={lastNameId}
          required={isRequired?.lastName}
          rules={[yupValidator(t, lastNameId)]}
        >
          <Input
            placeholder="LAST NAME"
            min={2}
            max={60}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
      <Col span={breakPoints?.firstName || 12}>
        <Form.Item
          label={labels?.firstName || t('common:fields.firstName')}
          name={firstNameId}
          required={isRequired?.firstName}
          rules={[yupValidator(t, firstNameId)]}
        >
          <Input
            placeholder="FIRST NAME"
            min={2}
            max={35}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
      {middleInitialId && (
        <Col span={breakPoints?.middleInitial || 12}>
          <Form.Item
            label={labels?.middleInitial || t('common:fields.middleInitial')}
            name={middleInitialId}
            rules={[yupValidator(t, middleInitialId)]}
          >
            <Input min={1} max={60} onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}
      {middleMiddleId && (
        <Col span={breakPoints?.middleInitial || 12}>
          <Form.Item
            label={labels?.middleInitial || t('common:fields.middleNama')}
            name={middleMiddleId}
            rules={[yupValidator(t, middleMiddleId)]}
          >
            <Input
              placeholder="MIDDLE NAME"
              min={1}
              max={60}
              onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>
        </Col>
      )}
      {suffixId && (
        <Col span={breakPoints?.suffix || 12}>
          <Form.Item
            className="field--last"
            label={labels?.suffix || t('common:fields.suffix')}
            name={suffixId}
            rules={[yupValidator(t, suffixId)]}
          >
            <Select
              placeholder="JR"
              options={GENERATIONS.map((el) => ({ label: el, value: el }))}
              allowClear
              showSearch
              onSearch={(input) => {
                onSearchSelect(form, suffixId, input, GENERATIONS);
              }}
            />
          </Form.Item>
        </Col>
      )}
      {generationId && (
        <Col span={breakPoints?.generation || 12}>
          <Form.Item
            className="field--last"
            label={labels?.generation || t('common:fields.generation')}
            name={generationId}
            //rules={[yupValidator(t, generationId)]}
          >
            <Select
              placeholder="JR"
              options={GENERATIONS.map((el) => ({ label: el, value: el }))}
              allowClear
              showSearch
              onSearch={(input) => {
                onSearchSelect(form, generationId, input, GENERATIONS);
              }}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

export default FieldsetFullName;
