import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import { Page } from 'components/common/Page';
import FormActions from 'components/common/FormActions';
import { FORM_ACTIONS, SECTIONS } from 'config/constants';
import { useParams } from 'react-router-dom';
import { useFormProvider } from 'context/form.context';
import { getClaimData, getClaimDataBySteps } from 'utils/search/search';
import { FormContext, useResetFormProvider } from '../components/common/FormProvider';
import { serializeFormData } from '../utils/common';
import { PaperForm } from 'components/dental/PaperForm';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getClaimdataById } from 'api/claims';
// import FormAttachmentsNew from 'components/common/FormAttachmentsNew';
import { ATTACHMENTS_STEP } from 'config/search';
import PageViewerWithAttachments from 'components/common/PageViewerWithAttachments';
import AuditTrail from 'components/common/AuditTrail';
import { useCanHandleDental } from 'utils/hooks/useCanHandleDental';
import ForbiddenPage from './ForbiddenPage';

type DentalProps = {
  modern?: boolean;
};

function Dental({ modern }: DentalProps): JSX.Element {
  const { t } = useTranslation();

  const { id, action, category } = useParams();
  const disabled = action === FORM_ACTIONS.VIEW;

  const { form, handleSave, handleSend, setData, setClaimId, setFileRepresentationsList, setAttachmentsNew } =
    useContext(FormContext);

  const [formState] = useFormProvider();
  const canHandleDental = useCanHandleDental();

  const { accessToken } = useOidcAccessToken();
  const hasClaim = action !== FORM_ACTIONS.NEW;

  useResetFormProvider();

  const pageTitle = React.useMemo(() => {
    switch (action) {
      case FORM_ACTIONS.EDIT:
        return 'Edit Dental Claim';
      case FORM_ACTIONS.COPY:
        return 'New Dental Claim from existing';
      default:
        return t('common:newItem', { item: 'Dental' });
    }
  }, [action]);

  const [count, setCount] = React.useState(1);
  const [dataSource, setDataSource] = React.useState<any>([]);

  const tabsItems = [
    {
      label: 'Form',
      key: 'form',
      children: (
        <PaperForm
          count={count}
          setCount={setCount}
          dataSource={dataSource}
          setDataSource={setDataSource}
          section={SECTIONS.DENTAL}
          actions={[]}
        />
      )
    },
    {
      label: 'Attachments',
      key: 'Attachments',
      children: <PageViewerWithAttachments form={'dental'} readOnly={disabled} />
    }
  ];

  if (hasClaim) {
    tabsItems.push({
      label: 'Audit trail',
      key: 'AuditTrail',
      children: <AuditTrail claimId={id} />
    });
  }

  React.useEffect(() => {
    const handleEffect = async () => {
      if (id) setClaimId(id);

      if (action === FORM_ACTIONS.EDIT || action === FORM_ACTIONS.VIEW) {
        const claimData = await getClaimdataById(
          id!,
          SECTIONS.DENTAL,
          accessToken,
          category === 'archive' ? true : false
        );
        if (claimData) {
          const _data = serializeFormData(getClaimData(claimData), 'dental');
          setFileRepresentationsList(claimData.fileRepresentations);
          setAttachmentsNew(claimData.attachments);
          setData(_data);

          form && form.setFieldsValue(_data);
        }
      }
      if (action === FORM_ACTIONS.COPY) {
        if (formState.filters) {
          const claimData = await getClaimdataById(
            formState.claimId!,
            SECTIONS.DENTAL,
            accessToken,
            category === 'archive' ? true : false
          );
          if (claimData) {
            const _data = serializeFormData(
              getClaimDataBySteps(SECTIONS.DENTAL, claimData, formState.filters),
              'dental'
            );
            setData(_data);

            if (formState?.filters?.includes(ATTACHMENTS_STEP)) {
              setAttachmentsNew(claimData.attachments);
            }

            form && form.setFieldsValue(_data);
          }
        }
      }
    };
    handleEffect();
  }, [action, formState, form, id]);

  if (!canHandleDental) return <ForbiddenPage />;

  return (
    <div>
      <Page pageHelmet={pageTitle}>
        <Page.Back url={`/${SECTIONS.DENTAL}`} />
        <Page.Header title={pageTitle} description={t(`dental:formDescription`)}>
          <div>
            <div className="rapid-toggle-group-form-view-wrapper"> </div>
          </div>
        </Page.Header>
        <Page.Content>
          <Tabs className="claim-wrapper" items={tabsItems} />

          <FormActions
            onSend={() => handleSend('dental')}
            onSave={() => handleSave('dental')}
            action={action as FORM_ACTIONS}
          />
        </Page.Content>
      </Page>
    </div>
  );
}

export default Dental;
