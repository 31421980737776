import { TFunction } from 'i18next';
import * as yup from 'yup';
import {
  testMoreThan1Year,
  testOperatingProviderNpiFilled,
  test100YearsPast,
  testDocumentControlNumber64aRequired,
  testNonCoveredChargesLowerThanTotal,
  testIfLargerThanTotalCharges54A,
  testIfLargerThanTotalChargesA,
  testIfLargerThanTotalCharges54B,
  testIfLargerThanTotalChargesB,
  testIfLargerThanTotalCharges54C,
  testIfLargerThanTotalChargesC,
  testICDValueNineMax,
  getDestinationPayer
} from './customRules';
import { UB04_FIELDS } from './fields';
import { testNPIforLuhnAlghoritm } from 'utils/hcfa/customRules';

const dxCodes = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q'];

const dxCodesFields = dxCodes.map((c) => UB04_FIELDS[`code67${c}Dx`]);

const getDiagnosisCodes = (t: TFunction) =>
  dxCodes.reduce((map, letter) => {
    const codesMap: any = { ...map };

    const keyDx = `code67${letter}Dx`;

    codesMap[UB04_FIELDS[keyDx]] = yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-code674', t('common:fieldErrors.ICD009'), testICDValueNineMax);

    return codesMap;
  }, {});

export const getUb04WarningServiceTableShape = (t: TFunction) => {
  return yup.object().shape({
    [UB04_FIELDS.dos_45]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-dos_45MoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.nonCoveredCharges_48]: yup
      .string()
      .nullable()
      .test('custom-nonCoveredCharges_48', t('common:fieldErrors.ATM002'), testNonCoveredChargesLowerThanTotal)
  });
};
export const getUb04WarningSchema = (t: TFunction) =>
  yup.object().shape({
    [UB04_FIELDS.servicesList]: yup.array().of(getUb04WarningServiceTableShape(t)),
    [UB04_FIELDS.coversPeriodTo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-coversPeriodTo1year', t('common:fieldErrors.DOS004'), testMoreThan1Year),

    [UB04_FIELDS.otherProvider1LastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test(
        'custom-otherProvider1LastNameNpiOperatingProvider',
        t('common:fieldErrors.NPI004'),
        testOperatingProviderNpiFilled
      ),
    [UB04_FIELDS.otherProvider2LastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test(
        'custom-otherProvider2LastNameNpiOperatingProvider',
        t('common:fieldErrors.NPI004'),
        testOperatingProviderNpiFilled
      ),
    [UB04_FIELDS.occurrenceSpan35AFrom]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan35AFromMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.occurrenceSpan35ATo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan35AToMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.occurrenceSpan35BFrom]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan35BFromMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.occurrenceSpan35BTo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan35BToMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.occurrenceSpan36AFrom]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan36AFromMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.occurrenceSpan36ATo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan36AToMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.occurrenceSpan36BFrom]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan36BFromMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.occurrenceSpan36BTo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-ocurrenteSpan36BToMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.principalProcedureDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-principalProcedureDateMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.procedureDate74a]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-procedureDate74aMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.procedureDate74b]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-procedureDate74bMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.procedureDate74c]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-procedureDate74cMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.procedureDate74d]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-procedureDate74dMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),
    [UB04_FIELDS.procedureDate74e]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-procedureDate74eMoreThan1Year', t('common:fieldErrors.DOS004'), testMoreThan1Year),

    [UB04_FIELDS.admissionDate]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.coversPeriodFrom]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),

    [UB04_FIELDS.patientBirthdate]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),

    [UB04_FIELDS.occurrenceDate31A]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.occurrenceDate31B]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.occurrenceDate32A]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.occurrenceDate32B]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.occurrenceDate33A]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.occurrenceDate33B]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.occurrenceDate34A]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    [UB04_FIELDS.occurrenceDate34B]: yup
      .string()
      .nullable()
      .test('custom-dos_Past', t('common:fieldErrors.DATE004'), test100YearsPast),
    // [UB04_FIELDS.documentControlNumber64a]: yup
    //   .string()
    //   .nullable()
    //   .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
    //     is: (payerA: string, payerB: string, payerC: string) => {
    //       if (getDestinationPayer(payerA, payerB, payerC) === 'A') return true;
    //       return false;
    //     },
    //     then: (schema) =>
    //       schema.test(
    //         'custom-documentControlNumber64aRequired',
    //         t('common:fieldErrors.TOB001'),
    //         testDocumentControlNumber64aRequired
    //       )
    //   }),
    // [UB04_FIELDS.documentControlNumber64b]: yup
    //   .string()
    //   .nullable()
    //   .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
    //     is: (payerA: string, payerB: string, payerC: string) => {
    //       if (getDestinationPayer(payerA, payerB, payerC) === 'B') return true;
    //       return false;
    //     },
    //     then: (schema) =>
    //       schema.test(
    //         'custom-documentControlNumber64aRequired',
    //         t('common:fieldErrors.TOB001'),
    //         testDocumentControlNumber64aRequired
    //       )
    //   }),
    // [UB04_FIELDS.documentControlNumber64c]: yup
    //   .string()
    //   .nullable()
    //   .when([UB04_FIELDS.payer50A, UB04_FIELDS.payer50B, UB04_FIELDS.payer50C], {
    //     is: (payerA: string, payerB: string, payerC: string) => {
    //       if (getDestinationPayer(payerA, payerB, payerC) === 'C') return true;
    //       return false;
    //     },
    //     then: (schema) =>
    //       schema.test(
    //         'custom-documentControlNumber64aRequired',
    //         t('common:fieldErrors.TOB001'),
    //         testDocumentControlNumber64aRequired
    //       )
    //   }),

    [UB04_FIELDS.priorPayments54A]: yup
      .string()
      .nullable()
      .test('custom-priorPayments54A', t('common:fieldErrors.AMT002'), testIfLargerThanTotalCharges54A),
    [UB04_FIELDS.estimatedAmount55A]: yup
      .string()
      .nullable()
      .test('custom-estimatedAmount55A', t('common:fieldErrors.AMT002'), testIfLargerThanTotalChargesA),
    [UB04_FIELDS.priorPayments54B]: yup
      .string()
      .nullable()
      .test('custom-priorPayments54B', t('common:fieldErrors.AMT002'), testIfLargerThanTotalCharges54B),
    [UB04_FIELDS.estimatedAmount55B]: yup
      .string()
      .nullable()
      .test('custom-estimatedAmount55B', t('common:fieldErrors.AMT002'), testIfLargerThanTotalChargesB),
    [UB04_FIELDS.priorPayments54C]: yup
      .string()
      .nullable()
      .test('custom-priorPayments54C', t('common:fieldErrors.AMT002'), testIfLargerThanTotalCharges54C),
    [UB04_FIELDS.billingNpi]: yup.string().nullable(),
    //.test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [UB04_FIELDS.attendingProviderNpi]: yup.string().nullable(),
    //.test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [UB04_FIELDS.operatingProviderNpi]: yup.string().nullable(),
    //.test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [UB04_FIELDS.otherProvider1Npi]: yup.string().nullable(),
    //.test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [UB04_FIELDS.otherProvider2Npi]: yup.string().nullable(),
    //.test('custom-npiluhn', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [UB04_FIELDS.estimatedAmount55C]: yup
      .string()
      .nullable()
      .test('custom-estimatedAmount55C', t('common:fieldErrors.AMT002'), testIfLargerThanTotalChargesC),
    ...getDiagnosisCodes(t)
  });

export const knownWarnings = [
  UB04_FIELDS.coversPeriodFrom,
  UB04_FIELDS.coversPeriodTo,
  // UB04_FIELDS.dos_45,
  UB04_FIELDS.otherProvider1LastName,
  UB04_FIELDS.otherProvider2LastName,
  UB04_FIELDS.occurrenceSpan35AFrom,
  UB04_FIELDS.occurrenceSpan35ATo,
  UB04_FIELDS.occurrenceSpan35BFrom,
  UB04_FIELDS.occurrenceSpan35BTo,
  UB04_FIELDS.occurrenceSpan36AFrom,
  UB04_FIELDS.occurrenceSpan36ATo,
  UB04_FIELDS.occurrenceSpan36BFrom,
  UB04_FIELDS.occurrenceSpan36BTo,
  UB04_FIELDS.principalProcedureDate,
  UB04_FIELDS.procedureDate74a,
  UB04_FIELDS.procedureDate74b,
  UB04_FIELDS.procedureDate74c,
  UB04_FIELDS.procedureDate74d,
  UB04_FIELDS.procedureDate74e,
  UB04_FIELDS.admissionDate,
  UB04_FIELDS.patientBirthdate,
  UB04_FIELDS.occurrenceDate31A,
  UB04_FIELDS.occurrenceDate31B,
  UB04_FIELDS.occurrenceDate32A,
  UB04_FIELDS.occurrenceDate32B,
  UB04_FIELDS.occurrenceDate33A,
  UB04_FIELDS.occurrenceDate33B,
  UB04_FIELDS.occurrenceDate34A,
  UB04_FIELDS.occurrenceDate34B,
  UB04_FIELDS.documentControlNumber64a,
  // UB04_FIELDS.nonCoveredCharges_48,
  UB04_FIELDS.priorPayments54A,
  UB04_FIELDS.priorPayments54B,
  UB04_FIELDS.priorPayments54C,
  UB04_FIELDS.estimatedAmount55A,
  UB04_FIELDS.estimatedAmount55B,
  UB04_FIELDS.estimatedAmount55C,
  UB04_FIELDS.billingNpi,
  UB04_FIELDS.attendingProviderNpi,
  UB04_FIELDS.operatingProviderNpi,
  UB04_FIELDS.otherProvider1Npi,
  UB04_FIELDS.otherProvider2Npi,
  ...dxCodesFields
];
