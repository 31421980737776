import { AttachmentDTO, FileRepresentationsDTO } from 'models/DTO/AttachmentDTO';
import React, { useContext, useState } from 'react';
import { FormContext } from './FormProvider';
import FormAttachmentsListItemNew from './FormAttachmentsListItemNew';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { List, Upload, notification } from 'antd';
import { ReactComponent as DropIcon } from 'heroicons/outline/download.svg';
import { FilesOptions, uploadFiles } from 'api/files';
import uuid from 'react-uuid';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { SECTIONS } from 'config/constants';
import { fileTypeFromBuffer } from 'file-type';
import { debounce } from 'lodash';

interface Props {
  accept?: string;
  isSimple?: boolean;
  readOnly: boolean;
  form: 'ub04' | 'hcfa' | 'dental' | SECTIONS;
  setSelectedAttachment?: React.Dispatch<React.SetStateAction<AttachmentDTO | FileRepresentationsDTO | undefined>>;
}

const { Dragger } = Upload;
const allowedTypes = ['image/tiff', 'application/pdf', 'image/jpeg', 'image/jpg', 'image/fax-tiff', 'image/png'];

const FormAttachmentsNew: React.FC<Props> = ({
  accept = 'image/tiff, application/pdf, image/jpeg, image/jpg, image/fax-tiff, image/png',
  isSimple = false,
  readOnly,
  form,
  setSelectedAttachment
}) => {
  const { attachmentsNew, setAttachmentsNew, fileRepresentationsList, setFileRepresentationsList } =
    useContext(FormContext);
  const [loading, setLoading] = useState(false);
  const { accessToken } = useOidcAccessToken();
  const attachments = isSimple ? fileRepresentationsList : attachmentsNew;
  const setAction = isSimple ? setFileRepresentationsList : setAttachmentsNew;
  /**When multiple files are selected onChange gets called once for each file, debounce was first obvius solution */
  const handleChangeDebounced = debounce(async function handleChange(file: UploadChangeParam<UploadFile<any>>) {
    if (!file.fileList?.length) return;

    const filesToUpload = file.fileList;
    let shouldAbort: string | null = null;

    const uploadParams = new FilesOptions();
    uploadParams.token = accessToken;
    const addedAtts: (AttachmentDTO | FileRepresentationsDTO)[] = [];

    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      const fileSizeInMB = (file.size || 0) / 1024 / 1024;
      if (fileSizeInMB > 50) {
        shouldAbort = 'File size exceeds 50MB';
        break;
      }
      const buff = await file.originFileObj?.arrayBuffer();
      const type = await fileTypeFromBuffer(buff!);
      let mimeType = file.originFileObj!.type;
      if (type?.mime) {
        mimeType = type.mime;
      }
      if (!mimeType || !allowedTypes.includes(mimeType!)) {
        shouldAbort = `Unsuported file type ${mimeType}`;
        break;
      }
      const uniqId = uuid();
      let extension = file?.type?.split('/')[1];
      uploadParams.paths.push(`${uniqId}.${extension}`);
      uploadParams.files.push(file.originFileObj!);
      const toAddAtt: AttachmentDTO | FileRepresentationsDTO = {
        attachmentType: isSimple ? '' : 'EOB',
        extension: `.${extension}`,
        fileName: file.name,
        id: uniqId,
        mimeType,
        path: `${uniqId}.${extension}`,
        size: file.size!,
        storageId: `${uniqId}.${extension}`,
        documentTypes: isSimple ? [] : ['EOB']
      };
      if (isSimple && !attachments.length && i === 0) {
        toAddAtt.containsOriginalClaim = true;
      }
      addedAtts.push(toAddAtt);
    }
    if (shouldAbort) {
      notification.error({
        message: shouldAbort
      });
    }

    setLoading(true);
    //
    try {
      await uploadFiles(uploadParams);

      setAction((atts: any[]) => [...addedAtts, ...atts]);
    } catch (e: any) {
      const serverMessage = e?.response?.data?.message;
      let msg = 'Server error occured';
      if (serverMessage && serverMessage === 'Wrong file upload length') {
        msg = 'The attached document seems to be corrupted, please check and attach again.';
      }
      notification.error({
        message: 'Upload failed',
        description: msg
      });
    } finally {
      setLoading(false);
    }
  }, 300);
  return (
    <div className="FormAttachments__container">
      {isSimple && (
        <div>
          <b>NOTE:</b>
          <p>
            Do not submit attachments using this option unless you are also uploading it with associated claim. If you
            want to submit attachments for a claim that you are not currently uploading, use the Claims Search Feature.
            After locating a member record on Search page, use the Claims Edit Attachment option to submit the
            documents.
          </p>
        </div>
      )}
      <Dragger
        disabled={loading || readOnly}
        fileList={[]}
        name="file"
        listType="picture"
        multiple={true}
        onChange={handleChangeDebounced}
        beforeUpload={() => false}
        accept={accept}
      >
        <p className="ant-upload-drag-icon">
          <DropIcon />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        {loading && (
          <p style={{ color: '#9c27b0' }}>
            <b>LOADING, PLEASE WAIT...</b>
          </p>
        )}
        {isSimple ? (
          <p>
            <b>Upload only PDF, PNG, JPEG, JPG, TIF and Fax TIFF files with max size of 50MB.</b>
            <br />
            <b style={{ color: '#9c27b0' }}>(Multiple document selection is allowed.)</b>
          </p>
        ) : (
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
          </p>
        )}
      </Dragger>
      <div className="mt-1 existing-attachments">
        {attachments?.length > 0 && (
          <List
            bordered
            size="small"
            dataSource={attachments}
            renderItem={(item: AttachmentDTO | FileRepresentationsDTO) => (
              <FormAttachmentsListItemNew
                readOnly={readOnly}
                attachment={item}
                isSimple={isSimple}
                setAction={setAction}
                form={form}
                setSelectedAttachment={setSelectedAttachment}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default FormAttachmentsNew;
