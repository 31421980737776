import { DocumentDTO } from './DocumentDTO';
import { AttachmentDTO, FileRepresentationsDTO } from './AttachmentDTO';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';

export class ClaimDTO {
  status: ClaimStatusEnum;
  claimId: string;
  documents: DocumentDTO[];
  attachments: AttachmentDTO[] = [];
  fileRepresentations: FileRepresentationsDTO[] = [];
  constructor(claimId: string, status: ClaimStatusEnum, documents: DocumentDTO[]) {
    this.claimId = claimId;
    this.status = status;
    this.documents = documents;
  }
}
