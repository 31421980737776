import * as React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getYupValidator as ub04YupValidator } from 'utils/ub04/validataions';
import { getYupValidator as hcfaYupValidator } from 'utils/hcfa/validations';
import { getYupValidator as dentalYupValidator } from 'utils/dental/validations';
import { onSearchSelect } from 'utils/search/search';
import { useContext } from 'react';
import { FormContext } from 'components/common/FormProvider';

type Fields = 'firstName' | 'lastName' | 'middleInitial' | 'middleName' | 'generation' | 'credential';

type FieldsetFullNameProps = {
  form: 'hcfa' | 'ub04' | 'dental';
  lastNameId: string;
  firstNameId: string;
  middleNameId?: string;
  credentialsId?: string;
  middleInitialId?: string;
  suffixId?: string;
  generationId?: string;
  labels?: Partial<Record<Fields, string>>;
  breakPoints?: Partial<Record<Fields, number>>;

  direction?: 'horizontal' | 'vertical';
};

const GENERATIONS = ['JR', 'SR', 'II', 'III', 'IV', 'V', 'VI'];

const validatorsMap = {
  hcfa: hcfaYupValidator,
  ub04: ub04YupValidator,
  dental: dentalYupValidator
};

function FieldsetFullName(props: FieldsetFullNameProps): JSX.Element {
  const {
    lastNameId,
    firstNameId,
    middleNameId,
    middleInitialId,
    generationId,
    suffixId,
    labels,
    breakPoints,
    direction,
    credentialsId
  } = props;
  const formModel = props.form;
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  const yupValidator = validatorsMap[formModel];

  if (direction === 'vertical') {
    return (
      <div className="fields-name-style">
        <Form.Item
          label={labels?.lastName || t('common:fields.lastName')}
          name={lastNameId}
          rules={[yupValidator(t, lastNameId)]}
        >
          <Input placeholder="LAST NAME" />
        </Form.Item>
        <Form.Item
          label={labels?.firstName || t('common:fields.firstName')}
          name={firstNameId}
          rules={[yupValidator(t, firstNameId)]}
        >
          <Input placeholder="FIRST NAME" />
        </Form.Item>
        {middleNameId && (
          <Col span={breakPoints?.middleInitial || 12}>
            <Form.Item
              label={labels?.middleName || t('common:fields.middleNama')}
              name={middleNameId}
              rules={[yupValidator(t, middleNameId)]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        )}
        {suffixId && (
          <Col span={breakPoints?.middleInitial || 12}>
            <Form.Item
              label={labels?.middleName || t('common:fields.suffix')}
              name={suffixId}
              rules={[yupValidator(t, suffixId)]}
            >
              <Input placeholder="" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
            </Form.Item>
          </Col>
        )}
        {middleInitialId && (
          <Form.Item
            label={labels?.middleInitial || t('common:fields.middleInitial')}
            name={middleInitialId}
            rules={[yupValidator(t, middleInitialId)]}
          >
            <Input placeholder="MIDDLE NAME" />
          </Form.Item>
        )}

        {generationId && (
          <Form.Item
            label={labels?.generation || t('common:fields.generation')}
            name={generationId}
            rules={[yupValidator(t, generationId)]}
          >
            <Select
              placeholder="JR"
              style={{ width: '70px' }}
              options={GENERATIONS.map((el) => ({ label: el, value: el }))}
              allowClear
              showSearch
              onSearch={(input) => {
                onSearchSelect(form, generationId, input, GENERATIONS);
              }}
            />
          </Form.Item>
        )}
        {credentialsId && (
          <Form.Item
            rules={[yupValidator(t, credentialsId)]}
            label={labels?.credential || 'Credentials'}
            name={credentialsId}
          >
            <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        )}
      </div>
    );
  }

  return (
    <Row gutter={0} style={{ width: '100%' }}>
      <Col span={breakPoints?.lastName || 12}>
        <Form.Item
          className="field--first"
          label={labels?.lastName || t('common:fields.lastName')}
          name={lastNameId}
          rules={[yupValidator(t, lastNameId)]}
        >
          <Input placeholder="LAST NAME" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </Col>
      <Col span={breakPoints?.firstName || 12}>
        <Form.Item
          label={labels?.firstName || t('common:fields.firstName')}
          name={firstNameId}
          rules={[yupValidator(t, firstNameId)]}
        >
          <Input placeholder="FIRST NAME" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </Col>
      {middleNameId && (
        <Col span={breakPoints?.middleInitial || 12}>
          <Form.Item
            label={labels?.middleInitial || t('common:fields.middleNama')}
            name={middleNameId}
            rules={[yupValidator(t, middleNameId)]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
      )}
      {middleInitialId && (
        <Col span={breakPoints?.middleInitial || 12}>
          <Form.Item
            label={labels?.middleInitial || t('common:fields.middleInitial')}
            name={middleInitialId}
            rules={[yupValidator(t, middleInitialId)]}
          >
            <Input placeholder="MIDDLE NAME" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}
      {suffixId && (
        <Col span={breakPoints?.middleInitial || 12}>
          <Form.Item
            label={labels?.middleName || t('common:fields.suffix')}
            name={suffixId}
            rules={[yupValidator(t, suffixId)]}
          >
            <Input placeholder="" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}
      {generationId && (
        <Col span={breakPoints?.generation || 12}>
          <Form.Item
            className="field--last"
            label={labels?.generation || t('common:fields.generation')}
            name={generationId}
            rules={[yupValidator(t, generationId)]}
          >
            <Select
              placeholder="JR"
              style={{ width: '70px' }}
              options={GENERATIONS.map((el) => ({ label: el, value: el }))}
              allowClear
              showSearch
              onSearch={(input) => {
                onSearchSelect(form, generationId, input, GENERATIONS);
              }}
            />
          </Form.Item>
        </Col>
      )}
      {credentialsId && (
        <Col span={breakPoints?.credential || 12}>
          <Form.Item
            rules={[yupValidator(t, credentialsId)]}
            label={labels?.credential || 'Credentials'}
            name={credentialsId}
          >
            <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

export default FieldsetFullName;
